import { useEffect, useState } from 'react';
import { getOrganisationAlias } from 'ors-utils';
import { useMatch } from 'react-router-dom';

import {
  organisationCountry,
  useGetOrganisationConfigurationQuery,
} from 'store/organisationCountry';
import { getProductFamilyId } from 'core/getProductFamilyId';
import { useDispatch } from './useDispatch';
import { appRoutes } from 'routing';
import { getRegFlow } from '../getRegFlow';
import { useLocale } from './useLocale';

/**
 * Use this hook to check if the organisation alias is present in the URL and if so,
 * download organisation's data like logos, etc.
 * TODO: what is the best way to move it to shared packages?
 */
export const useOrganisations = () => {
  const dispatch = useDispatch();
  const isOnCallbackPage = useMatch(appRoutes.auth.callback);
  const regFlow = getRegFlow();

  const orgAlias = getOrganisationAlias();
  const {
    data: orgConfig,
    isFetching,
    isError,
  } = useGetOrganisationConfigurationQuery({
    alias: orgAlias,
    productFamilyId: getProductFamilyId(),
    locale: useLocale().locale,
  });
  const orgData = orgConfig?.value;

  const [showApp, setShowApp] = useState(!orgAlias);
  const [logoUrl, setlogoUrl] = useState<string | undefined>(undefined);
  const [extraLogoUrl, setExtraLogoUrl] = useState<string | undefined>(undefined);
  const [faviconUrl, setFavicon] = useState<string | undefined>(undefined);

  /** Make call to API to get organisation data */
  useEffect(() => {
    const setOrganisationAlias = async (organisationAlias: string) => {
      await dispatch(organisationCountry.thunks.setOrganisationAlias(organisationAlias));
    };

    if (orgAlias) {
      /** "organisation" query parameter is not present on callback page */
      !isOnCallbackPage && setOrganisationAlias(orgAlias);
    } else {
      /** Reset organisation in case you've visited nCJ before with organisation context */
      dispatch(organisationCountry.actions.resetOrganisation());
    }
  }, [dispatch, isOnCallbackPage, orgAlias, regFlow]);

  /** Show nCJ when organisation data was downloaded from API */
  useEffect(() => {
    if (isFetching || !orgAlias) {
      return;
    }

    /** If error occurs continue as regular BC registration */
    if (orgData || isError) {
      setlogoUrl(orgData?.logoCdnUrl);
      setExtraLogoUrl(orgData?.extraLogoCdnUrl);
      setFavicon(orgData?.faviconCdnUrl);

      setShowApp(true);
    }
  }, [orgData, isFetching, orgAlias, isError]);

  return {
    showApp,
    logoUrl,
    extraLogoUrl,
    faviconUrl,
    orgData,
  };
};
