import { useTranslation } from 'react-i18next';
import { FormFieldProps } from '@britishcouncil/react-forms';
import { RadioGroup } from '@britishcouncil/react-solas-ors3';
import { GenderDto } from 'ors-api/mod';

import { getProductFamilyId, useLocale, useSelector } from 'core';
import { useGetGendersQuery } from 'store/personalDetails';

type Props = FormFieldProps<string>;

const getGenderRadios = (genders?: GenderDto[]) =>
  genders?.map((g) => ({
    checkValue: g.shortCode ?? '',
    label: g.name ?? '',
    testId: g.name,
  })) ?? [];

export const GenderField = (props: Props) => {
  const { t } = useTranslation();
  const countryId = useSelector((s) => s.searchSelect.activeCountry?.id);
  const { data: genders } = useGetGendersQuery({
    awardingBodySystem: useSelector((state) => state.bookTest.selectedExam?.awardingBodySystem),
    productId: useSelector((s) => s.searchSelect.ieltsProduct),
    productFamilyId: getProductFamilyId(),
    locale: useLocale().locale,
    countryId,
  });

  const genderRadios = getGenderRadios(genders);

  return (
    <>
      <label data-testid="gender">{t('APPB2C.cj.personalDetails.gender')}</label>
      <RadioGroup {...props} radios={genderRadios} />
    </>
  );
};
