import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { Button } from '@britishcouncil/react-solas';
import { Modal } from 'ors-ui';
import { selectLanguage } from 'ors-translations';

import { GTM, switchLanguage } from 'core';
import { useActiveCountryLanguageSettings } from './activeCountryLanguageSettings';
import { language } from 'store/language';

interface Props {
  showModal: boolean;
}

export const LanguageOptionsModal = ({ showModal }: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { activeCountryName, isCurrentLangAvailable, langs, currentLangName, singleLangAvailable } =
    useActiveCountryLanguageSettings();

  const dispatchLangSwitch = useCallback((lang: string) => {
    switchLanguage(lang, dispatch);
    dispatch(language.actions.setShowLanguageOptionsModal(false));
  }, []);

  const handleExit = () =>
    isCurrentLangAvailable && dispatch(language.actions.setShowLanguageOptionsModal(false));

  return (
    <Modal
      show={showModal}
      onExit={handleExit}
      title={t('APPB2C.common.findTest.langModal.title')}
      intent="gray"
      showHideLink={false}
      modalProps={{
        alert: !isCurrentLangAvailable,
      }}
      onHide={() => GTM.trackModal('Hide', 'language-options-modal')}
      onShow={() => GTM.trackModal('Show', 'language-options-modal')}
      dataTestId="lang-options-modal"
    >
      <ModalContent>
        <p>
          {t(
            isCurrentLangAvailable
              ? 'APPB2C.common.findTest.langModal.continue'
              : singleLangAvailable
              ? 'APPB2C.common.findTest.langModal.chooseSingle'
              : 'APPB2C.common.findTest.langModal.choose',
            { countryName: activeCountryName, languageName: currentLangName }
          )}
        </p>
        <OptionList>
          {langs.map((lang) => (
            <Button
              key={lang.code}
              intent="link"
              size="xs"
              onClick={() => dispatchLangSwitch(selectLanguage(lang.code))}
            >
              {lang.name}
            </Button>
          ))}
        </OptionList>
      </ModalContent>
    </Modal>
  );
};

const ModalContent = styled.div`
  max-width: 800px;
`;

const OptionList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  > button {
    margin: 20px 0 0 !important;
    padding: 0 !important;
    box-shadow: none !important;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
`;
