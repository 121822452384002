import { FC, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { AccessibilityDto, AvailableDistrictExamDto } from 'ors-api/mod';
import { TestCard, TestCardProps, VenueSearchResultsWrapper } from 'ors-ui';

import { useLocale, useSelector } from 'core';
import { RootState } from 'store';
import i18next from 'common/i18n';
import { invitation } from 'store/invitation';
import { useDispatch } from 'core/hooks/useDispatch';
import { bookTest } from 'store/bookTest';
import { reservation } from 'store/reservation';
import { searchSelect } from 'store/searchSelect';

interface VSRProps {
  results?: AvailableDistrictExamDto[];
  handleBook: (exam: AvailableDistrictExamDto, checkExamBefore?: boolean) => void;
  loadingExamDetails?: number;
  showVenues: boolean;
  allAccessibilities?: AccessibilityDto[];
}

export const VenueSearchResults: FC<VSRProps> = ({
  results,
  handleBook,
  loadingExamDetails,
  showVenues,
  allAccessibilities,
}) => {
  const dispatch = useDispatch();
  const locationCoords = useSelector((state: RootState) => state.searchSelect.locationCoords);
  const isUkviLifeSkills = useSelector(searchSelect.selectors.isUkviLifeSkills);
  const { locale } = useLocale();
  const intl = useIntl();
  const agentPays = useSelector(invitation.selectors.agentPays);
  const inChangeMode = useSelector((s) => s.registration.changeMode);
  const processingError = useSelector(reservation.selectors.getProcessingError());
  const isMapViewEnabled = useSelector((s) => s.featureFlags.features.isMapViewEnabled);

  const changeSpeakingTest = useCallback(
    (exam: AvailableDistrictExamDto) => {
      dispatch(bookTest.thunks.changeSpeaking(exam));
    },
    [dispatch]
  );

  if (!results || results.length < 1) {
    return null;
  }

  const extraProps: Partial<TestCardProps> = isUkviLifeSkills
    ? {
        hideLrwTime: true,
        hideSpeaking: true,
        customLrwExamFormat: 'f2f',
      }
    : {};

  const firstEl = results[0];

  return (
    <VenueSearchResultsWrapper
      firstEl={firstEl}
      showVenues={showVenues}
      showDistance={!!locationCoords}
      allAccessibilities={allAccessibilities}
      isMapViewEnabled={isMapViewEnabled}
    >
      {results.map((e) => (
        <TestCard
          key={`${e.plannedExamId}_${e.districtId}_${e.venueId}`}
          exam={e}
          handleBook={(exam) => handleBook(exam, inChangeMode)}
          onChangeSpeakingTest={changeSpeakingTest}
          loadingExamDetails={loadingExamDetails}
          locale={locale}
          error={processingError}
          inChangeMode={inChangeMode}
          agentPays={agentPays}
          displayVenues={showVenues}
          {...extraProps}
          intl={intl}
          i18next={i18next}
          showDistance={!!locationCoords}
          allAccessibilities={allAccessibilities}
          isMapViewEnabled={isMapViewEnabled}
        />
      ))}
    </VenueSearchResultsWrapper>
  );
};
