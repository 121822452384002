import { Divider, Quote } from '@britishcouncil/react-solas-ors3';
import { OrganisationConfigurationDto } from 'ors-api/mod';
import { HtmlTextRaw } from 'ors-ui';

interface Props {
  orgSpecificData?: OrganisationConfigurationDto;
}

export const OrganisationNotes = ({ orgSpecificData }: Props) => {
  return (
    <>
      {orgSpecificData?.landingPageNotes && (
        <>
          <div>
            <HtmlTextRaw htmlText={orgSpecificData?.landingPageNotes} />
          </div>
          <Divider />
        </>
      )}
      {orgSpecificData?.instructionSummary && (
        <>
          <div>
            <HtmlTextRaw htmlText={orgSpecificData?.instructionSummary} />
          </div>
          <Divider />
        </>
      )}
      {orgSpecificData?.customerJourneyNotes && (
        <>
          <Quote title="" intent="warning">
            <div>
              <HtmlTextRaw htmlText={orgSpecificData?.customerJourneyNotes} />
            </div>
          </Quote>
          <Divider />
        </>
      )}
    </>
  );
};
