import { extractApiError } from '@britishcouncil/react-common';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { asyncThunk } from 'components/asyncThunk';

import {
  paymentClient,
  registrationClient,
  Registration,
  B2CCreateRegistrationRequest,
  PayerDetails,
  termsAndConditionsClient,
} from 'ors-api/ukvi';
import { extractApiErrors } from 'ors-ui';
import { RootState } from '.';
import {
  ApiErrorDetailedWithProperty,
  ApiErrorsListWithInCentreRegistrationRequest,
} from './registration/types';

const sliceName = 'api-ukvi';

interface TermsVersionReq {
  shortCode: string;
  productGroupShortCode: string;
  subSystemName: string;
  organisationCountryId?: number;
}
export const getNewestTermsVersionNumberUkvi = asyncThunk(
  `${sliceName}/getNewestTermsVersionNumberUkvi`,
  (req: TermsVersionReq) =>
    termsAndConditionsClient.getTermsAndConditionsNewestVersionNumber(
      req.shortCode,
      req.productGroupShortCode,
      req.subSystemName,
      req.organisationCountryId
    )
);

export const createRegistrationUkvi = createAsyncThunk<
  Registration,
  B2CCreateRegistrationRequest,
  { state: RootState; rejectValue: ApiErrorsListWithInCentreRegistrationRequest }
>(sliceName + '/createRegistrationUkvi', async (req, { rejectWithValue }) => {
  try {
    const response = await registrationClient.createRegistration(req);
    return response.data;
  } catch (error: any) {
    const err = extractApiErrors(error);
    return rejectWithValue({ error: err, answers: req.answers });
  }
});
