import { mergeWith, isArray, isNumber, isEmpty } from 'lodash-es';
import { AvailableDistrictExamPageDto, SortingParameters, SortDirection } from 'ors-api/mod';

import { SortOrder } from './model';

/**
 * Append results and update totalCount
 */
export function mergeExams(
  stateExams?: AvailableDistrictExamPageDto,
  payloadExams?: AvailableDistrictExamPageDto
): AvailableDistrictExamPageDto | undefined {
  const object = mergeWith({ ...stateExams }, payloadExams, (objVal, sourceVal) => {
    if (isArray(objVal)) {
      return objVal.concat(sourceVal);
    } else if (isArray(sourceVal)) {
      return sourceVal;
    }

    if (isNumber(sourceVal)) {
      return sourceVal;
    }
  });

  return isEmpty(object) ? undefined : object;
}

const sortByDate = [
  { sortField: 'examDate', sortDir: SortDirection.Asc },
  { sortField: 'startTime', sortDir: SortDirection.Asc },
];

export const sortingDictionary: Record<SortOrder, SortingParameters[]> = {
  venueName: [{ sortField: 'venueName', sortDir: SortDirection.Asc }, ...sortByDate],
  distance: [{ sortField: 'distance', sortDir: SortDirection.Asc }, ...sortByDate],
  fee: [{ sortField: 'fee', sortDir: SortDirection.Asc }, ...sortByDate],
  date: [...sortByDate],
};
