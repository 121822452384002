import { ApiError } from '@britishcouncil/react-common';
import { MarketingQuestionDto } from 'ors-api/mod';
import { MarketingQuestionsShortCodes } from 'ors-ui';

import { appRoutes, navigateTo } from 'routing';
import { AppThunk } from '../..';
import { getVerifiedDetailsIol } from '../../api.iol';
import { candidate, FullCandidatePersonalDetails } from '../../candidate';
import slice from './../personalDetailsSlice';

export const loadData = (): AppThunk => async (dispatch, getState) => {
  const isLoggedIn = getState().auth.isLoggedIn;

  isLoggedIn && dispatch(candidate.thunks.loadProfile());
};

/** Go directly to review and do necessary IOL CJ API calls in the background. */
export const rebookGoToReviewIol =
  (
    profile: FullCandidatePersonalDetails,
    marketingQuestions: MarketingQuestionDto[] = []
  ): AppThunk =>
  async (dispatch, getState) => {
    const { iolExam } = getState().registration;
    const { reservationGuid } = getState().reservation;
    const { ieltsProduct } = getState().searchSelect;

    /** Get required data from API */

    /** "Verified details" include TT answers to MQ */
    ieltsProduct && (await dispatch(getVerifiedDetailsIol(ieltsProduct)));

    if (iolExam && reservationGuid) {
      /** Save candidate's nationality. */
      dispatch(saveNationalityOnRebook(marketingQuestions));

      /** Save candidate's ID details. */
      const nationalityId = getState().candidate.answers.data?.answers?.find(
        (answer) => answer.marketingQuestionShortCode === 'CountryOfNationality'
      )?.marketingQuestionOptionId;

      dispatch(
        candidate.actions.setIdDetailsIol({
          idType: profile.idTypeId ?? -1,
          idNumber: String(profile.idNumber ?? ''),
          expiryDate: profile.idExpiry ?? '',
          notExpire: false,
          issuingAuthority: profile.issuingAuthority ?? '',
          countryOfNationality: String(nationalityId ?? ''),
        })
      );

      dispatch(slice.actions.setProcessing(false));
      return navigateTo(appRoutes.journey.review);
    }

    return dispatch(slice.actions.setProcessError({ status: ApiError.Unknown }));
  };

export const saveNationalityOnRebook =
  (marketingQuestions: MarketingQuestionDto[] = []): AppThunk =>
  (dispatch, getState) => {
    const nationalityShortCode: MarketingQuestionsShortCodes = 'CountryOfNationality';
    const nationalities = marketingQuestions.filter(
      (question) => question.shortCode === nationalityShortCode
    );
    const nationalityId = getState().candidate.answers.data?.answers?.find(
      (answer) => answer.marketingQuestionShortCode === nationalityShortCode
    )?.marketingQuestionOptionId;

    nationalities &&
      nationalityId &&
      dispatch(
        candidate.actions.setNationality({
          id: nationalityId.toString(),
        })
      );
  };
