import { useEffect, useRef, useMemo, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { includesInUrl } from 'ors-utils';

import { loadChatScript } from './scripts';
import { useSelector } from 'core';

export const LiveAgent = () => {
  const isLAInitialized = useRef(false);
  const location = useLocation();
  const profile = useSelector((s) => s.candidate.profile);

  const prepopulatedData = useMemo(() => {
    return profile
      ? {
          FirstName: profile.firstName,
          LastName: profile.surname,
          Email: profile.email,
          Subject: '',
        }
      : undefined;
  }, [profile]);

  const loadScripts = useCallback(async () => {
    await loadChatScript(prepopulatedData);
  }, [prepopulatedData]);

  useEffect(() => {
    const isIol = includesInUrl('/iol/');
    if (!isIol) {
      displayChatButton(false);
      return;
    }

    if (isLAInitialized.current) {
      displayChatButton(true);
    } else {
      loadScripts();
      isLAInitialized.current = true;
      displayChatButton(true);
    }
  }, [loadScripts, location.pathname]);

  return (
    <>
      {/* TODO: Try to load init script here, instead of index.html */}
      <Helmet>
        <style type="text/css">
          {`
      .message
      {
        background: none;
        border-style: none;
        border-width: 0;
        color: #FFF;
        padding: 6px 8px 6px 6px;
        margin: 4px 20px;
      }
      .embeddedServiceHelpButton .helpButton .uiButton
      {
        background-color: #222222;
        font-family: "Open Sans" , "Arial", sans-serif;
      }
      .embeddedServiceHelpButton .helpButton .uiButton.helpButtonDisabled
      {
        background-color: #CCC;
      }
      .embeddedServiceHelpButton .helpButton .uiButton:focus
      {
        outline: 1px solid #222222;
      }
      h2[embeddedService-chatHeader_chatHeader] {
        color: white
      }
      svg[lightning-primitiveIcon_primitiveIcon] {
        display: none;
      }
      embeddedservice-chat-header header h2 {
        color: white;
      }
      embeddedservice-chat-header lightning-primitive-icon svg:first-child:not(:only-child){
        display: none;
      }
      header.sidebarHeader img[embeddedService-chatHeader_chatHeader] {
        max-height: 24px;
        max-width: 24px;
      }
      .embeddedServiceSidebarMinimizedDefaultUI .minimizedImage span.uiImage {
        width: 20px;
        height: 20px;
      }
      .embeddedServiceSidebarMinimizedDefaultUI .minimizedImage span.uiImage img {
        width: 20px;
        height: 20px;
        border-radius: unset;
      }
      button.embeddedServiceSidebarMinimizedDefaultUI {
        max-width: 20em;
        width: 235px;
      }
      `}
        </style>
      </Helmet>
    </>
  );
};

const displayChatButton = (show: boolean) => {
  const chatBtn = document.querySelector(
    '.embeddedServiceHelpButton .helpButton'
  ) as HTMLDivElement | null;

  if (chatBtn) {
    if (show) {
      chatBtn.style.display = 'block';
      chatBtn.style.visibility = 'visible';
    } else {
      chatBtn.style.display = 'none';
      chatBtn.style.visibility = 'hidden';
    }
  }
};
