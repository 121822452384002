import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ChildAfterTest } from 'ors-ui';
import { MarketingQuestionAnswer } from 'ors-api/ors2';

import { globalCleanRegistration, globalLogOut } from '../_common/actions';
import { IdDetailsIol } from '../idDetails';
import { extraReducersIol } from './extraReducers.iol';
import {
  CandidateState,
  ChildProfileState,
  EamUserProfile,
  ForWhom,
  FullCandidatePersonalDetails,
  ValidCandidateMarketingQuestionAnswerCount,
} from './types';
import { extraReducersIc } from './extraReducers.ic';

const sliceName = 'candidate';

export const initialState: CandidateState = {
  loadingData: false,
  forWhom: '',
  shouldResetForWhom: true,
  childAfterTest: ChildAfterTest.NotSet,
  pickups: [],
  answers: {
    isLoading: false,
    data: {
      isValid: false,
      answers: [],
    },
  },
  nationalityId: undefined,
  iol: {
    idDetailsIol: undefined,
  },
};

/**
 * Persisted slice presenting candidate details (both main and child if applicable)
 */
const slice = createSlice({
  name: sliceName,
  initialState: initialState,
  reducers: {
    profileSet(state, action: PayloadAction<FullCandidatePersonalDetails | undefined>) {
      state.profile = action.payload;
    },
    setForWhom(state, action: PayloadAction<ForWhom>) {
      state.forWhom = action.payload;

      if (action.payload === 'myself') {
        state.ttProfile = undefined;
        state.pickups = [];
        state.childAfterTest = ChildAfterTest.NotSet;
      }
    },
    setShouldResetForWhom(state, action: PayloadAction<boolean>) {
      state.shouldResetForWhom = action.payload;
    },
    childProfileSet(state, action: PayloadAction<ChildProfileState>) {
      if (action.payload.childAfterTest) {
        state.childAfterTest = action.payload.childAfterTest;
      }
      if (action.payload.pickups) {
        state.pickups = action.payload.pickups;
      }
      state.ttProfile = action.payload.profile;
    },
    profileUpdated(state, action: PayloadAction<Partial<FullCandidatePersonalDetails>>) {
      const profile = { ...state.profile, ...action.payload };
      state.profile = profile as any;
    },
    childProfileUpdated(state, action: PayloadAction<Partial<FullCandidatePersonalDetails>>) {
      const ttProfile = { ...state.ttProfile, ...action.payload };
      state.ttProfile = ttProfile as any;
    },
    userProfileSet(state, action: PayloadAction<EamUserProfile | undefined>) {
      state.userProfile = action.payload;
    },
    setAnswers(state, action: PayloadAction<MarketingQuestionAnswer[]>) {
      const answers = action.payload ?? [];
      state.answers.data = {
        answers,
        isValid: answers.length === ValidCandidateMarketingQuestionAnswerCount,
      };
    },
    setNationality(state, action: PayloadAction<{ id: string }>) {
      state.nationalityId = action.payload.id;
    },
    setIdType(state, action: PayloadAction<{ idType: number }>) {
      state.idType = action.payload.idType;
    },
    setIdDetailsIol(state, action: PayloadAction<IdDetailsIol | undefined>) {
      state.iol.idDetailsIol = action.payload;
    },
    resetId(state) {
      if (state.ttProfile) {
        state.ttProfile.idNumber = '';
        state.ttProfile.idExpiry = '';
        state.ttProfile.issuingAuthority = '';
      }
      if (state.profile) {
        state.profile.idNumber = '';
        state.profile.idExpiry = '';
        state.profile.issuingAuthority = '';
      }
    },
    resetIdType(state) {
      if (state.ttProfile) {
        state.ttProfile.idTypeId = 1;
      }
      if (state.profile) {
        state.profile.idTypeId = 1;
      }
    },
    childIdUpdate(state, action: PayloadAction<number | undefined>) {
      state.childId = action.payload;
    },
    setVisaTypeId(state, action: PayloadAction<number | undefined>) {
      state.visaTypeId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(globalLogOut, () => initialState);
    builder.addCase(globalCleanRegistration, (state) => {
      state.forWhom = '';
    });

    extraReducersIc(builder);
    extraReducersIol(builder);
  },
});

export default slice;
