import styled from '@emotion/styled';
import { breakpoints } from 'ors-ui';

/** "Quote" component (as lots of others ORS3 shared components...) doesn't accept "className" prop, so I have to make a wrapper here. */
export const NarrowQuote = styled.div`
  .bc-quote {
    max-width: 632px;
    margin: 0 auto;
  }

  @media (max-width: ${breakpoints['screen-md-min']}px) {
    .bc-quote {
      max-width: auto;
      margin: 24px;
    }
  }
`;
