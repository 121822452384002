import LRUCache from 'lru-cache';

const threshold = 3000; // 3 seconds
const cache = new LRUCache<string, RecordedCache>({ max: 5 });
export async function throttleAction<T>(key: string, getData: () => Promise<T>, params?: any) {
  const sKey = key + (!!params ? JSON.stringify(params) : '');
  const now = Date.now();
  const cachedRecord = cache.get(sKey);
  if (!!cachedRecord && now - cachedRecord.timestamp <= threshold) {
    return cachedRecord.value as T;
  }

  const val = await getData();
  cache.set(sKey, {
    timestamp: Date.now(),
    value: val,
  });

  return val as T;
}

export type RecordedCache = {
  timestamp: number;
  value?: any;
};
