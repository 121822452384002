import { ApiData, ChildAfterTest, ApiErrorsList } from 'ors-ui';
import {
  CandidateB2C,
  CandidateGuardianIelts,
  CandidatePersonalDetailsExtended,
  MarketingQuestionAnswer,
} from 'ors-api/ors2';

import { IdDetailsIol } from '../idDetails';

export interface CandidateState {
  loadingData: boolean;
  loadingDataErr?: ApiErrorsList;

  /** Candidate profile of the Account owner */
  profile?: FullCandidatePersonalDetails;

  /** User profile (basic stuff only. It comes from EAM) */
  userProfile?: EamUserProfile;

  forWhom: ForWhom;

  shouldResetForWhom: boolean;

  /** Candidate profile of the person takin an exam. It should be used only when registering for someone else */
  ttProfile?: FullCandidatePersonalDetails;

  childAfterTest: ChildAfterTest;

  /** List of up to 2 people that will be eligible to take child after the exam **/
  pickups: CandidateGuardianIelts[];

  /** Candidate marketing answers */
  answers: ApiData<CandidateMarketingQuestionAnswer>;

  /** Candidate nationality (one of marketing question asked on id details page) */
  nationalityId?: string;

  /** Candidate ID type (kept until the last screen to show proper labels) */
  idType?: number;

  /** IOL specific data */
  iol: {
    /** With IOL we save ID details during registration, so we need to keep it until last screen */
    idDetailsIol?: IdDetailsIol;
  };
  childId?: number;
  visaTypeId?: number;
}

export type ForWhom = 'myself' | 'someone-else' | '';

export interface EamUserProfile {
  email: string;
  fullName: string;
  id: number;
  isMinor: boolean;
  needToSupplyMissingDetails: boolean;
  needToUpdateMarketingPreferences: boolean;
  needToVerifyDraftProcess: boolean;
}

export interface FullCandidatePersonalDetails extends CandidatePersonalDetailsExtended {
  notExpires?: boolean;
  genderId?: number;
  hasEnglishOnlineAccount: boolean;
}

export interface ChildProfileState {
  profile?: FullCandidatePersonalDetails;
  pickups?: CandidateGuardianIelts[];
  childAfterTest?: ChildAfterTest;
}

export type TtProfileForm = {
  countryOfNationality: string;
  mainLanguageSpoken: string;
  otherLngInput: string;
  yearsOfStudyingEnglish: string;
  educationLevel: number;
  occupationLevel: string;
  occupationSector: string;
  reasonForTakingTest: string;
  countryBeingApplied: string;
};

export type CreateAccountRequest = Omit<CandidateB2C, 'id' | 'tokenResponse' | 'username'>;

export const ValidCandidateMarketingQuestionAnswerCount = 8;

export type CandidateMarketingQuestionAnswer = {
  isValid: boolean;
  answers: MarketingQuestionAnswer[];
};
