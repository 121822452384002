import {
  candidatesEnglishOnlineClient,
  registrationGelIntegrationClient as registrationGelIntegrationClientMod,
} from 'ors-api/mod';
import { registrationGelIntegrationClient as registrationGelIntegrationClientIol } from 'ors-api/iol';
import { getCtxFromProductId } from 'ors-utils';

export const openGel = async (registrationId: number, productId: string) => {
  const ctx = getCtxFromProductId(productId);
  const url =
    ctx === 'iol'
      ? await registrationGelIntegrationClientIol.getExternalLink(registrationId)
      : await registrationGelIntegrationClientMod.getExternalLink(registrationId);

  url.data && window.open(decodeURI(url.data), '_self');
};

export const getMemberAreaUrl = async () => {
  return (await candidatesEnglishOnlineClient.generateEnglishOnlineExternalLink()).data;
};

export const noTargetScoreSelectedValue = 'noScoreSelected';
