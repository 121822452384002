import { createAsyncThunk } from '@reduxjs/toolkit';
import queryString from 'query-string';
import { ApiErrorDetailed, extractApiError } from '@britishcouncil/react-common';
import {
  AvailableLrwSessionDto,
  AvailableLrwSessionWithSpeakingSlotsDto,
  candidatesClient,
  CreateReservationRequest,
  CreateOrUpdateReservationResponse,
  productsClient,
  reservationClient,
  VerifiedCandidateDetailsDto,
  IELTSProducts,
} from 'ors-api/iol';

import { logger } from 'ors-utils';
import { RootState } from '.';
import { ApiErrorsList, extractApiErrors } from 'ors-ui';
import { ExamType } from './registration';
import { getRegFlow } from 'core';

const IOL_AVAILABILITY_TIMEOUT = 10;
const sliceName = 'api-iol';

/**
 * Get IOL availability by country
 */
export const isAnyAvailable = createAsyncThunk<
  boolean,
  { productId?: IELTSProducts; examType?: ExamType },
  { state: RootState; rejectValue: ApiErrorDetailed }
>(sliceName + '/isAnyAvailable', async ({ productId, examType }, { rejectWithValue }) => {
  const isUkvi = getRegFlow() === 'ukvi';
  const isExamTypeAc = examType === 'ac';
  const productUOL = isExamTypeAc ? IELTSProducts.UkviOnlineAC : IELTSProducts.UkviOnlineGT;
  const productIOL = isExamTypeAc ? IELTSProducts.OnlineAC : IELTSProducts.OnlineGT;
  const derivedProductId = productId ?? (isUkvi ? productUOL : productIOL);

  try {
    const response = await productsClient.isAnyAvailable(derivedProductId, {
      timeout: 1000 * IOL_AVAILABILITY_TIMEOUT,
    });
    return response.data;
  } catch (error) {
    logger.logError(error as Error);
    const err = extractApiError(error);
    return rejectWithValue(err);
  }
});

/**
 * Get available dates for IOL Exam
 */
export const getLrwExamDates = createAsyncThunk<
  AvailableLrwSessionDto[],
  IELTSProducts,
  { state: RootState; rejectValue: ApiErrorDetailed }
>(sliceName + '/getLrwExamDates', async (productId, { rejectWithValue }) => {
  try {
    const response = await productsClient.getLrwExamDates(productId);
    return response.data;
  } catch (error) {
    logger.logError(error as Error);
    const err = extractApiError(error);
    return rejectWithValue(err);
  }
});

/**
 * Get available exams for selected date
 */
export const getExams = createAsyncThunk<
  AvailableLrwSessionWithSpeakingSlotsDto[],
  GetOnlineExamsArgs,
  { state: RootState; rejectValue: ApiErrorDetailed }
>(sliceName + '/getExams', async (req, { rejectWithValue }) => {
  try {
    const response = await productsClient.getLrwSessionsWithSpeakingSlots(
      req.productId,
      req.lrwSessionIds,
      {
        paramsSerializer: (params) => queryString.stringify(params),
      }
    );
    return response.data;
  } catch (error) {
    logger.logError(error as Error);
    const err = extractApiError(error);
    return rejectWithValue(err);
  }
});

/**
 * Create reservation for IOL
 */

export const createReservationIol = createAsyncThunk<
  CreateOrUpdateReservationResponse & { productId?: IELTSProducts },
  CreateReservationRequest,
  { state: RootState; rejectValue: ApiErrorsList }
>(sliceName + '/createReservationIol', async (request, { rejectWithValue }) => {
  try {
    const response = await reservationClient.create(request);
    return { ...response.data, productId: request.productId as IELTSProducts };
  } catch (error: any) {
    logger.logError(error as Error);
    const err = extractApiErrors(error);
    return rejectWithValue(err);
  }
});

/**
 * Get candidate's verified details
 */
export const getVerifiedDetailsIol = createAsyncThunk<
  VerifiedCandidateDetailsDto,
  IELTSProducts,
  { state: RootState; rejectValue: ApiErrorDetailed }
>(sliceName + '/getVerifiedDetailsIol', async (productId, { rejectWithValue }) => {
  try {
    const response = await candidatesClient.getVerifiedDetails(productId);
    return response.data;
  } catch (error) {
    logger.logError(error as Error);
    const err = extractApiError(error);
    return rejectWithValue(err);
  }
});

interface GetOnlineExamsArgs {
  productId: IELTSProducts;
  lrwSessionIds: number[];
}
