import slice, { initialState } from './personalDetailsSlice';
import * as apiOrs from './api/api.ors';
import * as thunksCommon from './thunks/thunks.common';
import * as thunksOrs from './thunks/thunks.ors';
import * as thunksIol from './thunks/thunks.iol';

export * from './routeValidation';
export * from './models';
export * from './personalDetails.service';

export const personalDetails = {
  ...slice,
  apiOrs,
  thunksCommon,
  thunksOrs,
  thunksIol,
  initialState,
};
