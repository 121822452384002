import { GoogleTagManager } from './googleTagManager';
import { SS } from '@britishcouncil/react-common';
import { omit } from 'lodash';

import { CookieManager } from 'ors-utils';
import { FullRegistrationDataLayer } from './models';
import { dataLayerClient } from 'ors-api/ga';
import { getDataLayerJson } from './dataLayerJson';

const GTM_LS_KEY = 'GTM_DL';

/** This method stores in LS the updated dataLayer and then emits new stuff to GTM */
export function updateDataLayer(value: Partial<FullRegistrationDataLayer>) {
  if (!value) {
    return;
  }

  const val = SS.read<Partial<FullRegistrationDataLayer>>(GTM_LS_KEY);
  const updated = { ...val, ...value };
  SS.write(GTM_LS_KEY, updated);

  GoogleTagManager.updateDataLayer(value);
}

function updateDataLayerArray(value: any[]) {
  const val = SS.read<Partial<FullRegistrationDataLayer>>(GTM_LS_KEY);
  const mergedDataLayer = {};
  value
    .filter((e) => e.event)
    .forEach((e) => {
      const parsedEvent = JSON.parse(e.event);
      GoogleTagManager.updateDataLayer(parsedEvent);
      Object.assign(mergedDataLayer, parsedEvent);
    });

  const ignored = [
    'autoTranslated',
    'event',
    'gtm.uniqueEventId',
    'virtualPageTitle',
    'virtualPageURL',
  ];
  const updated = omit({ ...val, ...mergedDataLayer }, ignored);

  SS.write(GTM_LS_KEY, updated);
}

/** Use it from VirtualPageView to populate dataLayer with persisted state */
export function getStoredData(): Partial<FullRegistrationDataLayer> {
  return SS.read<Partial<FullRegistrationDataLayer>>(GTM_LS_KEY);
}

/** Just clear the storage of the GTM dataLayer. It does not touch dataLayer directly */
export function clearDataLayer() {
  SS.remove(GTM_LS_KEY);
}

/**
 * This method will take whole Datalayer saved in Session Storage, serialize it
 * and send it to a remote server with Redis, where it can be stored and retrieved when necessary.
 * All of this to just match payment with the journey in Google Analytics -,-'
 * Use it just before redirecting user from the app to a different domain.
 * NPP expects to receive ARRAY as dataLayer
 */
export function postDataLayer() {
  // const data = getStoredData();
  const clientId = CookieManager.getCookie('_ga');
  const dataLayer = getDataLayerJson().toString();

  // Don't even ask why it's like this. For some reason that's how was done in NPP / old CJ
  // (╯°□°)╯︵ ┻━┻
  // const serializedData = JSON.stringify([{ event: JSON.stringify(data) }]);

  if (clientId && !!dataLayer) {
    dataLayerClient.post({ clientId, dataLayer }).catch(() => {});
  }

  return dataLayer;
}

/**
 * Uses GA cookie to download persisted Datalayer from the Redis service
 * If it fails, then no worries. Cache is quite short and in general we
 * should not worry too much about this service.
 * Use it just after loading page where there is a strong indication that it's
 * a journey continuation. Using it on the Start Page is ridiculous ;)
 */
export function restoreDataLayer() {
  const clientId = CookieManager.getCookie('_ga');

  if (clientId) {
    return dataLayerClient
      .get(clientId)
      .then((res) => {
        const str = res.data.dataLayer;
        if (!str) {
          return;
        }

        const dataLayer = JSON.parse(str);
        if (Array.isArray(dataLayer)) {
          updateDataLayerArray(dataLayer);
        } else {
          updateDataLayer(dataLayer);
        }
      })
      .catch(() => {});
  }
}
