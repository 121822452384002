import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { FormFields } from '@britishcouncil/react-forms';
import { Button } from '@britishcouncil/react-solas';

type ButtonProps = Omit<React.ComponentProps<typeof Button>, 'children'>;

type Props = ButtonProps & {
  processing?: boolean;
  fields?: FormFields<any>;
  inChangeMode?: boolean;
  buttonTestid?: string;
  errorTestId?: string;
  scrollLink?: ReactElement;
  textKey?: string;
};

export const isErrorVisible = (fields: FormFields<any>): boolean => {
  const errField = Object.keys(fields).find((f) => fields[f].error && fields[f].touched);
  return !!errField;
};

export const ProceedBtn = ({
  processing,
  inChangeMode,
  fields,
  buttonTestid = 'save-and-continue',
  errorTestId = 'form-validation-error',
  scrollLink,
  textKey,
  ...props
}: Props) => {
  const { t } = useTranslation();
  const showError = fields ? isErrorVisible(fields) : false;
  return (
    <Root>
      <Button
        type="submit"
        intent="primary"
        role="button"
        loading={props.loading || processing}
        disabled={props.disabled || processing}
        data-testid={buttonTestid}
        {...props}
      >
        {textKey
          ? t(textKey)
          : inChangeMode
          ? t('APPB2C.cj.basic.goToReview')
          : t('APPB2C.cj.basic.cta')}
      </Button>
      {showError && (
        <ValError data-testid={errorTestId}>
          {t('APPB2C.cj.basic.validationError')}
          {scrollLink}
        </ValError>
      )}
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  button {
    margin-right: 30px;
  }
`;

export const ValError = styled.span`
  border-left: 5px solid #a94442;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  color: #a94442;
  padding: 3px 10px;
  max-width: 335px;
  line-height: 20px;
  margin-top: 10px;
  & > button {
    padding: 0;
    margin: 0;
    border: 0;
    text-decoration: none;
  }
`;
