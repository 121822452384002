import { AgentCandidateInvitationContextState } from 'ors-api/mod';

import slice from './invitationSlice';
import { AppThunk } from '..';
import { history } from '../historyState';
import { getInvitationContextState } from './api';
import { appRoutes, navigateTo, routeWithBase } from 'routing';
import { GTM } from 'core';
import { clearInvitation } from '../_common/actions';

export const getInvitationContext =
  (token: string): AppThunk =>
  async (dispatch, getState) => {
    dispatch(slice.actions.setDeeplinkToken(token));
    const { payload } = await dispatch(getInvitationContextState());

    if (payload && 'state' in payload) {
      trackDeeplinks(payload.state);
      return payload.state === AgentCandidateInvitationContextState.Erroneous
        ? history.push(routeWithBase(appRoutes.notFound))
        : navigateTo(appRoutes.root);
    }

    navigateTo(appRoutes.root);
  };

export const clearInvitationContext = (): AppThunk => async (dispatch, getState) => {
  dispatch(clearInvitation());
  navigateTo(appRoutes.root);
};

const trackDeeplinks = (deeplinkState: AgentCandidateInvitationContextState) => {
  const action = 'B2B deeplinks';

  switch (deeplinkState) {
    case AgentCandidateInvitationContextState.Erroneous:
      GTM.trackError('Link erroneous', action);
      return;
    case AgentCandidateInvitationContextState.NotActive:
      GTM.trackError('Link inactive', action);
      return;
    case AgentCandidateInvitationContextState.Empty:
      GTM.trackError('Link empty', action);
      return;
    default:
      return;
  }
};
