import { getUrlSearchParam } from '@britishcouncil/react-common';
import { AFFILIATION_PREMIUM_CODE_KEY, CookieManager } from 'ors-utils';
import { GTM } from 'core';
import { isNumber } from 'lodash-es';

export const trackPremiumCodes = () => {
  const premiumCodeProvider = getUrlSearchParam('premiumcodeprovider') || undefined;
  const premiumCodeRequestId = getUrlSearchParam('premiumcoderequestid') || undefined;
  const premiumcode = getUrlSearchParam('premiumcode') || undefined;
  const premiumCodeTimeToLive = getUrlSearchParam('premiumCodeTimeToLive') || undefined;

  if (premiumcode && premiumCodeTimeToLive && isNumber(parseInt(premiumCodeTimeToLive))) {
    CookieManager.setCookie(
      AFFILIATION_PREMIUM_CODE_KEY,
      premiumcode,
      parseInt(premiumCodeTimeToLive)
    );
  }

  premiumCodeProvider &&
    GTM.update({
      premiumCodeProvider,
      premiumCodeRequestId,
    });
};
