import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { appRoutes } from 'routing';
import { useSelector } from 'core';

export const useChangePass = () => {
  const location = useLocation();

  const navigate = useNavigate();
  const { unfulfilled_policies } = useSelector((state) => state.auth);

  useEffect(() => {
    unfulfilled_policies?.includes('CustomResetPasswordAtFirstLogin') &&
      navigate(appRoutes.auth.changePsw);
  }, [navigate, unfulfilled_policies, location]);
};
