import { ApiError } from '@britishcouncil/react-common';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AvailableDistrictExamDto } from 'ors-api/mod';

import { globalCleanRegistration } from '../_common/actions';
import { SortOrder, BookTestState } from './model';
import { extraReducersIol, extraReducersOrs } from './extraReducers';

export const sliceName = 'bookTest';

const initApiData = {
  isLoading: false,
};

export const initialState: BookTestState = {
  examsIol: initApiData,
  loadingData: true,
  loadingMoreData: false,
  sortOrder: 'date',
  loadDataError: ApiError.None,
  loadExamDetailsError: ApiError.None,
  currentPage: 1,
  lastPageCount: 0,
};

/**
 * Ephemeral slice responsible for showing data for BookTest page.
 * It would be great to remove it and move logic directly to the forms
 * (or at least limit responsibilites of the store here)
 */
const slice = createSlice({
  name: sliceName,
  initialState: initialState,
  reducers: {
    setSortOrder(state, action: PayloadAction<SortOrder>) {
      state.sortOrder = action.payload;
    },
    setLoadingExamDetails(state, action: PayloadAction<number | undefined>) {
      state.loadingExamDetails = action.payload;
      if (action.payload) {
        state.loadExamDetailsError = ApiError.None;
      }
    },
    setLoadExamDetailsError(state, action: PayloadAction<ApiError>) {
      state.loadExamDetailsError = action.payload;
    },
    setSelectedExam(state, action: PayloadAction<AvailableDistrictExamDto | undefined>) {
      state.selectedExam = action.payload;
    },
    setCurrentPage(state, action: PayloadAction<number>) {
      state.currentPage = action.payload;
    },
    nextPage(state) {
      state.currentPage = state.currentPage + 1;
    },
    updateState(state, action: PayloadAction<Partial<BookTestState>>) {
      return {
        ...state,
        ...action.payload,
      };
    },
    clearExams(state) {
      state.exams = initialState.exams;
      state.examsIol = initialState.examsIol;
      state.selectedExam = initialState.selectedExam;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(globalCleanRegistration, () => initialState);
    extraReducersOrs(builder);
    extraReducersIol(builder);
  },
});

export default slice;
export const { setLoadExamDetailsError } = slice.actions;
