import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OrganisationConfigurationDto, OrganisationCountryConfigurationDto } from 'ors-api/mod';
import { ApiData } from 'ors-ui';

import { OrganisationCountryState } from './models';

export const sliceName = 'organisationCountry';
/** Alias used to indicate, that we should use general branding within the app. */
export const BC_ORG_ALIAS = 'bc';

export const initialState: OrganisationCountryState = {
  orgCountryDetails: { isLoading: false },
  organisationDetails: { isLoading: false },
};

/** Slice for organisation and organisation country data */
const slice = createSlice({
  name: sliceName,
  initialState: initialState,
  reducers: {
    resetOrganisation(state) {
      state.organisationDetails = { data: undefined, isLoading: false };
      state.organisationAlias = undefined;
    },
    setOrganisationAlias(state, action: PayloadAction<string>) {
      state.organisationAlias = action.payload;
    },
    setOrganisationDetails(state, action: PayloadAction<ApiData<OrganisationConfigurationDto>>) {
      state.organisationDetails = action.payload;
    },
    setOrgCountryDetails(
      state,
      action: PayloadAction<ApiData<OrganisationCountryConfigurationDto>>
    ) {
      state.orgCountryDetails = action.payload;
    },
  },
});

export default slice;
