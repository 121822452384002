import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { Button } from '@britishcouncil/react-solas';
import { ExamFormat } from 'ors-api/ors2';
import {
  AvailableDistrictExamDto,
  ExistingRegistrationDto as ExistingRegistrationDtoMod,
  AvailableSpeakingSlotDto as AvailableSpeakingSlotDtoMod,
} from 'ors-api/mod';
import { examTypeDescription } from 'ors-utils';
import { IolDetailsCard, Modal, TestDetailsCard } from 'ors-ui';

import { getRegFlow, useLocale, useSelector } from 'core';
import { appRoutes, useNavigateTo } from 'routing';
import { LocationCoords } from 'store/searchSelect';
import { getExamFullDateTime } from '../../../TestDetails/iol/utils/getTitles';
import { useExistingRegistrations } from '../hooks';

const getLrwDetailsMod = (regData: ExistingRegistrationDtoMod): AvailableDistrictExamDto => {
  return {
    plannedExamId: 0,
    examDate: regData.details?.lrwExam?.examDate || '',
    startTime: regData.details?.lrwExam?.startTimeDate || '',
    endTime: regData.details?.lrwExam?.endTimeDate || '',
    examFormat: regData.details?.examFormat || ExamFormat.PB,
    districtId: 0,
    lastSeats: false,
    displayVenues: regData.details?.displayVenues || false,
    fee: 0,
    venueId: 0,
    venueName: regData.details?.lrwExam?.venue?.name,
    venueLatitude: regData.details?.lrwExam?.venue?.latitude,
    venueLongitude: regData.details?.lrwExam?.venue?.longitude,
    venueStreetAddress1: regData.details?.lrwExam?.venue?.streetAddress1,
    venueStreetAddress2: regData.details?.lrwExam?.venue?.streetAddress2,
    venueCity: regData.details?.lrwExam?.venue?.city,
    venuePostCode: regData.details?.lrwExam?.venue?.postcode,
    venueState: regData.details?.lrwExam?.venue?.state,
    isMixedSession: false,
    linkedVenuesForCandidatesEnforced: false,
    awardingBodySystem: 2,
    linkingVenuesEnabled: false,
    organisationCountryId: 1,
    centreId: 0,
    candidateArrivalTime: 0,
    speakingCandidateArrivalTime: 0,
    netFee: 0,
  };
};

const getSpeakingSlotMod = (
  regData: ExistingRegistrationDtoMod
): AvailableSpeakingSlotDtoMod | undefined => {
  const { details } = regData;
  return details?.speakingExam
    ? {
        date: details.speakingExam.examDate!,
        startTime: details.speakingExam.startTimeDate!,
        endTime: details.speakingExam.endTimeDate!,
        organisationId: 0,
        isBcOrganisation: false,
        districtId: 0,
        centreId: 0,
        displayVenues: true,
        venueId: 0,
        speakingFormat: details.speakingFormat,
        venueName: details.speakingExam.venue?.name,
        venueLatitude: details.speakingExam.venue?.latitude,
        venueLongitude: details.speakingExam.venue?.longitude,
        venueStreetAddress1: details.speakingExam.venue?.streetAddress1,
        venueStreetAddress2: details.speakingExam.venue?.streetAddress2,
        venueCity: details.speakingExam.venue?.city,
        venuePostCode: details.speakingExam.venue?.postcode,
        venueState: details.speakingExam.venue?.state,
        isVenueLinked: false,
      }
    : undefined;
};

export const ExistingRegistrationsModal = ({
  setFieldValue,
  inChangeModeExamToCheck,
  handleBook,
  handleBookIol,
  iolExamDateToCheck,
}: {
  setFieldValue?: (field: string, value: string) => void;
  inChangeModeExamToCheck?: AvailableDistrictExamDto;
  handleBook?: (exam: AvailableDistrictExamDto) => void;
  handleBookIol?: () => void;
  iolExamDateToCheck?: string;
}) => {
  const { changeMode } = useSelector((state) => state.registration);
  const { isLoggedIn } = useSelector((state) => state.auth);
  const isMapViewEnabled = useSelector((s) => s.featureFlags.features.isMapViewEnabled);

  const { t } = useTranslation();
  const { locale, localeDate } = useLocale();
  const navigate = useNavigateTo();
  const isIol = getRegFlow() === 'iol';

  const {
    isModalOpen,
    setIsModalOpen,
    existingRegDataIol,
    existingRegDataMod,
    examFlow,
    isUkviLifeSkills,
    clearData,
  } = useExistingRegistrations(
    inChangeModeExamToCheck,
    handleBook,
    handleBookIol,
    iolExamDateToCheck
  );

  const detailsIol = existingRegDataIol?.details;
  const detailsMod = existingRegDataMod?.details;

  const lrwExamDateTimeIol = getExamFullDateTime(
    detailsIol?.lrwExam?.startDateTime,
    detailsIol?.lrwExam?.endDateTime,
    locale
  );
  const speakingExamDateTimeIol = getExamFullDateTime(
    detailsIol?.speakingExam?.startDateTime,
    detailsIol?.speakingExam?.endDateTime,
    locale
  );
  const speakingLocationCoords: LocationCoords = {
    latitude: Number(detailsMod?.speakingExam?.venue?.latitude),
    longitude: Number(detailsMod?.speakingExam?.venue?.longitude),
  };

  const speakingSlotMod = existingRegDataMod ? getSpeakingSlotMod(existingRegDataMod) : null;

  const ttpPath = detailsIol
    ? `/ttp/${examFlow}/test-details/${detailsIol?.registrationId}/date-location`
    : `${`/ttp/${examFlow}/`}test-details/${detailsMod?.registrationId}/date-location`;

  const subtitle = detailsIol
    ? detailsIol?.lrwExam?.startDateTime &&
      `${t('APPB2C.cj.personalDetails.existingRegistrationsModal.subtitle')} ${localeDate(
        detailsIol.lrwExam.startDateTime
      )} for ${detailsIol?.firstName} ${detailsIol?.surname} `
    : detailsMod?.lrwExam?.examDate &&
      `${t('APPB2C.cj.personalDetails.existingRegistrationsModal.subtitle')} ${localeDate(
        detailsMod.lrwExam.examDate
      )} for ${detailsMod?.firstName} ${detailsMod?.surname} `;

  const bookForSomeoneElse = () => {
    clearData();
    setFieldValue && setFieldValue('forWhom', 'someone-else');
    setIsModalOpen(false);
  };

  return (
    <Modal
      show={!isLoggedIn ? false : isModalOpen}
      onExit={() => null}
      showHideLink={false}
      title={t('APPB2C.cj.personalDetails.existingRegistrationsModal.title')}
      intent="highlight"
      modalProps={{
        alert: true,
        escapeExits: false,
      }}
    >
      <ModalContent>
        <h4 style={{ marginBottom: '12px' }}>{subtitle}</h4>
        {!isIol && !changeMode && (
          <Button size="xs" style={{ margin: '12px 0' }} onClick={bookForSomeoneElse}>
            {t('APPB2C.cj.personalDetails.existingRegistrationsModal.bookSomeoneElse')}
          </Button>
        )}
        <h3 style={{ margin: '12px 0' }}>
          {examTypeDescription(detailsIol ? detailsIol?.productId : detailsMod?.productId)}
        </h3>

        {detailsIol ? (
          <IolDetailsCard
            speakingTestDateTime={speakingExamDateTimeIol}
            lrwTestDateTime={lrwExamDateTimeIol}
            displayColumn
          />
        ) : (
          <>
            <TestDetailsCard>
              {detailsMod && existingRegDataMod && (
                <TestDetailsCard.WritingDetails
                  showVenues={detailsMod.displayVenues}
                  locale={locale}
                  hideLrwTime={isUkviLifeSkills}
                  customLrwExamFormat={isUkviLifeSkills ? 'f2f' : undefined}
                  showArrive={!isUkviLifeSkills}
                  isMapViewEnabled={isMapViewEnabled}
                  {...getLrwDetailsMod(existingRegDataMod)}
                />
              )}
            </TestDetailsCard>

            {!isUkviLifeSkills && (
              <TestDetailsCard>
                {detailsMod && existingRegDataMod && speakingSlotMod && (
                  <TestDetailsCard.SpeakingDetails
                    showVenues={detailsMod.displayVenues || false}
                    locationCoords={speakingLocationCoords}
                    locale={locale}
                    isMapViewEnabled={isMapViewEnabled}
                    {...speakingSlotMod}
                  />
                )}
              </TestDetailsCard>
            )}
          </>
        )}

        <p style={{ margin: '25px 0 0' }}>
          {t('APPB2C.cj.personalDetails.existingRegistrationsModal.viewBooking')}
        </p>
        <Button
          intent="primary"
          style={{ margin: '16px 0' }}
          onClick={() => (window.location.pathname = ttpPath)}
        >
          {t('APPB2C.cj.personalDetails.existingRegistrationsModal.viewCurrentBooking')}
        </Button>
        <Button style={{ margin: '12px 0' }} onClick={() => navigate(appRoutes.search.findTest)}>
          {t('APPB2C.cj.personalDetails.existingRegistrationsModal.changeDate')}
        </Button>
      </ModalContent>
    </Modal>
  );
};

const ModalContent = styled.div`
  max-width: 700px;
  display: flex;
  flex-direction: column;

  .testDateSpeaking,
  .testTimeSpeaking,
  .testDateLrw,
  .testTimeLrw,
  .testTimeAndDate {
    padding: 0 32px;
    margin: 0;
  }
`;
