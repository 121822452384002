import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApiErrorDetailed, SimpleLS, SS } from '@britishcouncil/react-common';
import { DateTime } from 'luxon';
import { TokenResponse } from 'ors-api/ors2';
import { OidcContext } from '@britishcouncil/react-oidc-auth';

import { AppThunk } from '.';
import { candidate } from './candidate';
import { globalLogOut } from './_common/actions';
import { getCandidateChildren } from './personalDetails/api/api.ors';
import { history } from './historyState';

import { invitation } from './invitation';
import { getRegFlow, postDataLayer, useLocale, useSelector } from 'core';
import { getJourneyRouteType } from 'common/getJourneyRoute';
import { appRoutes, routeWithBase } from 'routing';
import { OidcCallbackUser } from '../auth/models';
import { User } from '@britishcouncil/react-oidc-auth';
import { useDispatch } from 'core/hooks/useDispatch';
import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';

const sliceName = 'auth';

export const initialState: AuthTokenState = {
  isLoggedIn: false,
  hasJustLoggedIn: false,
  tempAccount: false,
  logInError: undefined,
  logInProcessing: false,
  showLogoutModal: false,
};

// TODO: this slice is almost the same for nCJ and nTTP.
// Maybe make it as a shared slice to avoid bugs and repetitions?
export const slice = createSlice({
  name: sliceName,
  initialState: initialState,
  reducers: {
    loggedIn(state, action: PayloadAction<Partial<AuthTokenState>>) {
      state.accessToken = action.payload.accessToken;
      state.expiresAt = action.payload.expiresAt;
      state.userId = action.payload.userId;
      state.isLoggedIn = true;
      state.hasJustLoggedIn = true;
      state.tempAccount = action.payload.tempAccount || false;
      state.logInError = undefined;
      state.unfulfilled_policies = action.payload.unfulfilled_policies;
      state.idp = action.payload.idp;
    },
    resetHasJustLoggedIn(state) {
      state.hasJustLoggedIn = false;
    },
    setLoadingProcessing(state, action: PayloadAction<boolean>) {
      state.logInProcessing = action.payload;
      state.logInError = state.logInError = undefined;
    },
    toggleShowLogoutModal(state) {
      state.showLogoutModal = !state.showLogoutModal;
    },
    removePolicy(state, action: PayloadAction<string>) {
      const policyToRemove = action.payload;
      const newPolicies = state.unfulfilled_policies?.filter((p) => p !== policyToRemove);
      state.unfulfilled_policies = newPolicies ?? [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(globalLogOut, () => initialState);
  },
});

export interface AuthTokenState {
  /** Flag indicating if user is logged in using a temporary account. */
  tempAccount: boolean;
  isLoggedIn: boolean;
  hasJustLoggedIn: boolean;
  accessToken?: string;
  expiresAt?: string;
  userId?: number;
  logInError?: ApiErrorDetailed;
  logInProcessing?: boolean;
  showLogoutModal: boolean;
  unfulfilled_policies?: string[];
  idp?: string;
}

export interface EamAuthIdentity {
  token_type?: string;
  user_id?: any;
  expires_in?: number;
  unfulfilled_policies?: string[];
  valid_till: string;
  id_token?: string;
  access_token: string;
  idp?: string;
}

export interface StartLoginParams {
  isIeltsReadyLogin?: boolean;
  allowAzureB2CAccountCreation?: boolean;
  locale?: string;
  redirectToPersonalDetails?: boolean;
}

export default slice;
export const { toggleShowLogoutModal } = slice.actions;
export const reducer = slice.reducer;

const removeAuthKeysFromLS = (): void => {
  SimpleLS.remove('SECURITY_AUTH_IDENTITY');
  SimpleLS.remove('USER_PROFILE');
};

export const loadAuthStateFromLS =
  (handleLogOut: (redirectUrl?: string) => void): AppThunk =>
  async (dispatch, getState) => {
    const eamAuthIdentity = SimpleLS.read<EamAuthIdentity>('SECURITY_AUTH_IDENTITY', {});
    const tempAccount = getState().auth.tempAccount;
    const isLoggedIn = getState().auth.isLoggedIn;
    const tokenExpired = DateTime.fromISO(eamAuthIdentity.valid_till) <= DateTime.local();

    if (eamAuthIdentity && eamAuthIdentity.access_token) {
      if (tokenExpired) {
        return handleLogOut();
      }

      const authRes: Partial<AuthTokenState> = {
        accessToken: eamAuthIdentity.access_token,
        expiresAt: eamAuthIdentity.valid_till,
        userId: parseInt(eamAuthIdentity.user_id, 10),
        tempAccount,
        unfulfilled_policies: eamAuthIdentity.unfulfilled_policies,
        idp: eamAuthIdentity.idp,
      };
      dispatch(slice.actions.loggedIn(authRes));
    } else if (isLoggedIn) {
      dispatch(globalLogOut());
    }
    dispatch(candidate.thunks.loadStateFromLS());
  };

export const B2B_INVITATION_KEY = 'B2B_INVITATION';

export const logIn =
  (user: OidcCallbackUser): AppThunk =>
  async (dispatch, getState) => {
    dispatch(slice.actions.setLoadingProcessing(true));

    if (user?.state?.deeplinkToken) {
      dispatch(invitation.actions.setDeeplinkToken(user.state.deeplinkToken));
      dispatch(
        invitation.actions.setInvitationContext({
          invitationContext: user.state.invitationContext.data,
        })
      );
    }

    dispatch(persistAuth(user));
    await dispatch(candidate.thunks.loadProfile());
    dispatch(slice.actions.setLoadingProcessing(false));

    const profileId = getState().candidate.profile?.id ?? 0;
    const missingDetails = getState().candidate.profile?.needToSupplyMissingDetails ?? false;
    const shouldLoadUsersDependants = profileId !== 0 && missingDetails === false;
    shouldLoadUsersDependants && dispatch(getCandidateChildren());

    const mustChangePass = user?.profile?.unfulfilled_user_policies
      ? JSON.parse(user.profile.unfulfilled_user_policies as string)[0] ===
        'CustomResetPasswordAtFirstLogin'
      : false;

    if (user?.state?.isIeltsReadyLogin) {
      history.replace(
        !!getState().candidate.profile?.needToSupplyMissingDetails
          ? appRoutes.journey.ieltsReadyReg
          : appRoutes.journey.ieltsReadyRedirect
      );
    } else {
      const pathName = mustChangePass
        ? routeWithBase(appRoutes.auth.changePsw)
        : user?.state?.location?.pathname ?? '/';

      const search = new URLSearchParams(user?.state?.location?.search);
      mustChangePass && search.set('returnUrl', user?.state?.location?.pathname);

      history.replace(`${pathName}?${search.toString()}`, user?.state?.location ?? '/');
    }
  };

export const persistAuth =
  (user: User): AppThunk =>
  async (dispatch) => {
    const auth = {
      ...user,
      user_id: user.profile.sub,
      valid_till: new Date((user.expires_at || 1) * 1000).toISOString(),
      unfulfilled_policies: user?.profile?.unfulfilled_user_policies
        ? JSON.parse(user?.profile?.unfulfilled_user_policies as string)
        : undefined,
      idp: user.profile.idp as string,
    };

    dispatch(
      slice.actions.loggedIn({
        tempAccount: false,
        accessToken: auth.access_token,
        expiresAt: auth.valid_till,
        userId: parseInt(auth.user_id, 10),
        unfulfilled_policies: auth?.unfulfilled_policies,
        idp: auth.idp,
      })
    );
    SimpleLS.write('SECURITY_AUTH_IDENTITY', auth);
  };

export const persistTempAuth =
  (token: TokenResponse): AppThunk =>
  async (dispatch) => {
    const auth = {
      access_token: token.accessToken,
      expires_in: token.expiresIn,
      token_type: token.tokenType,
      user_id: 0,
      valid_till: new Date(new Date().getTime() + token.expiresIn * 1000).toISOString(),
      unfulfilled_policies: null,
    };

    dispatch(
      slice.actions.loggedIn({
        tempAccount: true,
        accessToken: auth.access_token,
        expiresAt: auth.valid_till,
        userId: auth.user_id,
      })
    );
    SimpleLS.write('SECURITY_AUTH_IDENTITY', auth);
  };

export const removePolicy =
  (policy: string): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.removePolicy(policy));

    let eamAuthIdentity = SimpleLS.read<EamAuthIdentity>('SECURITY_AUTH_IDENTITY', {});

    const newPolicy = {
      ...eamAuthIdentity,
      unfulfilled_policies: eamAuthIdentity.unfulfilled_policies?.filter((p) => p !== policy),
    };

    SimpleLS.write('SECURITY_AUTH_IDENTITY', newPolicy);
  };

export const useAuthSlice = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { locale } = useLocale();
  const { signinRedirect, signoutRedirect, events, settings } = OidcContext.useAuth();

  const { invitation } = useSelector((s) => s);
  const { organisationAlias } = useSelector((s) => s).organisationCountry;
  const { unfulfilled_policies } = useSelector((s) => s).auth;

  const handleStartLogIn = useCallback(
    (params: StartLoginParams = {}) => {
      postDataLayer();
      const flow = getRegFlow();
      const redirectPathname = params.redirectToPersonalDetails
        ? `/${flow}/personal-details`
        : history.location.pathname;

      /**
       * Keep invitation context in session storage to retrieve it when user comes back to app
       * using browser's "Back" button.
       */
      SS.write(B2B_INVITATION_KEY, invitation);

      const extraQueryParams = {
        ...(organisationAlias && { organisationAlias }),
        ui_locales: locale,
        allowAzureB2CAccountCreation: params?.allowAzureB2CAccountCreation ?? false,
        requestSource: params?.isIeltsReadyLogin ? 'IELTS_READY_MEMBER' : 'BOOKING',
      };

      signinRedirect({
        redirectMethod: 'replace',
        extraQueryParams,
        state: {
          location: { ...history.location, pathname: redirectPathname },
          ...invitation,
          ...params,
        },
      });
    },
    [locale]
  );

  const handleLogOut = (redirectUrl?: string) => {
    const userLs = SimpleLS.read<EamAuthIdentity>('SECURITY_AUTH_IDENTITY');

    removeAuthKeysFromLS();
    dispatch(globalLogOut());
    const isOnSearchScreens = getJourneyRouteType() === 'search';

    const extraQueryParams = {
      ui_locales: locale,
      ...(organisationAlias && { organisationAlias }),
      viewCustomizationClientId: settings.client_id,
    };

    const postRedirectUrl =
      !redirectUrl && !isOnSearchScreens
        ? `${window.location.origin}${appRoutes.root}`
        : redirectUrl;

    userLs?.id_token
      ? signoutRedirect({
          redirectMethod: 'replace',
          extraQueryParams,
          post_logout_redirect_uri: postRedirectUrl,
          id_token_hint: userLs?.id_token,
        })
      : navigate(appRoutes.root);
  };

  const subscribeToUserLoaded = () => {
    events.addUserLoaded((user) => {
      dispatch(persistAuth(user));
    });

    const policies = unfulfilled_policies;
    const mustChangePass = policies?.includes('CustomResetPasswordAtFirstLogin');
    if (mustChangePass) {
      const search = new URLSearchParams(window.location.search);
      search.set('returnUrl', window.location.pathname);
      history.push(`${routeWithBase(appRoutes.auth.changePsw)}?${search.toString()}`);
    }
  };

  return { handleStartLogIn, handleLogOut, subscribeToUserLoaded };
};
