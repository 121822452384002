import { ExamDistrictDto } from 'ors-api/mod';
import { buildLocationList } from '../helpers';
import { SearchSelectState } from '../models';

export const onLocationsFullfilled = (state: SearchSelectState, payload: ExamDistrictDto[]) => {
  state.locationsList = { isLoading: false, data: buildLocationList(payload) };

  /* Clears selected location when it's no longer available */
  if (state.locationsList.data && state.activeLocation) {
    if (
      !state.locationsList?.data
        .map((c) => c.ids)
        .flat()
        .some((l) => state.activeLocation?.ids.some((c) => c === l))
    ) {
      state.activeLocation = undefined;
    }
  }
};
