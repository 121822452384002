import { localeFns } from 'ors-utils';

import { useSelector } from './useSelector';

export const useLocale = () => {
  const locale = useSelector((state) => state.language.currentLanguage);
  const { localeTime, localeDate } = localeFns(locale);

  return { locale, localeTime, localeDate };
};
