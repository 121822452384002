import { combineReducers } from '@reduxjs/toolkit';
import { persistReducer } from '@britishcouncil/common-store';

import auth from './authSlice';
import featureFlags from './featureFlagsSlice';
import bookTest from './bookTest/bookTestSlice';
import registration from './registration/registrationSlice';
import candidate from './candidate/candidateSlice';
import idDetails from './idDetails/idDetailsSlice';
import reservation from './reservation/reservationSlice';
import searchSelect from './searchSelect/searchSelectSlice';
import personalDetails from './personalDetails/personalDetailsSlice';
import payment from './payment/paymentSlice';
import invitation from './invitation/invitationSlice';
import organisationCountry from './organisationCountry/organisationCountrySlice';
import storageData from './storageData/storageDataSlice';
import { onRequest } from './onRequest';
import language from './language/languageSlice';
import { api } from './shared/api';

const rootReducer = combineReducers({
  [api.reducerPath]: api.reducer,
  auth: auth.reducer,
  searchSelect: searchSelect.reducer,
  featureFlags: featureFlags.reducer,
  bookTest: bookTest.reducer,
  candidate: candidate.reducer,
  personalDetails: personalDetails.reducer,
  idDetails: idDetails.reducer,
  reservation: reservation.reducer,
  registration: registration.reducer,
  payment: payment.reducer,
  invitation: persistReducer(invitation.reducer, {
    key: 'AGENT_INVITATION',
    storage: localStorage,
    whitelist: ['invitationContext', 'deeplinkToken'],
  }),
  organisationCountry: organisationCountry.reducer,
  storageData: storageData.reducer,
  onRequest: onRequest.reducer,
  language: language.reducer,
});

export default rootReducer;
