import slice, { initialState } from './reservationSlice';
import * as thunksCommon from './thunks.common';
import * as thunksIol from './thunks.iol';
import * as selectors from './selectors';

export * from './models';

export const reservation = {
  ...slice,
  thunksCommon,
  thunksIol,
  selectors,
  initialState,
};
