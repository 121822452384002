import { getUrlSearchParam } from '@britishcouncil/react-common';

import { GetCountryExamDatesRequest, ProductFamilies } from 'ors-api/mod';
import { getOrganisationAlias, hasOrganisationInUrl } from 'ors-utils';

import { GTM } from 'core';
import { appRoutes, navigateTo } from 'routing';
import { converters } from 'common/converters';
import { loadDatesBy, loadLocationsList, refreshFeatureFlags } from '../../api';
import { ExamType } from '../../registration';
import { organisationCountry } from '../../organisationCountry';
import { setOrganisationAlias } from '../../organisationCountry/thunks';
import { AppThunk, ThunkApi } from '../..';
import { getDaysOffset } from '../helpers';
import { chooseExamType } from './thunks.common';
import { searchSelectConverters } from '../sliceConverters';

/**
 * Thunks related to ORS2 flow.
 */

export async function loadReferenceData({ dispatch, getState }: ThunkApi) {
  const organisationAlias = getOrganisationAlias();
  if (organisationAlias) {
    await dispatch(setOrganisationAlias(organisationAlias));
  }
}

export const loadData = (): AppThunk => async (dispatch, getState) => {
  const { examType } = getState().searchSelect;

  if (!examType) {
    const passedType = getUrlSearchParam('examType');
    if (!passedType) {
      return navigateTo(appRoutes.root);
    }
    dispatch(chooseExamType(passedType as ExamType));
  }

  loadReferenceData({ dispatch, getState });
};

export const loadLocations = (): AppThunk => async (dispatch, getState) => {
  const { id } = getState().searchSelect.activeCountry || {};
  const { ieltsModule } = getState().searchSelect;
  const organisationId = organisationCountry.selectors.getOrgId(getState());
  const orgDetailsError = getState().organisationCountry?.organisationDetails?.error;
  const organisationAlias = getOrganisationAlias();
  const fullOrgDataAvailable =
    !hasOrganisationInUrl() ||
    !organisationAlias ||
    orgDetailsError ||
    (!!organisationAlias && !!organisationId);
  const shouldLoad = !!id && fullOrgDataAvailable;

  if (shouldLoad) {
    await dispatch(
      loadLocationsList({
        productFamilyId: ProductFamilies.IELTS,
        countryId: id,
        organisationId: organisationId,
        ieltsModule: ieltsModule,
        examOptionType: undefined,
      })
    );
  }
};

export const loadDatesAvailable = (): AppThunk => async (dispatch, getState) => {
  const locationIds = getState().searchSelect.activeLocation?.ids;
  const organisationId = organisationCountry.selectors.getOrgId(getState());
  const { id: countryId } = getState().searchSelect.activeCountry || {};
  const { filter, ieltsModule, needSpecialReqs, specialReqs, locationCoords } =
    getState().searchSelect;
  const examFormat = filter?.examFormat;
  const shouldGetByCountryId = !!(countryId && locationCoords);

  const days = getDaysOffset(needSpecialReqs ? specialReqs : undefined);
  const request: GetCountryExamDatesRequest = {
    daysToNearestCdExam: days.daysToNearestCdExam,
    daysToNearestPbExam: days.daysToNearestPbExam,
    ieltsModule: searchSelectConverters.ieltsModule.toExamModule(ieltsModule),
    organisationId,
    examFormat: examFormat,
  };

  if (locationIds ?? (shouldGetByCountryId && countryId)) {
    dispatch(
      loadDatesBy({
        request,
        districtIds: locationIds,
        countryId: shouldGetByCountryId ? countryId : undefined,
        productFamily: ProductFamilies.IELTS,
      })
    );
  }
};

export const proceed = (): AppThunk => async (dispatch, getState) => {
  const { filter, activeCountry, activeLocation, showAllDates, needSpecialReqs, specialReqs } =
    getState().searchSelect;
  const specialArrangements = needSpecialReqs ? specialReqs?.filter((e) => e.checked) : [];

  GTM.update({
    specialArrangements: specialArrangements?.map((e) => e.name).join(', '),
    preferredDates: showAllDates ? 'all-dates' : 'date-range',
    venueName: activeLocation?.name || '',
    filter_format: (converters.fromExamFormat(filter?.examFormat).toCode() || '').toUpperCase(),
  });

  GTM.updateCountry({
    country: activeCountry?.isoCode2 || '',
    countryTagCode: activeCountry?.googleTagManagerCode || '',
  });

  dispatch(refreshFeatureFlags());

  navigateTo(appRoutes.search.bookTest);
};
