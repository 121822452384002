import { DataLayerB2C } from '.';

export let dataLayerJson: DataLayerB2C[] = [];

export const updateDataLayerJson = (value: Object) => {
  const dataLayerB2C: DataLayerB2C = {
    event: JSON.stringify(value),
  };

  dataLayerJson.push(dataLayerB2C);
};

export const getDataLayerJson = () => {
  return JSON.stringify(dataLayerJson);
};
