import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { ReservationState } from '../models';
import { preregister } from './helpers';
import { preRegisterExistingChild, preRegisterMyself, preRegisterNewChild } from '../api/api.mod';

/* Pre-register selected exam for ORS */
export const extraReducersMyself = (builder: ActionReducerMapBuilder<ReservationState>) => {
  builder
    .addCase(preRegisterMyself.pending, preregister.pending)
    .addCase(preRegisterMyself.fulfilled, preregister.fullfiled)
    .addCase(preRegisterMyself.rejected, preregister.rejected);
};

export const extraReducersExistingChild = (builder: ActionReducerMapBuilder<ReservationState>) => {
  builder
    .addCase(preRegisterExistingChild.pending, preregister.pending)
    .addCase(preRegisterExistingChild.fulfilled, preregister.fullfiled)
    .addCase(preRegisterExistingChild.rejected, preregister.rejected);
};

export const extraReducersNewChild = (builder: ActionReducerMapBuilder<ReservationState>) => {
  builder
    .addCase(preRegisterNewChild.pending, preregister.pending)
    .addCase(preRegisterNewChild.fulfilled, preregister.fullfiled)
    .addCase(preRegisterNewChild.rejected, preregister.rejected);
};
