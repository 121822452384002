import { ActionReducerMapBuilder } from '@reduxjs/toolkit';

import { CandidateState, ValidCandidateMarketingQuestionAnswerCount } from './types';
import { verifyCandidateLastMarketingAnswers } from '../api';
import { examTypeChanged } from '../_common/actions';
import { apiExtraReducer } from '../_common/apiExtraReducer';

export const extraReducersIc = (builder: ActionReducerMapBuilder<CandidateState>) => {
  builder.addCase(examTypeChanged, (state) => {
    state.iol.idDetailsIol = undefined;
    state.answers = {
      isLoading: false,
      data: {
        isValid: false,
        answers: [],
      },
    };
  });

  apiExtraReducer(builder, verifyCandidateLastMarketingAnswers, 'answers', {
    transform: (data) => {
      return {
        answers: data,
        isValid: data && data.length === ValidCandidateMarketingQuestionAnswerCount,
      };
    },
  });
};
