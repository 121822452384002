import slice, { initialState } from './paymentSlice';
import * as thunks from './thunks';
import * as thunksIol from './thunks.iol';

export * from './models';
export * from './payment.service';
export const payment = {
  ...slice,
  thunks,
  thunksIol,
  initialState,
};
