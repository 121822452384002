import { ExamCountryDto } from 'ors-api/mod';
import {
  defaultLanguage,
  ieltsUsaLanguage,
  pseudoLanguage,
  useLanguageNames,
} from 'ors-translations';

import { useLocale, useSelector } from 'core';

export const useActiveCountryLanguageSettings = () => {
  const { locale } = useLocale();
  const { activeCountry } = useSelector((state) => state.searchSelect);

  const activeCountryName = activeCountry?.name;
  const { activeCountryLangs, isCurrentLangAvailable } = selectedCountryLanguageSettings(
    locale,
    activeCountry
  );
  const langs = useLanguageNames().filter(
    (lang) => lang.code === defaultLanguage || activeCountryLangs?.includes(lang.code)
  );
  const singleLangAvailable = langs.length === 1;
  const currentLangName = useLanguageNames().find((lang) => lang.code === locale)?.name;

  return {
    activeCountryName,
    isCurrentLangAvailable,
    langs,
    singleLangAvailable,
    currentLangName,
  };
};

export const selectedCountryLanguageSettings = (
  currentLanguage: string,
  activeCountry?: ExamCountryDto
) => {
  const frontendLangs = useLanguageNames().map((c) => c.code);
  const activeCountryLangs = activeCountry?.additionalLanguages?.filter((lang) =>
    frontendLangs.includes(lang)
  );

  const activeCountryHasLangs =
    !!activeCountryLangs &&
    activeCountryLangs.length > 0 &&
    !activeCountryLangs.includes(ieltsUsaLanguage);

  const isCurrentLangAvailable = !!activeCountryLangs
    ? activeCountryLangs.includes(currentLanguage) ||
      [defaultLanguage, pseudoLanguage].includes(currentLanguage)
    : true;

  return { activeCountryLangs, activeCountryHasLangs, isCurrentLangAvailable };
};
