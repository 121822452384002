import { FC } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { SecondaryNav, PrimaryNav } from '@britishcouncil/react-solas';
import { includesInUrl } from 'ors-utils';

import { useSelector } from 'core/';
import { appRoutes } from 'routing';
import { useNavigationTabs } from './useNavigationTabs';
import { LoginBtn } from './LoginBtn';
import { LogoutButton } from './LogoutButton';
import { LanguageContainer } from './LanguageContainer';

export const UserNav: FC = () => {
  const { t } = useTranslation();
  const { isLoggedIn } = useSelector((s) => s.auth);
  const { navigationTabs } = useNavigationTabs();
  const fullName = useSelector((s) => s.candidate.userProfile?.fullName);
  const needToSupplyMissingDetails = useSelector(
    (s) => s.candidate.profile?.needToSupplyMissingDetails
  );
  const location = useLocation();

  const isBookingRoute = [
    appRoutes.journey.idDetails,
    appRoutes.journey.personalDetails,
    appRoutes.journey.childDetails,
    appRoutes.journey.review,
    appRoutes.journey.ttProfile,
  ].includes(location.pathname.split('/')[2]);

  /** Remove hyphen when candidate has no surname */
  const candidateName = fullName
    ?.split(' ')
    .filter((v) => v !== '-')
    .join(' ');
  const showCandidateName = !!fullName && !fullName.includes('null');
  const primaryNavVisible =
    isLoggedIn && !isBookingRoute && !includesInUrl(appRoutes.journey.ieltsReadyReg);

  return (
    <>
      <PrimaryNav>
        {primaryNavVisible &&
          navigationTabs
            .filter((tab) => tab.loginAccess === isLoggedIn)
            .map((tab) => (
              <li key={tab.key}>
                {!tab.externalLink && (
                  <Link to={tab.target} data-testid={tab.testDataId}>
                    {t(tab.translationKey)}
                  </Link>
                )}
                {tab.externalLink && (
                  <a
                    href={window.location.origin + tab.target + window.location.search}
                    data-testid={tab.testDataId}
                  >
                    {t(tab.translationKey)}
                  </a>
                )}
              </li>
            ))}
      </PrimaryNav>
      <SecondaryNav>
        <LanguageContainer />
        {isLoggedIn ? (
          <>
            {!needToSupplyMissingDetails && (
              <Hello>
                <span>
                  {t('APPB2C.common.nav.hello')}
                  {showCandidateName ? (
                    <b data-testid="nav.loggedInUsername"> {candidateName}</b>
                  ) : (
                    '!'
                  )}
                </span>
              </Hello>
            )}
            <li>
              <LogoutButton />
            </li>
          </>
        ) : (
          <li>
            <LoginBtn />
          </li>
        )}
      </SecondaryNav>
    </>
  );
};

export interface BookingConfParams {
  registrationId: string;
  type: string;
}

const Hello = styled.li`
  @media (max-width: 849px) {
    display: none;
  }
`;
