import { configureStore, Action } from '@reduxjs/toolkit';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import {
  persistReducer,
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import expireReducer from 'redux-persist-expire';
import { PersistConfig, PersistedState } from 'redux-persist/es/types';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

import { api } from './shared/api';
import rootReducer from './rootReducer';
import appConfig from 'appConfig';

export * from './_common/actions';

const migrations = (state: PersistedState, newVersion: number): Promise<PersistedState> => {
  const storedVersion = state?._persist?.version;
  if (storedVersion && newVersion > storedVersion) {
    return Promise.resolve({} as any);
  }

  return Promise.resolve(state);
};

const persistConfig: PersistConfig<any> = {
  key: 'root',
  storage,
  whitelist: [
    'auth',
    'invitation',
    'searchSelect',
    'candidate',
    'personalDetails',
    'idDetails',
    'storageData',
    'featureFlags',
    'reservation',
    'registration',
    'organisationCountry',
    'payment',
    'bookTest',
    'findTest',
    'onRequest',
    'language',
    'accessibility',
  ],
  version: appConfig.storeVersion,
  migrate: migrations,
  stateReconciler: autoMergeLevel2,

  transforms: [
    expireReducer('auth', { expireSeconds: 3600 * 24, autoExpire: true }),
    expireReducer('invitation', { expireSeconds: 3600 * 24, autoExpire: true }),
    expireReducer('searchSelect', { expireSeconds: 3600 * 24, autoExpire: true }),
    expireReducer('candidate', { expireSeconds: 3600 * 24 * 7, autoExpire: true }),
    expireReducer('personalDetails', { expireSeconds: 3600 * 24 * 7, autoExpire: true }),
    expireReducer('idDetails', { expireSeconds: 3600 * 24 * 7, autoExpire: true }),
    expireReducer('storageData', { expireSeconds: 3600 * 24 * 7, autoExpire: true }),
    expireReducer('featureFlags', { expireSeconds: 3600 * 24 * 7, autoExpire: true }),
    expireReducer('reservation', { expireSeconds: 3600 * 24, autoExpire: true }),
    expireReducer('registration', { expireSeconds: 3600 * 24 * 7, autoExpire: true }),
    expireReducer('organisationCountry', { expireSeconds: 3600 * 24 * 7, autoExpire: true }),
    expireReducer('language', { expireSeconds: 3600 * 24 * 7, autoExpire: true }),
    expireReducer('payment', { expireSeconds: 300, autoExpire: true }),
    expireReducer('bookTest', { expireSeconds: 300, autoExpire: true }),
    expireReducer('findTest', { expireSeconds: 300, autoExpire: true }),
    expireReducer('onRequest', { expireSeconds: 300, autoExpire: true }),
    expireReducer('accessibility', { expireSeconds: 3600 * 24, autoExpire: true }),
  ],
};

const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(api.middleware),
  reducer: persistReducer(persistConfig, rootReducer),
});

export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnData = void> = ThunkAction<
  ReturnData,
  RootState,
  undefined,
  Action<string>
>;

export type ThunkApi = {
  dispatch: ThunkDispatch<RootState, undefined, Action<string>>;
  getState: () => RootState;
};

export type RootState = ReturnType<typeof rootReducer>;
export type PathValidation = (state: RootState) => true | string;

export default store;

const persistor = persistStore(store);

export { persistor };
