import { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';

import { appRoutes } from '../appRoutes';

const TermsDownloadPage = lazy(() => import('../../pages/Terms/DownloadTermsPage'));

export const TermsDownloadRoutes = () => (
  <Routes>
    {/** React Router V6 no longer supports optional parameters, using separate routes with a single component is advised */}
    <Route path={appRoutes.terms.download.shortCode} element={<TermsDownloadPage />} />
    <Route path={appRoutes.terms.download.organisationCountryId} element={<TermsDownloadPage />} />
    <Route path={appRoutes.terms.download.version} element={<TermsDownloadPage />} />
  </Routes>
);
