var __create = Object.create;
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __getProtoOf = Object.getPrototypeOf;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toESM = (mod, isNodeMode, target) => (target = mod != null ? __create(__getProtoOf(mod)) : {}, __copyProps(isNodeMode || !mod || !mod.__esModule ? __defProp(target, "default", { value: mod, enumerable: true }) : target, mod));
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);

// src/enhancedClients/ga.ts
var ga_exports = {};
__export(ga_exports, {
  axios: () => axios,
  dataLayerClient: () => dataLayerClient,
  healthMonitorClient: () => healthMonitorClient
});
module.exports = __toCommonJS(ga_exports);

// src/clients/ga.ts
var import_axios = __toESM(require("axios"));
var axios = import_axios.default.create({
  baseURL: "/google-analytics"
});
var dataLayerClient = {
  get(clientId, $config) {
    let url = "/api/data-layer";
    return axios.request(__spreadValues({
      url,
      method: "GET",
      params: {
        clientId: serializeQueryParam(clientId)
      }
    }, $config));
  },
  post(request, $config) {
    let url = "/api/data-layer";
    return axios.request(__spreadValues({
      url,
      method: "POST",
      data: request
    }, $config));
  }
};
var healthMonitorClient = {
  get($config) {
    let url = "/api/healthmonitor";
    return axios.request(__spreadValues({
      url,
      method: "GET"
    }, $config));
  }
};
function serializeQueryParam(obj) {
  if (obj === null || obj === void 0)
    return "";
  if (obj instanceof Date)
    return obj.toJSON();
  if (typeof obj !== "object" || Array.isArray(obj))
    return obj;
  return Object.keys(obj).reduce((a, b) => a.push(b + "=" + obj[b]) && a, []).join("&");
}
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
  axios,
  dataLayerClient,
  healthMonitorClient
});
