import { getRegFlow } from 'core';
import { appRoutes } from 'routing';
import { IdType } from '../../pages/IdDetails/common/components/models';
import { enableFileUpload } from '../../pages/IdDetails/common/useIdUpload';
import { CandidateState } from '../candidate';
import { PaymentStatus } from '../payment';
import { searchSelect } from '../searchSelect';
import { IdDetailsIol } from '../idDetails';
import { PathValidation } from '..';
import { CommonIdDetails } from '.';

const isIdValid = (
  idDetails: CommonIdDetails,
  canSetNotExpiry: boolean,
  lrwDate?: string,
  speakingDate?: string,
  idTypes?: IdType[],
  idTypeId?: number,
  isUploadRequired?: boolean,
  hasUploadedFiles?: boolean
): boolean => {
  const notValidIds = ['', ' ', undefined];
  if (notValidIds.includes(idDetails.idNumber)) return false;

  if (
    idTypes &&
    !idTypes?.some(
      (t) => ('idTypeId' in t && t.idTypeId === idTypeId) || ('id' in t && t.id === idTypeId)
    )
  )
    return false;

  if (isUploadRequired && !hasUploadedFiles) return false;

  if (!canSetNotExpiry && idDetails.notExpires) return false;

  if (idDetails.notExpires) return true;

  if (idDetails.idExpiry && lrwDate && speakingDate) {
    const idExpiry = idDetails.idExpiry.split('T')[0];
    return idExpiry >= lrwDate.split('T')[0] || idExpiry >= speakingDate.split('T')[0];
  }

  return false;
};

const candidateIdDetails = (candidate: CandidateState): CommonIdDetails => {
  const profile = candidate.forWhom === 'myself' ? candidate.profile : candidate.ttProfile;

  return {
    idNumber: profile?.idNumber,
    notExpires: profile?.notExpires,
    idExpiry: profile?.idExpiry,
  };
};

const iolIdDetails = (idDetails?: IdDetailsIol): CommonIdDetails => ({
  idNumber: idDetails?.idNumber,
  notExpires: idDetails?.notExpire,
  idExpiry: idDetails?.expiryDate,
});

export const validateReview: PathValidation = (state) => {
  const { candidate, payment, registration, auth, idDetails, bookTest } = state;
  const isIol = searchSelect.selectors.isIol();
  const isUploadRequired = isIol || enableFileUpload(idDetails.idTypes.data, candidate.idType);
  const hasUploadedFiles = idDetails.initIdFiles.length > 0;

  if (getRegFlow() === 'iol') {
    if (!registration.iolExam) return appRoutes.search.findTest;
    if (!candidate.profile?.genderId || candidate.profile?.genderId === -1)
      return appRoutes.journey.personalDetails;
    if (!candidate.answers.data?.isValid) {
      return candidate.nationalityId ? appRoutes.journey.ttProfile : appRoutes.journey.idDetails;
    }
  } else {
    if (!registration.inCentreExam) return appRoutes.search.findTest;
    if (!bookTest.selectedExam?.awardingBodySystem) return appRoutes.search.bookTest;
    if (!candidate.answers.data?.isValid) {
      return candidate.nationalityId ? appRoutes.journey.ttProfile : appRoutes.journey.idDetails;
    }
  }

  if ((!candidate.profile && !candidate.ttProfile) || !auth.isLoggedIn)
    return appRoutes.journey.personalDetails;

  const tt = candidate.forWhom === 'myself' ? candidate.profile : candidate.ttProfile;
  if (
    !isIol &&
    !isIdValid(
      candidateIdDetails(candidate),
      true,
      registration.inCentreExam?.examDate,
      registration.inCentreSpeakingExam?.date,
      idDetails.idTypes.data,
      candidate.idType ?? tt?.idTypeId,
      isUploadRequired,
      hasUploadedFiles
    )
  ) {
    return appRoutes.journey.idDetails;
  }

  if (
    isIol &&
    !isIdValid(
      iolIdDetails(candidate.iol.idDetailsIol),
      false,
      registration.iolExam?.lrwStartDateTimeUtc,
      registration.iolExam?.speakingStartDateTimeUtc,
      idDetails.idTypesIol.data,
      candidate.idType ?? candidate.profile?.idTypeId,
      isUploadRequired,
      hasUploadedFiles
    )
  ) {
    return appRoutes.journey.idDetails;
  }

  if (
    payment.paymentStatus === PaymentStatus.STARTED &&
    !payment.processing &&
    registration.registrationId.data
  ) {
    return appRoutes.journey.bookingComplete
      .replace(':registrationId', registration.registrationId.data?.toString())
      .replace(':type', 'online');
  }

  return true;
};
