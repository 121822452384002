import { useEffect } from 'react';
import { readSupportedLanguages, useSelectedLang } from 'ors-translations';

import { useDispatch } from 'core/hooks/useDispatch';
import { switchLanguage } from 'core';

export const useI18n = () => {
  const dispatch = useDispatch();
  const supportedLanguages = readSupportedLanguages();
  const startLang = useSelectedLang();

  useEffect(() => {
    switchLanguage(startLang, dispatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supportedLanguages]);
};
