import { ProductWithPriceResponse, TaxedFee } from 'ors-api/ors2';
import { ApiErrorsList, RevOrderAckForm } from 'ors-ui';

export enum PaymentStatus {
  IDLE = 0,
  STARTED = 1,
  FAILED = 2,
  SUCCESS = 3,
}

export enum PromoCodeState {
  UNUSED = 0,
  INVALID = 1,
  VALID = 2,
  UNKNOWN = 3,
}

interface IolSpecificState {
  paymentId?: string;
}

export interface PaymentState extends IolSpecificState {
  processing: boolean;
  error?: ApiErrorsList;
  promoCodeState: PromoCodeState;
  isOnline?: boolean;
  paymentStatus: PaymentStatus;
  discount?: ProductWithPriceResponse;
  taxedFee?: TaxedFee;
  redirectUrl?: string;
}

export interface ReviewForm extends RevOrderAckForm {
  terms: boolean;
  ai: string;
  modifyNote: boolean;
  haveAgent: string;
  agentName: string;
}

export type PaymentMode = 'online' | 'offline' | 'agent';
