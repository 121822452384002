import { localeFns } from 'ors-utils';

export const getFullDate = (date?: string, locale?: string) => {
  const timeZoneId = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return localeFns(locale, timeZoneId).localeDate(date, { weekday: 'long' });
};

export const getTimeSpan = (
  start?: string,
  end?: string,
  locale?: string,
  /** To be used for IOL tests only! */
  timeZoneId?: string
) => {
  return `${localeFns(locale, timeZoneId).localeTimeFromDate(start)} - ${localeFns(
    locale,
    timeZoneId
  ).localeTimeFromDate(end)}`;
};

export const getFullDateTime = (start?: string, end?: string, locale?: string) => {
  const timeZoneId = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return `${getFullDate(start, locale)}, ${getTimeSpan(start, end, locale, timeZoneId)}`;
};

export const getExamFullDateTime = (start?: string, end?: string, locale?: string) => {
  const timeZoneId = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return {
    fullDate: getFullDate(start, locale),
    timeSpan: getTimeSpan(start, end, locale, timeZoneId),
  };
};
