import { includesInUrl } from 'ors-utils';

export type RegFlow = 'ors' | 'ukvi' | 'iol' | undefined;

/**
 * Get registration flow based on URL
 */
export const getRegFlow = (): RegFlow => {
  switch (true) {
    case includesInUrl('/ors/'):
      return 'ors';
    case includesInUrl('/iol'):
      return 'iol';
    case includesInUrl('/ukvi'):
      return 'ukvi';
    default:
      return undefined;
  }
};
