import slice, { initialState } from './organisationCountrySlice';
import * as thunks from './thunks';
import * as selectors from './selectors';

export * from './models';
export * from './organisationCountry.service';

export const organisationCountry = {
  ...slice,
  initialState,
  thunks,
  selectors,
};
