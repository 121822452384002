import { ApiErrorDetailed } from '@britishcouncil/react-common';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GenderDto } from 'ors-api/mod';
import { ApiErrorsList } from 'ors-ui';

import { clearErrors } from '../_common/actions';
import { extraReducersIc } from './extrareducers.ic';
import { PersonalDetailsState } from './models';

const sliceName = 'personalDetails';

const initApiData = {
  isLoading: false,
};

export const initialState: PersonalDetailsState = {
  dependants: initApiData,
  parentDetails: initApiData,
  genders: [],
  allowEdit: false,
  loadingData: true,
  processing: false,
};

const slice = createSlice({
  name: sliceName,
  initialState: initialState,
  reducers: {
    setProcessing(state, action: PayloadAction<boolean>) {
      state.processing = action.payload;
    },
    setProcessError(state, action: PayloadAction<ApiErrorsList | undefined>) {
      state.processingErr = action.payload;
      state.processing = false;
    },
    clearDependants(state) {
      state.dependants = initApiData;
    },
    clearSensitivePersonalData(state) {
      state.dependants = initApiData;
      state.parentDetails = initApiData;
    },
    setAllowEdit(state, action: PayloadAction<boolean>) {
      state.allowEdit = action.payload;
    },
    setGenders(state, action: PayloadAction<GenderDto[]>) {
      state.genders = action.payload;
    },
    setMobileCountryIso(state, action: PayloadAction<string>) {
      state.mobileCountryIso = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(clearErrors, (state) => {
      state.processingErr = undefined;
    });

    //TODO: Maybe add a global event for that
    builder.addCase('auth/setLoadingProcessing', (state) => {
      state.processingErr = undefined;
    });

    extraReducersIc(builder);
  },
});

export default slice;
