import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosPromise } from 'axios';
import { extractApiErrors, ApiErrorsList } from 'ors-ui';

import { logger } from 'ors-utils';

type Fn<A, R> = (arg: A, $config?: any) => AxiosPromise<R>;

export const asyncThunk = <A, R>(prefix: string, fn: Fn<A, R>) =>
  createAsyncThunk<
    Awaited<ReturnType<typeof fn>>['data'],
    Parameters<typeof fn>[0],
    { rejectValue: ApiErrorsList }
  >(prefix, async (arg, { rejectWithValue }) => {
    try {
      const response = await fn(arg);
      return response.data;
    } catch (error) {
      logger.logError(error as Error);
      const err = extractApiErrors(error);
      return rejectWithValue(err);
    }
  });
