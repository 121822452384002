import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { BcAlert, PageContainer } from '@britishcouncil/react-solas-ors3';
import { useSelector } from 'core';

export const ApplyBoardVoucherWarning = () => {
  const { t } = useTranslation();

  const affiliationNoDiscountReason = useSelector(
    (state) => state.bookTest.exams?.affiliationNoDiscountReason
  );

  enum Reasons {
    invalidState = 'VoucherInvalidState',
    notSupported = 'ExamTypeNotSupported',
    countryNotMatched = 'VoucherCountryNotMatched',
    notFound = 'VoucherNotFound',
    reserved = 'VoucherReserved',
    used = 'VoucherUsed',
  }

  const getMessage = (reason: string) => {
    switch (reason) {
      case Reasons.used:
        return t('APPB2C.common.bookTest.noDiscount.msg.used');
      case Reasons.reserved:
        return t('APPB2C.common.bookTest.noDiscount.msg.reserved');
      case Reasons.countryNotMatched:
        return t('APPB2C.common.bookTest.noDiscount.msg.countryNotMatched');
      case Reasons.notSupported:
        return t('APPB2C.common.bookTest.noDiscount.msg.notSupported');
      case Reasons.invalidState:
      case Reasons.notFound:
      default:
        return t('APPB2C.common.bookTest.noDiscount.msg.notFound');
    }
  };

  return affiliationNoDiscountReason ? (
    <NoDiscountContainer>
      <PageContainer transparent>
        <BcAlert title={t('APPB2C.common.bookTest.noDiscount.title')}>
          <p>{getMessage(affiliationNoDiscountReason)}</p>
        </BcAlert>
      </PageContainer>
    </NoDiscountContainer>
  ) : null;
};

const NoDiscountContainer = styled.div`
  div[data-testid='page-container'] {
    padding: 0;
  }
`;
