import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { IdTypeDto, productsClient } from 'ors-api/iol';
import {
  AwardingBodySystem,
  awardingBodySystemsClient,
  OrganisationCountryAvailableIdDto,
} from 'ors-api/mod';
import { logger } from 'ors-utils';
import { extractApiErrors } from 'ors-ui';

import { idDetails } from 'store/idDetails';
import { searchSelect } from 'store/searchSelect';
import { api } from '../shared/api';
import { BaseTranslatedQueryParams } from 'store/shared';

type GetAvailableIdsQuery = {
  awardingBodySystem?: AwardingBodySystem;
  organisationCountryId?: number;
  productId?: string;
  fetch?: boolean;
} & BaseTranslatedQueryParams;

const idDetailsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAvailableIds: builder.query<
      OrganisationCountryAvailableIdDto[] | IdTypeDto[],
      GetAvailableIdsQuery
    >({
      query: ({
        awardingBodySystem,
        organisationCountryId,
        productFamilyId,
        productId,
        fetch = true,
      }) => {
        if (!fetch) {
          return Promise.resolve([] as any);
        }

        if (awardingBodySystem && organisationCountryId && productFamilyId) {
          return awardingBodySystemsClient.getAvailableIds(
            awardingBodySystem,
            organisationCountryId,
            productFamilyId
          );
        }

        if (productId) {
          return productsClient.get(productId);
        }

        return Promise.resolve([] as any);
      },
    }),
  }),
});

const { useGetAvailableIdsQuery: useGetAvailableIdsQueryRaw } = idDetailsApi;

export const useGetAvailableIdsQuery = (query: GetAvailableIdsQuery) => {
  const dispatch = useDispatch();
  const isIol = searchSelect.selectors.isIol();

  const rawAvailableIds = useGetAvailableIdsQueryRaw(query);
  const { data, isFetching, error } = rawAvailableIds;

  useEffect(() => {
    if (isFetching) {
      isIol
        ? dispatch(idDetails.actions.setIdTypesIol({ isLoading: true }))
        : dispatch(idDetails.actions.setIdTypes({ isLoading: true }));
      return;
    }

    if (error) {
      logger.logError(error as Error);
      isIol
        ? dispatch(
            idDetails.actions.setIdTypesIol({
              isLoading: false,
              error: extractApiErrors(error),
            })
          )
        : dispatch(
            idDetails.actions.setIdTypes({ isLoading: false, error: extractApiErrors(error) })
          );
      return;
    }

    isIol
      ? dispatch(idDetails.actions.setIdTypesIol({ isLoading: false, data: data as IdTypeDto[] }))
      : dispatch(
          idDetails.actions.setIdTypes({
            isLoading: false,
            data: data as OrganisationCountryAvailableIdDto[],
          })
        );
  }, [data, isFetching, error]);

  return rawAvailableIds;
};
