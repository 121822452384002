import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import styled from '@emotion/styled';
import { useSelector } from 'core';
import { PageHeading as GenericPageHeading } from 'ors-ui';
import { Breadcrumbs } from './layout/';

interface Props {
  heading: string;
  step?: number;
  steps?: number;
  continued?: boolean;
}

export const PageHeading: FC<Props> = ({ heading, steps = 5, step, continued }) => {
  const { t } = useTranslation();

  const isRebooking = useSelector((s) => s.registration.rebooking);
  const stepsText =
    step && !isRebooking
      ? continued
        ? t('APPB2C.common.general.stepsContinued', { steps, step })
        : t('APPB2C.common.general.steps', { steps, step })
      : undefined;

  return (
    <PageHeadingSection heading={heading} stepsText={stepsText}>
      <Breadcrumbs />
    </PageHeadingSection>
  );
};

const PageHeadingSection = styled(GenericPageHeading)`
  background: red;
`;
