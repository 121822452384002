import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CountryDto } from 'ors-api/mod';
import { ApiErrorsList } from 'ors-ui';

import { Status, StorageDataSlice } from './types';

const sliceName = 'storageData';

export const dataStorageKeys = {
  countriesMod: 'COUNTRIES_MOD',
  countriesIol: 'COUNTRIES_IOL',
  examCountriesMod: 'EXAM_COUNTRIES_MOD',
};

const getInitState = () =>
  Object.keys(dataStorageKeys).reduce(
    (acc, key) => ({
      ...acc,
      [key]: {
        storageKey: dataStorageKeys[key as keyof typeof dataStorageKeys],
        status: Status.Idle,
        error: undefined,
      },
    }),
    {} as StorageDataSlice
  );

export const initialState: StorageDataSlice = getInitState();

type Payload = {
  status: Status;
  error?: ApiErrorsList;
};

type CountryPayload = Payload & {
  data?: CountryDto[];
};

export const slice = createSlice({
  name: sliceName,
  initialState: initialState,
  reducers: {
    countriesStatus(state, action: PayloadAction<CountryPayload>) {
      state.countriesMod.data = action.payload.data;
      state.countriesMod.status = action.payload.status;
      state.countriesMod.error = action.payload.error;
    },
  },
});

export default slice;
