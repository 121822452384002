import { Trans, useTranslation } from 'react-i18next';
import { FormFields } from '@britishcouncil/react-forms';
import { BcCheckbox } from '@britishcouncil/react-solas-ors3';
import styled from '@emotion/styled';
import { FC } from 'react';

import { PersonalDetailsForm } from 'store/personalDetails';

interface Props {
  fields: FormFields<PersonalDetailsForm>;
}

export const SmsConsent: FC<Props> = ({ fields }) => {
  const { t } = useTranslation();

  return (
    <BcCheckbox
      style={{ top: '30%', zIndex: 1 }}
      checked={fields.smsNotificationsAllowed.value}
      handleChange={fields.smsNotificationsAllowed.onChange}
      id="sms-notifications-consent"
    >
      {t('APPB2C.cj.personalDetails.forms.contact.smsConsent')}
      <Note>
        <Trans
          t={t}
          i18nKey="APPB2C.cj.personalDetails.forms.contact.smsNote"
          components={{ bold: <strong /> }}
        />
      </Note>
    </BcCheckbox>
  );
};

const Note = styled.div`
  margin-top: 10px;
`;
