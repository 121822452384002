import { AnchorHTMLAttributes, FC } from 'react';
import styled from '@emotion/styled';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { getRegFlow } from 'core';

interface Props {
  to: string;
}

// Move to review components as "change link"
export const Anchor: FC<Props & AnchorHTMLAttributes<HTMLAnchorElement>> = ({
  to,
  children,
  className,
  ...props
}) => {
  const flow = getRegFlow();
  return (
    <StyledLink to={`/${flow}/${to}`} className={cn(className, 'btn-link', 'link')} {...props}>
      {children}
    </StyledLink>
  );
};

const StyledLink = styled(Link)`
  display: inline-block;
  padding: 1px 6px;
  border-width: 2px;
`;
