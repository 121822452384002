import { ApiError } from '@britishcouncil/react-common';
import {
  IPayOnlineFromRegistrationServiceQuery,
  ProductType,
  registrationClient,
} from 'ors-api/iol';
import { extractApiErrors } from 'ors-ui';

import slice from './paymentSlice';
import { AppThunk } from '..';
import { logger } from 'ors-utils';
import { changeLastPageInHistory } from './utils';
import { registerIol } from '../registration/thunks.iol';
import { navigateToConfirmPage } from './utils';

export const payOnlineIol =
  (regId?: number, tranId?: number, aiConsent?: boolean): AppThunk =>
  async (dispatch, getState) => {
    dispatch(slice.actions.startProcessing({ isOnline: true }));
    !regId && (await dispatch(registerIol(aiConsent)));

    const registrationIdentifiersIol = getState().registration.registrationIdentifiersIol.data;

    let registrationId = registrationIdentifiersIol?.registrationId ?? regId;
    let transactionId = registrationIdentifiersIol?.transactionId ?? tranId;

    if (registrationId && transactionId) {
      try {
        const {
          data: { paymentId, paymentPageUrl },
        } = await registrationClient.payOnline(registrationId, transactionId, {
          productType: ProductType.ExamRegistration,
          returnUrlVirtualPath: 'iol/online-payment/finish',
        } as IPayOnlineFromRegistrationServiceQuery);

        dispatch(slice.actions.setPaymentId(paymentId));

        if (paymentPageUrl) {
          /* Replaces /review page with /booking-complete page in history
          So that when going back from NPP won't break User Experience */
          changeLastPageInHistory(`/iol/booking-complete/online/${registrationId}/${paymentId}`);
          return (window.location.href = paymentPageUrl);
        }

        dispatch(
          slice.actions.processingFailed({
            status: ApiError.Unknown,
          })
        );
      } catch (error: any) {
        const err = extractApiErrors(error);
        dispatch(slice.actions.processingFailed(err));
        logger.logError(error);
      }
      return;
    }
    dispatch(slice.actions.processingFailed({ status: ApiError.Unknown }));
  };

export const finishOnlinePaymentIol =
  (paymentId?: string): AppThunk =>
  async (dispatch, getState) => {
    const registrationIdentifiersIol = getState().registration.registrationIdentifiersIol.data;

    if (registrationIdentifiersIol?.registrationId && paymentId) {
      dispatch(slice.actions.processingDone());
      dispatch(
        navigateToConfirmPage('online', registrationIdentifiersIol.registrationId, paymentId)
      );
    } else {
      dispatch(slice.actions.processingFailed({ status: ApiError.Unknown }));
    }
  };
