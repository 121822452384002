import { useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { LinkButton } from '@britishcouncil/react-solas-ors3';
import { Trans, useTranslation } from 'react-i18next';
import { ApiErrorsAlert, ApiErrorsList } from 'ors-ui';

import { useSelector } from 'core';
import { useAuthSlice } from 'store/authSlice';
import { converters } from 'common';

export const Errors = ({ marketingError }: { marketingError: ApiErrorsList }) => {
  const { t } = useTranslation();
  const { handleStartLogIn } = useAuthSlice();

  const { processingErr: personalDetailsError } = useSelector((state) => state.personalDetails);
  const { processingErr: reservationError } = useSelector((state) => state.reservation);
  const {
    countriesMod: { error: countriesError },
  } = useSelector((s) => s.storageData);

  const [showEmailInUse, setShowEmailInUse] = useState(false);

  const errorStatus =
    personalDetailsError?.status ?? reservationError?.status ?? countriesError?.status;

  const errors = useMemo(() => {
    setShowEmailInUse(false);

    return converters.tErrorMessagePersonalDetails(
      [marketingError, personalDetailsError, reservationError, countriesError],
      setShowEmailInUse
    );
  }, [personalDetailsError?.errors, reservationError?.errors, t]);

  return (
    <ApiErrorsAlert
      apiError={errorStatus}
      errors={errors}
      extended
      style={{ marginBottom: '25px' }}
    >
      {showEmailInUse ? (
        <p>
          <Trans t={t} i18nKey="APPB2C.cj.personalDetails.forms.contact.emailInUse">
            <StyledLinkButton
              className="link"
              data-testid="warning-modal-login-link"
              onClick={() => handleStartLogIn()}
            >
              Log in
            </StyledLinkButton>
          </Trans>
        </p>
      ) : null}
    </ApiErrorsAlert>
  );
};

const StyledLinkButton = styled(LinkButton)`
  padding: 0;
`;
