import { createAsyncThunk } from '@reduxjs/toolkit';
import { CandidateB2C, candidateClient, termsAndConditionsTypeClient } from 'ors-api/ors2';
import { extractApiErrors, ApiErrorsList } from 'ors-ui';

import { logger } from 'ors-utils';
import { CreateAccountRequest } from '../../candidate/types';
import { RootState } from '../..';

export const getCandidateChildren = createAsyncThunk(
  'api-ors2/getCandidateChildren',
  async (thunkAPI) => {
    const response = await candidateClient.getCandidateChildren();
    return response.data;
  }
);

export const getTermsAndConditionsNewestVersionNumber = createAsyncThunk<
  number,
  string,
  { state: RootState; rejectValue: ApiErrorsList }
>('api-ors2/getTermsAndConditionsNewestVersionNumber', async (shortCode, { rejectWithValue }) => {
  try {
    const response = await termsAndConditionsTypeClient.getTermsAndConditionsNewestVersionNumber(
      shortCode,
      'IELTS',
      'B2C',
      null
    );
    return response.data;
  } catch (error: any) {
    logger.logError(error);
    const err = extractApiErrors(error);
    return rejectWithValue(err);
  }
});

export const createCandidateAccount = createAsyncThunk<
  CandidateB2C,
  CreateAccountRequest,
  { state: RootState; rejectValue: ApiErrorsList }
>('api-ors2/createCandidateAccount', async (req, { rejectWithValue }) => {
  try {
    const response = await candidateClient.saveB2CCandidate(req as CandidateB2C);
    return response.data;
  } catch (error: any) {
    logger.logError(error);
    const err = extractApiErrors(error);
    return rejectWithValue(err);
  }
});
