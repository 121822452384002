import { FC, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import { Button } from '@britishcouncil/react-solas';
import { ApiError } from '@britishcouncil/react-common';
import { BcAlert, PageContainer } from '@britishcouncil/react-solas-ors3';
import {
  AvailableDistrictExamPageDto,
  AvailableDistrictExamDto,
  AccessibilityDto,
} from 'ors-api/mod';
import { ApiErrorsAlert, LoadingCard } from 'ors-ui';
import { groupVenueExams } from 'ors-utils';

import { bookTest } from 'store/bookTest';
import { appRoutes, routeWithFlow } from 'routing';
import { VenueSearchResults } from './components';
import { useSelector } from 'core';

const ClearFiltersButton = styled(Button)`
  margin-top: 15px;
`;

interface Props {
  results?: AvailableDistrictExamPageDto;
  isDefaultFilter: boolean;
  loadError?: ApiError;
  loading: boolean;
  showVenues: boolean;
  loadingExamDetails?: number;
  handleBook: (exam: AvailableDistrictExamDto, checkExamBefore?: boolean) => void;
  allAccessibilities?: AccessibilityDto[];
}

export const ResultsList: FC<Props> = ({
  results,
  loading,
  handleBook,
  showVenues,
  loadError,
  isDefaultFilter,
  loadingExamDetails,
  allAccessibilities,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { processingErr, showExamTakenModal } = useSelector((s) => s.reservation);

  const groupedResults = useMemo(() => {
    return groupVenueExams(results?.results, !showVenues);
  }, [results, showVenues]);

  const showLoadingExamDetails = !processingErr && !showExamTakenModal;

  if (loading) {
    return (
      <PageContainer>
        <LoadingCard type="white">{t('APPB2C.common.bookTest.results.searchText')}</LoadingCard>
      </PageContainer>
    );
  }

  if (!results || !results.results || results.results.length === 0) {
    if (loadError === ApiError.NoNetwork || loadError === ApiError.ServerError) {
      return <ApiErrorsAlert extended style={{ marginTop: '2em' }} apiError={loadError} />;
    }

    return (
      <PageContainer>
        <BcAlert
          data-testid="no-results-alert"
          title={t('APPB2C.common.bookTest.results.noResultsTitle')}
          type="warning"
          style={{ marginTop: '2em' }}
        >
          {!isDefaultFilter ? (
            <>
              <div>{t('APPB2C.common.bookTest.results.noResultsText')}.</div>
              <ClearFiltersButton
                intent="primary"
                onClick={() => dispatch(bookTest.thunks.clearFilter())}
              >
                {t('APPB2C.common.bookTest.results.clearFilters')}
              </ClearFiltersButton>
            </>
          ) : (
            <Trans t={t} i18nKey="APPB2C.common.bookTest.results.noResultsGoBack">
              We can't find any tests, please
              <Link to={routeWithFlow(appRoutes.search.findTest)}>go back</Link>
              and try changing your location or dates
            </Trans>
          )}
        </BcAlert>
      </PageContainer>
    );
  }

  return (
    <VenueResultsContainer data-testid="results-container">
      {groupedResults.map((group, index) => (
        <VenueSearchResults
          key={index}
          results={group}
          showVenues={showVenues}
          handleBook={handleBook}
          loadingExamDetails={showLoadingExamDetails ? loadingExamDetails : 0}
          allAccessibilities={allAccessibilities}
        />
      ))}
    </VenueResultsContainer>
  );
};

const VenueResultsContainer = styled.div`
  div[data-testid='page-container'] {
    padding: 32px;
  }
`;
