import { DateTime } from 'luxon';
import {
  ApiResponseOfCentreConfigurationDto,
  CentrePaymentMethodDto,
  centresClient,
  ExamFormat,
} from 'ors-api/mod';
import { preparePaymentMethods, logger } from 'ors-utils';
import { extractApiErrors } from 'ors-ui';

import { api } from '../shared/api';
import slice from './paymentSlice';
import { BaseTranslatedQueryParams } from 'store/shared';

const paymentApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getPaymentMethods: builder.query<
      CentrePaymentMethodDto[],
      {
        centreId?: number;
        registrationId?: number;
        examDate?: string;
        examFormat?: ExamFormat;
      } & BaseTranslatedQueryParams
    >({
      query: async ({ centreId, registrationId, examDate, examFormat, productFamilyId }) => {
        if (centreId && productFamilyId) {
          const response = await centresClient.getCentrePaymentMethods(
            centreId,
            productFamilyId,
            registrationId
          );

          const curTime = DateTime.local().toISO();
          const preparedData = preparePaymentMethods(response.data, curTime, examDate, examFormat);

          return { ...response, data: preparedData };
        }

        return Promise.resolve([] as any);
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {
          dispatch(slice.actions.processingFailed(extractApiErrors(error)));
          logger.logError(error as Error);
        }
      },
    }),
    getCentreConfiguration: builder.query<
      ApiResponseOfCentreConfigurationDto,
      { locale: string; centreId?: number }
    >({
      query: ({ centreId }) => {
        if (!centreId) return Promise.resolve({} as any);

        return centresClient.getCentreConfiguration(centreId);
      },
    }),
  }),
});

export const { useGetPaymentMethodsQuery, useGetCentreConfigurationQuery } = paymentApi;
