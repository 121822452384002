import { ButtonHTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@britishcouncil/react-solas';

import { useSelector } from 'core';
import styled from '@emotion/styled';

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  size?: 'xs' | 'sm' | 'lg';
  dataTestId?: string;
  loading?: boolean;
}

export const BookButton = ({
  size = 'sm',
  dataTestId = 'btn-book',
  disabled,
  loading,
  onClick,
  'aria-label': ariaLabel,
}: Props) => {
  const { t } = useTranslation();
  const inChangeMode = useSelector((s) => s.registration.changeMode);

  return (
    <StyledButton
      intent="primary"
      size={size}
      data-testid={dataTestId}
      aria-label={ariaLabel}
      disabled={disabled}
      loading={loading}
      onClick={onClick}
    >
      {t('APPB2C.common.bookTest.results.book')}
      {inChangeMode && ' ' + t('APPB2C.common.bookTest.results.review')}
    </StyledButton>
  );
};

const StyledButton = styled(Button)`
  && {
    margin: 8px 0px 0px 0px;
  }
`;
