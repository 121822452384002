import { AppThunk } from '../..';
import { appRoutes, navigateTo } from 'routing';
import { getLrwExamDates } from '../../api.iol';

/**
 * Thunks related to IOL flow.
 */

export const loadData = (): AppThunk => async (dispatch, getState) => {
  const { ieltsProduct } = getState().searchSelect;
  ieltsProduct && dispatch(getLrwExamDates(ieltsProduct));
};

export const proceed = (): AppThunk => async (dispatch, getState) => {
  navigateTo(appRoutes.search.testDetails);
};
