import { userFriendlyTime } from '@britishcouncil/react-solas-ors3';
import { createSelector } from '@reduxjs/toolkit';
import { DateTime } from 'luxon';
import {
  B2CCreateRegistrationRequest,
  ExamMode,
  MarketingQuestionAnswer,
  ProductPricingRequest,
  WhoPaysType,
} from 'ors-api/ors2';
import { B2CCreateRegistrationRequest as B2CCreateRegistrationRequestUkvi } from 'ors-api/ukvi';
import { ProductFamilies } from 'ors-api/mod';
import { ChildAfterTest } from 'ors-ui';
import { getProductGroupSubSystemId } from 'ors-utils';

import { RootState } from '../';
import { converters } from 'common';
import { isIol } from '../searchSelect/selectors';

export const getFirstExamDate = createSelector(
  (state: RootState) => state.registration,
  (s) => findExamDate('min', s.inCentreExam?.examDate, s.inCentreExam?.examDate)
);

export const getLastExamDate = createSelector(
  (state: RootState) => state.registration,
  isIol,
  (reg, isIol) => {
    const lastExam = isIol
      ? findExamDate('max', reg.iolExam?.lrwStartDateTimeUtc, reg.iolExam?.speakingStartDateTimeUtc)
      : findExamDate('max', reg.inCentreExam?.examDate, reg.inCentreExam?.examDate);

    return lastExam.set({ hour: 0, minute: 0 });
  }
);

export const getProductPricingRequest = createSelector(
  (state: RootState) => state,
  (s) => {
    const { inCentreExam } = s.registration;
    const { ieltsModule, ukvi } = s.searchSelect;
    const { organisationDetails } = s.organisationCountry;
    if (!inCentreExam) {
      return null;
    }

    const productGroupSubSystemId = getProductGroupSubSystemId(
      ProductFamilies.IELTS,
      organisationDetails?.data?.organisationId,
      ukvi?.examOptionType
    );

    return {
      centreId: inCentreExam.centreId,
      displayVenue: inCentreExam.displayVenues,
      districtExamId: inCentreExam.plannedExamId,
      examDateUtc: inCentreExam.examDate,
      examFormat: inCentreExam.examFormat,
      organisationCountryId: inCentreExam.organisationCountryId,
      productGroupSubSystemId: productGroupSubSystemId,
      quantity: 1,
      salesChannel: 'SALESCHANNEL~B2C',
      timeTableSession: converters.fromTimeTableSessionCode(inCentreExam.timeTableSession).toEnum(),
      venueId: inCentreExam.venueId,
      productId: 0,
      ieltsModule: ieltsModule,
      examMode: ExamMode.Full,
    } as ProductPricingRequest;
  }
);

export const getRegistrationRequest = createSelector(
  (state: RootState) => state,
  (s) => {
    const {
      inCentreSpeakingExam,
      inCentreExam,
      termsShortCodeIelts,
      newestTermsVersionNumberIelts,
    } = s.registration;
    const { childAfterTest, pickups, answers, nationalityId: nationality } = s.candidate;
    const { needSpecialReqs, otherSpecialReqs, specialReqs } = s.searchSelect;
    const { reservationGuid } = s.reservation;
    const { discount } = s.payment;
    const { invitationContext } = s.invitation;

    const answersWithNationality: MarketingQuestionAnswer[] =
      answers.data?.answers?.map((ans) =>
        ans.marketingQuestionShortCode === 'CountryOfNationality'
          ? {
              ...ans,
              marketingQuestionOptionId: nationality
                ? Number(nationality)
                : ans.marketingQuestionOptionId,
            }
          : ans
      ) ?? [];

    const selectedSpecialReqIds = specialReqs?.filter((r) => r.checked).map((r) => r.id);

    return {
      //payerModel: ... - it should be merged in the thunk, as it's value partially depends on the form
      speakingDetails: {
        isSpeakingReserved: true,
        speakingVenueId: inCentreSpeakingExam?.venueId,
        speakingStartTime: userFriendlyTime(inCentreSpeakingExam?.startTime),
        speakingEndTime: userFriendlyTime(inCentreSpeakingExam?.endTime),
        speakingFormat: inCentreSpeakingExam?.speakingFormat!,
        speakingFormatName: converters
          .fromSpeakingFormat(inCentreSpeakingExam?.speakingFormat)
          .toName(),
        speakingExamDate: converters
          .fromUncertainUtcDate(inCentreSpeakingExam?.date)
          .toProperUtcDate(),
      },
      registratonReservationGuid: reservationGuid,
      omitSendingCommunication: false,
      specialArrangementIds: needSpecialReqs ? selectedSpecialReqIds : undefined,
      answers: answersWithNationality,
      // Fill when implementing receiving organisations
      receivingOrganisations: undefined,
      // TODO: pre-payment screen?
      resultDeliveryTypes: undefined,
      pickUps: pickups?.filter((c) => !!c.relationship),
      guardianNeededForChild: [
        ChildAfterTest.IWillPickHim,
        ChildAfterTest.SomeoneElseWillPick,
      ].some((e) => e === childAfterTest),
      canAgentProvideChildConsent: undefined,
      specialArrangementsDetails: otherSpecialReqs,
      whoPays: invitationContext.data?.whoPays ?? WhoPaysType.AgentOrTestTaker,
      promoCode: discount?.codeWord,
      expectedExamPrice: {
        totalFee: inCentreExam?.fee,
        currencyCode: inCentreExam?.currencyCode,
      },
      acceptedTermsAndConditionsVersion: newestTermsVersionNumberIelts.data,
      termsAndConditionsShortCode: termsShortCodeIelts,
    } as B2CCreateRegistrationRequest;
  }
);

export const getRegistrationRequestUkvi = createSelector(
  (state: RootState) => state,
  (s) => {
    const orsRequest = getRegistrationRequest(s);

    return {
      ...orsRequest,
      speakingDetails: { ...orsRequest.speakingDetails, isAutoBooking: false },
      acceptedTermsAndConditionsVersion: s.registration.newestTermsVersionNumberUkvi.data,
      termsAndConditionsShortCode: s.registration.termsShortCodeUkvi,
      awardingBodySystem: s.bookTest.selectedExam?.awardingBodySystem,
    } as B2CCreateRegistrationRequestUkvi;
  }
);

function findExamDate(method: 'min' | 'max', lrwDate?: string, speakingDate?: string) {
  const date1 = lrwDate ? DateTime.fromISO(lrwDate) : DateTime.local();
  const date2 = speakingDate ? DateTime.fromISO(speakingDate) : DateTime.local();

  return DateTime[method](date1, date2)!;
}

export const showModifyNote = createSelector(
  (state: RootState) => state,
  (s) => {
    const shouldConfirmSpeakingSelection =
      s.organisationCountry.orgCountryDetails.data?.shouldConfirmSpeakingSelection;
    const modifyNoteAccepted = s.registration.organisationSpecific?.modifyNoteAccepted;

    return shouldConfirmSpeakingSelection && !modifyNoteAccepted;
  }
);
