import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { getLrwExamDates, isAnyAvailable } from '../../api.iol';
import { apiExtraReducer } from '../../_common/apiExtraReducer';
import { SearchSelectState } from '../models';

export const extraReducersIol = (builder: ActionReducerMapBuilder<SearchSelectState>) => {
  /** Load IELTS Online exam dates */
  apiExtraReducer(builder, getLrwExamDates, 'availableIolDates', { dataWhenError: [] });

  /** Check IELTS Online availability */
  apiExtraReducer(builder, isAnyAvailable, 'iolAvailable', { dataWhenError: false });
};
