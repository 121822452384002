import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { extraReducersIc } from './extraReducers.ic';
import { InvitationState, SetInvitationContext } from './models';

const sliceName = 'invitation';

const initialState: InvitationState = {
  invitationContext: { isLoading: false },
  deeplinkToken: null,
};

const slice = createSlice({
  name: sliceName,
  initialState: initialState,
  reducers: {
    setDeeplinkToken(state, action: PayloadAction<string>) {
      state.deeplinkToken = action.payload;
    },
    setInvitationContext(state, action: PayloadAction<SetInvitationContext | undefined>) {
      state.invitationContext.data = action.payload?.invitationContext;
    },
  },
  extraReducers: (builder) => {
    extraReducersIc(builder);
  },
});

export { initialState };
export default slice;
