import { FC, useMemo } from 'react';
import DOMPurify from 'dompurify';
import styled from '@emotion/styled';
import { BcAlert } from '@britishcouncil/react-solas-ors3';

import { getProductFamilyId, useLocale, useSelector } from 'core';
import { useGetOrgCountryConfigurationQuery } from 'store/organisationCountry';
import { NarrowQuote } from 'components';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  extended?: boolean;
}

export const CjNotes: FC<Props> = ({ extended, ...props }) => {
  const organisationCountryId = useSelector(
    (s) => s.registration.inCentreExam?.organisationCountryId
  );
  const { data: orgCountryConfig } = useGetOrgCountryConfigurationQuery({
    organisationCountryId,
    productFamilyId: getProductFamilyId(),
    locale: useLocale().locale,
  });
  const message = orgCountryConfig?.value?.b2CCustomerJourneyNotes;

  const cjNotes = useMemo(
    () => ({ __html: DOMPurify.sanitize(message || '', { ADD_ATTR: ['target'] }) }),
    [message]
  );

  if (!message) {
    return null;
  }

  return (
    <NarrowQuote {...props}>
      <StyledAlert extended={extended} type="warning" dangerouslySetInnerHTML={cjNotes} />
    </NarrowQuote>
  );
};

const StyledAlert = styled(BcAlert)`
  .contents {
    p {
      font-size: 0.9em;
      margin-bottom: 8px;
    }
  }
  && {
    padding-top: 20px;
    padding-bottom: 20px;
    margin-bottom: 20px;
  }
  .icon {
    display: none;
  }

  ul li {
    list-style: initial;
    margin-left: 20px;

    ul li {
      list-style: circle;
    }
  }
  ol li {
    list-style: number;
    margin-left: 20px;
  }
`;
