import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { AgentCandidateInvitationContextState } from 'ors-api/mod';

import { InvitationState } from '.';
import { getInvitationContextState } from './api';
import { clearInvitation } from '../_common/actions';

export const extraReducersIc = (builder: ActionReducerMapBuilder<InvitationState>) => {
  builder.addCase(clearInvitation, (state) => {
    state.invitationContext = { isLoading: false };
    state.deeplinkToken = null;
  });

  builder
    .addCase(getInvitationContextState.pending, (state) => {
      state.invitationContext = { isLoading: true, error: undefined };
    })
    .addCase(getInvitationContextState.fulfilled, (state, action) => {
      state.invitationContext = { isLoading: false, data: action.payload };
      if (action.payload.state !== AgentCandidateInvitationContextState.Active) {
        state.deeplinkToken = null;
      }
    })
    .addCase(getInvitationContextState.rejected, (state, action) => {
      state.invitationContext = { isLoading: false, error: action.payload };
    });
};
