import { AwardingBodySystem } from 'ors-api/ors2';
import { AppThunk } from '../..';
import { candidate } from '../../candidate';
import { getCandidateChildren } from '../api/api.ors';
import { LoadData } from '../models';
import slice from '../personalDetailsSlice';

/* Load data for ORS and UKVI flows. */
export const loadOrsData =
  ({ centreId, isLoggedIn }: LoadData<AwardingBodySystem>): AppThunk =>
  async (dispatch, getState) => {
    if (isLoggedIn) {
      await dispatch(candidate.thunks.loadProfile());
      const { profile } = getState().candidate;
      profile?.id && !profile?.needToSupplyMissingDetails && dispatch(getCandidateChildren());
    } else {
      dispatch(slice.actions.clearDependants());
    }
  };
