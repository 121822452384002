import { extractApiError } from '@britishcouncil/react-common';
import { ActionReducerMapBuilder } from '@reduxjs/toolkit';

import { loadDatesBy, loadLocationsList } from '../../api';
import { SearchSelectState } from '../models';
import { onLocationsFullfilled } from './helpers';

export const extraReducersOrs = (builder: ActionReducerMapBuilder<SearchSelectState>) => {
  /** Load locations within selected countries */
  builder
    .addCase(loadLocationsList.pending, (state) => {
      state.locationsList = { isLoading: true, error: undefined };
    })
    .addCase(loadLocationsList.fulfilled, (state, action) => {
      onLocationsFullfilled(state, action.payload);
    })
    .addCase(loadLocationsList.rejected, (state, action) => {
      state.locationsList = {
        isLoading: false,
        data: [],
        error: extractApiError(action.payload),
      };
    })
    .addCase(loadDatesBy.pending, (state) => {
      state.availableDates = { isLoading: true, error: undefined };
    })
    .addCase(loadDatesBy.fulfilled, (state, action) => {
      state.availableDates = {
        data: action.payload,
        isLoading: false,
      };
    })
    .addCase(loadDatesBy.rejected, (state, action) => {
      state.availableDates = {
        isLoading: false,
        data: undefined,
        error: extractApiError(action.payload),
      };
    });
};
