import { ApiError } from '@britishcouncil/react-common';
import { paymentClient } from 'ors-api/ors2';
import { paymentClient as paymentClientUkvi } from 'ors-api/ukvi';
import { registrationClient } from 'ors-api/mod';
import { OidcContext } from '@britishcouncil/react-oidc-auth';
import { storeOrganisationAliasInLS, logger } from 'ors-utils';
import { extractApiErrors } from 'ors-ui';

import { getRegFlow, useLocale } from 'core';
import { AppThunk } from '..';
import { persistAuth } from '../authSlice';
import { searchSelect } from '../searchSelect';
import { register } from '../registration/thunks';
import { ReviewForm } from './models';
import { changeLastPageInHistory, navigateToConfirmPage } from './utils';
import slice from './paymentSlice';
import { useDispatch } from 'core/hooks/useDispatch';

/** Book test without payment */
export const bookTest =
  (values: ReviewForm): AppThunk =>
  async (dispatch, getState) => {
    dispatch(slice.actions.startProcessing({ isOnline: false }));
    await dispatch(register(values));

    const registrationId = getState().registration.registrationId.data;
    if (registrationId) {
      dispatch(slice.actions.processingDone());
      dispatch(navigateToConfirmPage('agent', registrationId));
    } else {
      dispatch(slice.actions.processingFailed({ status: ApiError.Unknown }));
    }
  };

export const payOffline =
  (values: ReviewForm): AppThunk =>
  async (dispatch, getState) => {
    dispatch(slice.actions.startProcessing({ isOnline: false }));
    await dispatch(register(values));

    const isUkvi = searchSelect.selectors.isUkvi();
    const payOffline = isUkvi ? paymentClientUkvi.payOffline : paymentClient.payOffline;
    const registrationId = getState().registration.registrationId.data;

    if (registrationId) {
      try {
        await payOffline(registrationId);
        dispatch(slice.actions.processingDone());
        dispatch(navigateToConfirmPage('offline', registrationId));
      } catch (error: any) {
        const err = extractApiErrors(error);
        dispatch(slice.actions.processingFailed(err));
        logger.logError(error);
      }
      return;
    }
    dispatch(slice.actions.processingFailed({ status: ApiError.Unknown }));
  };

export const payOnlineMod =
  (values?: ReviewForm, regId?: number): AppThunk =>
  async (dispatch, getState) => {
    const flow = getRegFlow();
    dispatch(slice.actions.startProcessing({ isOnline: true }));
    values && (await dispatch(register(values)));

    const registrationId = getState().registration.registrationId.data ?? regId;

    if (registrationId) {
      try {
        const {
          data: { paymentId, paymentPageUrl },
        } = await registrationClient.payOnlineExamRegistration(
          registrationId,
          `${window.location.origin}/${flow}/online-payment/finish`
        );

        dispatch(slice.actions.setPaymentId(paymentId));

        if (paymentPageUrl) {
          storeOrganisationAliasInLS();

          /* Replaces /review page with /booking-complete page in history
          So that when going back from NPP won't break User Experience */
          changeLastPageInHistory(
            `/${flow}/booking-complete/online/${registrationId}/${paymentId}`
          );
          return (window.location.href = paymentPageUrl);
        }

        dispatch(
          slice.actions.processingFailed({
            status: ApiError.Unknown,
          })
        );
      } catch (error: any) {
        const err = extractApiErrors(error);
        dispatch(slice.actions.processingFailed(err));
        logger.logError(error);
      }
      return;
    }
    dispatch(slice.actions.processingFailed({ status: ApiError.Unknown }));
  };

export const finishOnlinePaymentMod =
  (paymentId?: string): AppThunk =>
  async (dispatch, getState) => {
    const registrationId = getState().registration.registrationId.data;
    if (registrationId && paymentId) {
      dispatch(slice.actions.processingDone());
      dispatch(navigateToConfirmPage('online', registrationId, paymentId));
    } else {
      dispatch(slice.actions.processingFailed({ status: ApiError.Unknown }));
    }
  };

/**
 * Manually renew IS4 token to give user 10 minutes to finish payment.
 * If user finish payment in more than 10 minutes, he will be logged out and redirected to start page.
 */
export const useRenewIS4Token = () => {
  const { signinSilent, user } = OidcContext.useAuth();
  const { locale } = useLocale();
  const dispatch = useDispatch();

  const renewToken = async () => {
    try {
      const extraQueryParams = {
        ui_locales: locale,
      };

      const userData = await signinSilent({
        extraQueryParams,
      });

      userData && user && (await dispatch(persistAuth(user)));
    } catch (e) {}
  };

  return { renewToken };
};
