import { candidateClient, termsAndConditionsClient } from 'ors-api/ukvi';
import { asyncThunk } from 'components/asyncThunk';

const apiClient = 'api-ukvi';

/*  Decided not to use these endpoints and feed Personal Details page using ORS2 endpoints.
    Below endpoints are left for future use. */

export const getCandidateChildren = asyncThunk(
  `${apiClient}/getCandidateChildren`,
  candidateClient.getCandidateChildren
);

export const getTermsAndConditionsNewestVersionNumber = asyncThunk(
  `${apiClient}/getTermsAndConditionsNewestVersionNumber`,
  (shortCode: string) =>
    termsAndConditionsClient.getTermsAndConditionsNewestVersionNumber(
      shortCode,
      'IELTS',
      'B2C',
      null
    )
);
