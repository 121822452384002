import { MarketingQuestionDto } from 'ors-api/mod';
import { MarketingQuestion, MarketingQuestionOption } from 'ors-api/ors2';
import { MarketingQuestionsDto } from 'ors-api/iol';
import { MarketingQuestionsShortCodes } from 'ors-ui';

/** Get single marketing question. */
export const getMarketingQuestion = (
  marketingQuestions: MarketingQuestionDto[] = [],
  questionCode: MarketingQuestionsShortCodes
) => marketingQuestions.filter((question) => question.shortCode === questionCode)[0];

/** Get single marketing question's available answers. */
export const getMarketingQuestionOptions = (
  marketingQuestions: MarketingQuestionDto[] = [],
  questionCode: MarketingQuestionsShortCodes
) => {
  const question = getMarketingQuestion(marketingQuestions, questionCode);
  return getOptions(question?.options);
};

export const getOptions = (options?: MarketingQuestionOption[]) => {
  return options
    ? options.map((o) => ({
        name: o.esolDescription ?? '-',
        value: o.id.toString(),
        allowOpenAnswer: o.allowOpenAnswer,
      }))
    : [];
};

/** Map marketing questions for IOL to match IC type */
export const mapIolMq = (questions: MarketingQuestionsDto): MarketingQuestion[] => {
  if (!questions?.marketingQuestions) return [];

  return questions?.marketingQuestions.map((q) => ({
    id: q.id,
    name: q.name,
    shortCode: q.shortCode,
    options: q.marketingQuestionOptions?.map((o) => ({
      id: o.id,
      marketingQuestionId: q.id,
      displayOrder: o.displayOrder,
      active: o.active,
      allowOpenAnswer: o.allowOpenAnswer,
      esolDescription: o.name,
      esolCode: o.code,
    })),
  }));
};
