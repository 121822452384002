import slice from '../bookTestSlice';
import { AppThunk } from '../..';
import { getExams } from '../../api.iol';
import { appRoutes, navigateTo } from 'routing';
import { SelectedIolSession } from '../../registration/types';
import { registration } from '../../registration';
import { reservation } from '../../reservation';
import { RebookStatus } from './thunks.ors';
import { FullCandidatePersonalDetails } from '../../candidate';
import { getIdFiles } from '../../api';
import { isIdValid, isIdTypeAvailable } from '../../idDetails/thunks/thunks.helpers';
import { verifyDocuments } from '../../idDetails/api/api.ors';

export const loadData = (): AppThunk => async (dispatch, getState) => {
  //Restore as default stuff from searchSelect slice
  const { lrwSessionIds, ieltsProduct } = getState().searchSelect;
  dispatch(
    slice.actions.updateState({
      lrwSessionIds,
    })
  );

  if (!lrwSessionIds) {
    navigateTo(appRoutes.search.findTest);
    return;
  }

  ieltsProduct && dispatch(getExams({ productId: ieltsProduct, lrwSessionIds: lrwSessionIds }));
};

export const proceed =
  (
    selectedSession: SelectedIolSession,
    setIsBookingInProgress: (val?: number) => void,
    handleIdentityFlow: () => void
  ): AppThunk =>
  async (dispatch, getState) => {
    setIsBookingInProgress(selectedSession.lrwSessionId);
    const inChangeMode = getState().registration.changeMode;
    const isLoggedIn = getState().auth.isLoggedIn;

    dispatch(registration.actions.setSelectedIolExam(selectedSession));

    if (inChangeMode) {
      await dispatch(reservation.thunksIol.preregisterIol());
      const { processingErr, showExamTakenModal } = getState().reservation;
      if (processingErr || showExamTakenModal) return setIsBookingInProgress(undefined);
      navigateTo(appRoutes.journey.review);
      return;
    }

    isLoggedIn ? navigateTo(appRoutes.journey.personalDetails) : handleIdentityFlow();
  };

export const getRebookStatusIol =
  (profile?: FullCandidatePersonalDetails): AppThunk<Promise<RebookStatus>> =>
  async (dispatch, getState) => {
    profile?.id && dispatch(verifyDocuments(profile.id));

    if (!profile?.idNumber) return RebookStatus.NewTestTaker;

    if (!isIdTypeAvailable(getState())) {
      return RebookStatus.IdInvalid;
    }

    if (!isIdValid(getState())) {
      /** ID already expired or will expire before test date */
      return RebookStatus.IdInvalid;
    } else {
      await dispatch(getIdFiles(profile.id));
      const hasUploadedFiles = getState().idDetails.initIdFiles.length > 0;
      return hasUploadedFiles ? RebookStatus.ExistingTestTaker : RebookStatus.IdUploadRequired;
    }
  };
