import { ApiData, AddressFields, ApiErrorsList } from 'ors-ui';
import { GenderDto } from 'ors-api/mod';
import {
  CandidateMarketingPreferences,
  Candidate,
  CandidateBasicWithFamilyRelationsip,
} from 'ors-api/ors2';

import { ChildAfterExamType } from 'core';
import { ForWhom } from '../candidate';

export interface PersonalDetailsState {
  dependants: ApiData<Candidate[]>;
  parentDetails: ApiData<CandidateBasicWithFamilyRelationsip>;

  genders: GenderDto[];
  allowEdit: boolean;
  loadingData: boolean;
  processing: boolean;
  mobileCountryIso?: string;
  processingErr?: ApiErrorsList;
}

export interface PersonalDetailsForm extends AddressFields {
  forWhom: ExtForWhom;

  visaTypeId?: number;

  dateOfBirth: string;
  firstName: string;
  lastName: string;
  gender: string;

  email: string;
  confirmEmail: string;
  password: string;
  mobile: string;
  smsNotificationsAllowed: boolean;

  countryId: string;

  acceptIeltsTermsAndConditions: boolean;
  marketingPrefs: CandidateMarketingPreferences;
}

export type ExtForWhom = ForWhom | number;

export const childAfterExamTypes: { [key: string]: ChildAfterExamType } = {
  '': '',
  '1': 'CanLeaveOnItsOwn',
  '2': 'IWillPickHim',
  '3': 'SomeoneElseWillPick',
};

export enum Titles {
  MALE = 1,
  FEMALE = 4,
}

export type LoadData<A> = {
  awardingBodySystem?: A;
  centreId?: number;
  isLoggedIn: boolean;
};
