import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import { Button } from '@britishcouncil/react-solas';
import { goTop, ApiError, getErrorCode } from '@britishcouncil/react-common';
import { LinkButton, ErrorModal } from '@britishcouncil/react-solas-ors3';
import { ExamFormat } from 'ors-api/ors2';

import { GTM, getRegFlow, useSelector, useLocale } from 'core';
import { PageHeading } from 'components';
import { bookTest } from 'store/bookTest';
import { reservation } from 'store/reservation';
import { registration } from 'store/registration';
import { organisationCountry } from 'store/organisationCountry';
import { useGetAvailableIdsQuery } from 'store/idDetails';
import { ExamTakenModal, ExistingRegistrationsModal } from '../PersonalDetails/common/components';
import { RegInProgressModal } from './components';
import { FilterSection } from './FilterSection';
import { FilterModal } from './FilterModal';
import { ResultsList } from './ResultsList';
import { searchSelect } from 'store/searchSelect';
import { ExamOptionType, AvailableDistrictExamDto } from 'ors-api/mod';
import { useIdentityFlow } from 'pages/PersonalDetails/common/useIdentityFlow';
import { ApplyBoardVoucherWarning } from './components/ApplyBoardVoucherWarning';
import { clearErrors } from 'store';
import { useGetAccessibilitiesOnLngChange } from 'store/accessibility';

const BookTest: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { handleIdentityFlow } = useIdentityFlow('book-test');

  const showVenues = useSelector(
    (state) => state.searchSelect.activeCountry?.displayVenues || false
  );
  const isLifeSkills = useSelector(searchSelect.selectors.isUkviLifeSkills);
  const {
    loadingData,
    loadingMoreData,
    exams,
    loadingExamDetails,
    loadExamDetailsError,
    loadDataError: loadExamsError,
  } = useSelector((state) => state.bookTest);
  const { isDefaultFilter } = useSelector((state) => state.searchSelect);
  const { childId, forWhom } = useSelector((state) => state.candidate);
  const canLoadMore = useSelector(bookTest.selectors.getCanLoadMore);
  const organisationId = useSelector(organisationCountry.selectors.getOrgId);
  const isRegInProgress = useSelector(reservation.selectors.isRegInProgress);
  const showModifyNote = useSelector(
    (state) => state.organisationCountry.orgCountryDetails.data?.shouldConfirmSpeakingSelection
  );

  const [plannedExamIdInProgress, setPlannedExamIdInProgress] = useState<number | undefined>(
    undefined
  );
  const [showRegInProgressWarn, setShowRegInProgressWarn] = useState(false);
  const [selExam, setSelExam] = useState<AvailableDistrictExamDto>();
  const [inChangeModeExamToCheck, setInChangeModeExamToCheck] =
    useState<AvailableDistrictExamDto>();

  const isNewMinor = !childId && forWhom === 'someone-else';

  const awardingBodySystem = useSelector(
    (state) => state.bookTest.selectedExam?.awardingBodySystem
  );
  const organisationCountryId = useSelector(
    (state) => state.bookTest.selectedExam?.organisationCountryId
  );
  const productFamilyId = useSelector((state) => state.searchSelect.activeCountry?.productFamilyId);
  const { isLoggedIn } = useSelector((state) => state.auth);
  const { isFetching: isFetchingIdTypes } = useGetAvailableIdsQuery({
    awardingBodySystem,
    organisationCountryId,
    productFamilyId,
    fetch: isLoggedIn,
    locale: useLocale().locale,
  });
  const { allAccessibilities, isLoadingAccessibilities } = useGetAccessibilitiesOnLngChange();

  const isLoading = loadingData || isFetchingIdTypes || isLoadingAccessibilities;

  const loadMore = () => dispatch(bookTest.thunks.loadMoreExamResults());
  const handleBook = (exam: AvailableDistrictExamDto, checkExamBefore?: boolean) => {
    dispatch(clearErrors());
    setPlannedExamIdInProgress(exam.plannedExamId);
    const isUkvi = getRegFlow() === 'ukvi';
    if (!inChangeModeExamToCheck && checkExamBefore && !isNewMinor) {
      return setInChangeModeExamToCheck(exam);
    }
    setInChangeModeExamToCheck(undefined);
    if (isUkvi && !isLifeSkills) {
      dispatch(
        searchSelect.actions.setUkviSpecificData({
          examOptionType:
            exam.examFormat === ExamFormat.PB
              ? ExamOptionType.IELTSUKVILRW
              : ExamOptionType.IELTSCDUKVILRW,
        })
      );
    }
    if (isRegInProgress && !showRegInProgressWarn) {
      setPlannedExamIdInProgress(0);
      setShowRegInProgressWarn(true);
      setSelExam(exam);
    } else {
      dispatch(bookTest.thunks.bookExam(exam, handleIdentityFlow));
      setShowRegInProgressWarn(false);
    }
  };

  const onRestart = () => {
    if (selExam) {
      dispatch(reservation.actions.clear());
      handleBook(selExam);
    }
  };

  const resultsToShow = !(!exams || !exams.results || exams.results.length === 0);

  useEffect(() => {
    dispatch(bookTest.thunks.loadInitialData());
    showModifyNote && dispatch(registration.actions.setModifyNoteAccepted(false));
  }, [dispatch, showModifyNote, organisationId]);

  const [showFilter, setShowFilter] = useState(false);

  const errCode = getErrorCode(loadExamDetailsError) ?? 'server';

  const errorMessage = useMemo(() => {
    switch (errCode) {
      case 'network':
        return t('APPB2C.common.basic.errors.network');
      case 'server':
        return t('APPB2C.common.basic.errors.server');
      case 'unknown':
        return t('APPB2C.common.basic.errors.unknown');
      case 'contactTestCentre':
        return t('APPB2C.common.basic.errors.contactTestCentre');
      case 'request':
        return t('APPB2C.common.basic.errors.noTest');
      default:
        return undefined;
    }
  }, [errCode, t]);

  return (
    <article>
      <PageHeading data-testid="book-test-title" heading={t('APPB2C.common.bookTest.title')} />
      <ApplyBoardVoucherWarning />
      <FilterSection onOpenFilters={() => setShowFilter(true)} disabled={isLoading} />

      <ResultsList
        results={exams}
        loadError={loadExamsError}
        loading={isLoading}
        showVenues={showVenues}
        handleBook={handleBook}
        isDefaultFilter={isDefaultFilter}
        loadingExamDetails={loadingExamDetails || plannedExamIdInProgress}
        allAccessibilities={allAccessibilities}
      />
      {!isLoading && resultsToShow && (
        <BottomActions>
          {canLoadMore && (
            <Button
              type="button"
              intent="default"
              id="btn-load-more"
              disabled={loadingMoreData}
              onClick={loadMore}
              loading={loadingMoreData}
            >
              {t('APPB2C.common.bookTest.loadMoreTests')}
            </Button>
          )}
          <LinkButton onClick={goTop} id="btn-gotop">
            {t('APPB2C.common.basic.backToTop')}
          </LinkButton>
        </BottomActions>
      )}
      <ErrorModal
        show={!!loadExamDetailsError}
        errorMessage={errorMessage}
        onExit={() => {
          dispatch(bookTest.actions.setLoadExamDetailsError(ApiError.None));
        }}
        onHide={() => GTM.trackModal('Hide', 'exam-details-err-modal')}
        onShow={() => GTM.trackModal('Show', 'exam-details-err-modal')}
        i18n={{
          close: t('APPB2C.common.basic.close'),
          header: t('APPB2C.common.basic.wereSorry'),
          title: t('APPB2C.common.basic.failure'),
        }}
      />
      <FilterModal
        show={showFilter}
        onExit={() => setShowFilter(false)}
        onConfirm={(filterSelection) => {
          setShowFilter(false);
          dispatch(bookTest.thunks.changeFilter(filterSelection));
        }}
      />
      <RegInProgressModal
        onExit={() => setShowRegInProgressWarn(false)}
        showModal={showRegInProgressWarn}
        onRestart={onRestart}
        onCancel={() => setShowRegInProgressWarn(false)}
      />
      <ExamTakenModal />
      {inChangeModeExamToCheck && (
        <ExistingRegistrationsModal
          inChangeModeExamToCheck={inChangeModeExamToCheck}
          handleBook={handleBook}
        />
      )}
    </article>
  );
};

export default BookTest;

const BottomActions = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1em;
  justify-content: center;
  align-items: center;

  #btn-load-more {
    margin-bottom: 0.5em;
  }
`;
