import { lazy } from 'react';

import { getRegFlow } from 'core';

const TestDetailsOrs = lazy(() => import('./ors'));
const TestDetailsIol = lazy(() => import('./iol'));
const NotFound = lazy(() => import('../../pages/NotFound'));

const TestDetails = () => {
  const flow = getRegFlow();

  switch (flow) {
    case 'ors':
      return <TestDetailsOrs />;
    case 'ukvi':
      return <TestDetailsOrs />;
    case 'iol':
      return <TestDetailsIol />;

    default:
      return <NotFound />;
  }
};

export default TestDetails;
