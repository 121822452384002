import { getOrganisationAlias } from 'ors-utils';
import { IELTSProducts } from 'ors-api/iol';
import { ExamOptionType, ProductsInCentre } from 'ors-api/mod';

import { AppThunk } from '.';
import { history } from './historyState';
import { ExamType } from './registration';
import { isAnyAvailable } from './api.iol';
import slice from './searchSelect/searchSelectSlice';
import { appRoutes, routeWithFlow } from 'routing';
import { RegFlow } from 'core';

export const startPageRedirect =
  (productId: IELTSProducts | ProductsInCentre): AppThunk =>
  async (dispatch, getState) => {
    const { deeplinkToken } = getState().invitation;
    const orgAlias = getOrganisationAlias();
    const ukviFindTestRoute = appRoutes.search.findTest;

    const showChoosePage = async (productId: IELTSProducts) => {
      if (!deeplinkToken && !orgAlias) {
        await dispatch(isAnyAvailable({ productId }));
        return getState().searchSelect.iolAvailable.data ?? false;
      }

      return false;
    };

    const selectRoute = (showChoosePage: boolean) =>
      showChoosePage ? appRoutes.search.chooseTest : appRoutes.search.findTest;

    dispatch(slice.actions.setUkviSpecificData(undefined));

    const getUkviSpecificData = (
      lifeSkill?: 'a1' | 'a2' | 'b1'
    ): {
      examOptionType: ExamOptionType;
    } => {
      switch (lifeSkill) {
        case 'a1':
          return {
            examOptionType: ExamOptionType.LifeSkillsA1,
          };
        case 'a2':
          return {
            examOptionType: ExamOptionType.LifeSkillsA2,
          };
        case 'b1':
          return {
            examOptionType: ExamOptionType.LifeSkillsB1,
          };

        default:
          return {
            examOptionType: ExamOptionType.IELTSUKVILRW,
          };
      }
    };

    const redirect = async (
      examType: ExamType,
      flow: RegFlow,
      lifeSkill?: 'a1' | 'a2' | 'b1',
      isUol?: boolean
    ) => {
      const isExamTypeAc = examType === 'ac';
      const productUOL = isExamTypeAc ? IELTSProducts.UkviOnlineAC : IELTSProducts.UkviOnlineGT;
      const productIOL = isExamTypeAc ? IELTSProducts.OnlineAC : IELTSProducts.OnlineGT;
      const isUkvi = flow === 'ukvi';
      const currentRoute = lifeSkill
        ? ukviFindTestRoute
        : selectRoute(await showChoosePage(isUkvi ? productUOL : productIOL));

      dispatch(slice.actions.setExamType({ examType, flow }));
      isUkvi &&
        !isUol &&
        dispatch(slice.actions.setUkviSpecificData(getUkviSpecificData(lifeSkill)));

      return history.push(
        routeWithFlow(currentRoute, {
          flow: isUkvi ? 'ukvi' : 'ors',
        })
      );
    };

    const redirectByProductId = async (productId?: IELTSProducts | ProductsInCentre) => {
      switch (productId) {
        // IOL
        case IELTSProducts.OnlineAC:
          return redirect('ac', 'iol');

        case IELTSProducts.OnlineGT:
          return redirect('gt', 'iol');

        // UOL
        case IELTSProducts.UkviOnlineAC:
          return redirect('ac', 'ukvi', undefined, true);

        case IELTSProducts.UkviOnlineGT:
          return redirect('gt', 'ukvi', undefined, true);

        // ORS
        case ProductsInCentre.IELTSAcademicPaperBase:
        case ProductsInCentre.IELTSAcademicComputerDelivered:
          return redirect('ac', 'ors');

        case ProductsInCentre.IELTSGeneralTrainingPaperBase:
        case ProductsInCentre.IELTSGeneralTrainingComputerDelivered:
          return redirect('gt', 'ors');

        // UKVI
        case ProductsInCentre.UKVIAcademicComputerDelivered:
        case ProductsInCentre.UKVIAcademicPaperBase:
          return redirect('ac', 'ukvi');

        case ProductsInCentre.UKVIGeneralTrainingComputerDelivered:
        case ProductsInCentre.UKVIGeneralTrainingPaperBase:
          return redirect('gt', 'ukvi');

        case ProductsInCentre.UKVILifeSkillsA1:
          return redirect('ac', 'ukvi', 'a1');

        case ProductsInCentre.UKVILifeSkillsA2:
          return redirect('ac', 'ukvi', 'a2');

        case ProductsInCentre.UKVILifeSkillsB1:
          return redirect('ac', 'ukvi', 'b1');

        default:
          break;
      }
    };

    redirectByProductId(productId);
  };
