import { createSelector } from '@reduxjs/toolkit';
import { IELTSProducts } from 'ors-api/iol';

import { RootState } from '../';
import {
  IeltsModule,
  PreRegisterRequest,
  PreRegisterRequestPreregisterType,
  SpeakingFormat,
} from 'ors-api/ors2';
import { converters } from 'common';
import { getOrgId } from '../organisationCountry/selectors';
import { isIol } from 'store/searchSelect/selectors';
import { ExamModule, ReserveRequest, SpeakingFormat2 } from 'ors-api/mod';
import { userFriendlyTime } from '@britishcouncil/react-solas-ors3';

export const getReservation = (s: RootState) => s.reservation;
export const getRegistration = (s: RootState) => s.registration;

export const getProcessingError = () => {
  return createSelector(getReservation, (reservation) => {
    return converters.tErrorMessageReservation(reservation.processingErr);
  });
};

export const getPreregisterModRequest = createSelector(
  (state: RootState) => state,
  getOrgId,
  (s, organisationId) => {
    const { inCentreExam, inCentreSpeakingExam } = s.registration;
    const { ieltsModule } = s.searchSelect;

    if (!inCentreExam || !inCentreSpeakingExam) {
      throw new Error('Missing exam details');
    }

    const req: ReserveRequest = {
      districtExamId: inCentreExam.plannedExamId,
      examModule: ieltsModule === IeltsModule.AC ? ExamModule.AC : ExamModule.GT,
      organisationId,
      speakingDetails: {
        venueId: inCentreSpeakingExam.venueId,
        startTime: inCentreSpeakingExam.startTime,
        endTime: inCentreSpeakingExam.endTime,
        examDate: inCentreSpeakingExam.date,
        format:
          inCentreSpeakingExam.speakingFormat === SpeakingFormat.Live
            ? SpeakingFormat2.InPerson
            : SpeakingFormat2.VideoCall,
      },
      lrwDetails: {
        venueId: inCentreExam.displayVenues ? inCentreExam.venueId : undefined,
      },
    };

    return req;
  }
);

export const getPreregisterRequest = createSelector(
  (state: RootState) => state,
  getOrgId,
  (s, organisationId) => {
    const { profile, forWhom, childId, ttProfile } = s.candidate;
    const { inCentreExam, inCentreSpeakingExam, changeMode } = s.registration;
    const { ieltsModule } = s.searchSelect;

    let candidateId = profile?.id || 0;

    if (!inCentreExam || !inCentreSpeakingExam) {
      throw new Error('Missing exam details');
    }

    const someoneElseId =
      forWhom === 'myself' ? undefined : changeMode && ttProfile?.id ? ttProfile.id : childId;

    const req: PreRegisterRequest = {
      plannedExamId: inCentreExam.plannedExamId,
      candidateId,
      ieltsModule: ieltsModule!,
      venueId: inCentreExam.displayVenues ? inCentreExam.venueId : undefined, // Do not change. API breaks when it receives `0`
      speakingDetails: {
        speakingFormat: inCentreSpeakingExam.speakingFormat || SpeakingFormat.Live,
        speakingVenueId: inCentreSpeakingExam.venueId,
        speakingExamDate: converters
          .fromUncertainUtcDate(inCentreSpeakingExam?.date)
          .toProperUtcDate(),
        speakingStartTime: userFriendlyTime(inCentreSpeakingExam.startTime),
        speakingEndTime: userFriendlyTime(inCentreSpeakingExam.endTime),
        isSpeakingReserved: false,
      },
      organisationId,
      someoneElseId,
      requestType:
        forWhom === 'myself'
          ? PreRegisterRequestPreregisterType.Candidate
          : changeMode || childId
            ? PreRegisterRequestPreregisterType.ExistingChild
            : PreRegisterRequestPreregisterType.NewChild,
    };

    return req;
  }
);

export const isRegInProgress = createSelector(
  getReservation,
  getRegistration,
  (res, reg) => !!res.reservationGuid && !reg.changeMode && !reg.rebooking
);

export const isUolContext = (state: RootState) =>
  (state.reservation.productId === IELTSProducts.UkviOnlineAC && isIol()) ||
  (state.reservation.productId === IELTSProducts.UkviOnlineGT && isIol());
