import { FC, Fragment, InputHTMLAttributes } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { BsChevronRight } from 'react-icons/bs';
import styled from '@emotion/styled';

import { useLocale, useSelector, getRegFlow } from 'core';
import { searchSelect } from 'store/searchSelect';
import { appRoutes, routeWithFlow } from 'routing';

const { findTest, bookTest, testDetails } = appRoutes.search;
const { chooseTest } = appRoutes.utils;

const getAppRoute = (pathName: string) => pathName.split('/').slice(-1)[0];

/** Breadcrumbs that shows content based on URL path */
export const Breadcrumbs: FC<InputHTMLAttributes<HTMLDivElement>> = ({ ...props }) => {
  const location = useLocation();

  const breadcrumbs = [];
  if ([chooseTest, findTest, bookTest, testDetails].includes(getAppRoute(location.pathname))) {
    breadcrumbs.push(<ChangeExamType />);
  }
  if ([bookTest, testDetails].includes(getAppRoute(location.pathname))) {
    breadcrumbs.push(<ChangeTestType />);
  }
  if (breadcrumbs.length === 0) {
    return null;
  }

  return (
    <StyledBreadcrumbs className="breadcrumbs" {...props}>
      {breadcrumbs.map((e, index) => (
        <Fragment key={index}>
          {e} <BsChevronRight className="divider" />{' '}
        </Fragment>
      ))}
    </StyledBreadcrumbs>
  );
};

const ChangeExamType = () => {
  const { t } = useTranslation();
  const examTypeTitle = useSelector(searchSelect.selectors.getExamTypeLongName);

  return (
    <BreadcrumbLink
      to={appRoutes.root}
      className="link notranslate"
      title={t('APPB2C.common.bookTest.changeExamType')}
      data-testid="link-changeExamType"
    >
      {examTypeTitle}
    </BreadcrumbLink>
  );
};

const ChangeTestType = () => {
  const { t } = useTranslation();
  const { localeDate } = useLocale();
  const { activeLocation, showAllDates, locationCoords, datesRange } = useSelector(
    (s) => s.searchSelect
  );
  const readableDatesRange = useSelector(searchSelect.selectors.getHumanReadableDatesRange);

  return getRegFlow() === 'iol' ? (
    <>
      <BreadcrumbLink
        to={routeWithFlow(findTest)}
        data-testid="link-changeSearchQuery"
        className="link"
      >
        {datesRange ? localeDate(datesRange[0]) : ''}
      </BreadcrumbLink>
    </>
  ) : (
    <BreadcrumbLink
      to={routeWithFlow(findTest)}
      data-testid="link-changeSearchQuery"
      className="link"
    >
      {!!locationCoords
        ? t('APPB2C.common.findTest.selectLocation.closestToMe')
        : !activeLocation?.districtChildNames || !activeLocation?.districtChildNames.length
        ? activeLocation?.name
        : activeLocation?.districtChildNames?.length > 1
        ? t('APPB2C.common.findTest.multipleLocations')
        : activeLocation?.districtChildNames[0]}
      ,{' '}
      {!showAllDates && !!readableDatesRange
        ? readableDatesRange
        : t('APPB2C.common.bookTest.filters.allDates')}
    </BreadcrumbLink>
  );
};

const StyledBreadcrumbs = styled.div`
  > a {
    margin-right: 0;
  }

  .divider {
    margin: -1px 6px;
    vertical-align: middle;

    &:last-child {
      display: none;
    }
  }
`;

const BreadcrumbLink = styled(Link)`
  margin: 0 0.5em 1em 0;
  display: inline-block;

  svg {
    margin-right: 6px;
    margin-bottom: -2px;
  }

  &:focus-visible {
    outline: 0;
    box-shadow: 0 0 0 6px rgba(1, 156, 238, 0.5);
    z-index: 1;
    position: relative;
  }
`;
