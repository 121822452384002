import { SimpleLS } from '@britishcouncil/react-common';
import { getOrganisationAlias } from 'ors-utils';
import { ExamFormat } from 'ors-api/ors2';
import { ExamCountryDto } from 'ors-api/mod';

import slice from '../searchSelectSlice';
import { AppThunk } from '../..';
import { GTM, RegFlow } from 'core';
import { ExamType } from '../../registration';
import { Location } from '../models';
import { searchSelect, LocationCoords } from '../';

/**
 * Thunks not related to specific flow nor clients.
 */

export const setActiveCountry =
  (country: ExamCountryDto): AppThunk =>
  async (dispatch) => {
    await dispatch(slice.actions.setActiveLocation(undefined));
    await dispatch(slice.actions.setActiveCountry(country));

    GTM.updateCountry({
      country: country.isoCode2 || '',
      countryTagCode: country.googleTagManagerCode || '',
    });
    SimpleLS.write('COUNTRY_CODE', country.googleTagManagerCode || '');
    SimpleLS.write('GTM_COUNTRY_DETAILS', {
      name: country.name || '',
      isoCode: country.isoCode2 || '',
    });
  };

export const setActiveLocation =
  (location?: Location): AppThunk =>
  async (dispatch, getState) => {
    if (location?.name) {
      GTM.update({ location: location.name });
    }
    const locations = getState().searchSelect.locationsList.data;
    if (
      (locations &&
        location &&
        locations
          .map((c) => c.ids)
          .flat()
          .some((l) => location?.ids.some((c) => c === l))) ||
      !location
    ) {
      dispatch(slice.actions.setActiveLocation(location));

      dispatch(
        searchSelect.actions.setIsUkviOnRequest(
          !!location?.isMobile && !location.hasCdExamsAvailable && !location.hasPbExamsAvailable
        )
      );
    }
  };

export const chooseExamType =
  (examType: ExamType, flow?: RegFlow): AppThunk =>
  async (dispatch) => {
    dispatch(clearDatesRange(examType));
    dispatch(slice.actions.setExamType({ examType, flow }));
    GTM.update({
      prodType: examType,
      organization: getOrganisationAlias() || 'BC',
    });
  };

export const clearDatesRange =
  (newExamType: ExamType): AppThunk =>
  async (dispatch, getState) => {
    const { examType } = getState().searchSelect;

    if (newExamType !== examType) {
      dispatch(slice.actions.datesRangeChanged(undefined));
      dispatch(searchSelect.actions.datesRangeChanged(undefined));
    }
  };

export const needSpecialReqsToggled =
  (checked: boolean): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.needSpecialReqsToggled(checked));
    dispatch(slice.actions.setFilter({ examFormat: checked ? ExamFormat.PB : undefined }));
  };

export const setGelocation =
  (position?: LocationCoords): AppThunk =>
  async (dispatch) => {
    if (position) {
      GTM.update({ venueName: 'Closest to me' });
    }
    dispatch(searchSelect.actions.setGeolocationPosition(position));
  };
