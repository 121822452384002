export const useNavigationTabs = () => {
  const navigationTabs = [
    {
      key: 'results&tests',
      testDataId: 'results-and-test-nav-button',
      translationKey: 'APPB2C.common.nav.resultsTests',
      target: '/ttp',
      loginAccess: true,
      externalLink: true,
    },
    {
      key: 'bookNewTest',
      testDataId: 'book-new-test-nav-button',
      translationKey: 'APPB2C.common.nav.bookNewTest',
      target: '/',
      loginAccess: true,
    },
    {
      key: 'preparation',
      testDataId: 'preparation-nav-button',
      translationKey: 'APPB2C.common.nav.preparation',
      target: '/ttp/preparation',
      loginAccess: true,
      externalLink: true,
    },
    {
      key: 'helpAndContact',
      testDataId: 'help-and-contact-nav-button',
      translationKey: 'APPB2C.common.nav.helpAndContact',
      target: '/ttp/help-and-contact',
      loginAccess: true,
      externalLink: true,
    },
    {
      key: 'myAccount',
      testDataId: 'my-account-nav-button',
      translationKey: 'APPB2C.common.nav.myAccount',
      target: '/ttp/my-account',
      loginAccess: true,
      externalLink: true,
    },
  ];

  return { navigationTabs };
};
