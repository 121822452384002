import { GenderDto } from 'ors-api/mod';
import {
  CandidateBookingPersonalDetailsWithName,
  CandidateMarketingPreferences,
} from 'ors-api/ors2';

import { GenderType } from 'common';
import { GTM } from 'core';
import { PersonalDetailsForm, Titles } from '../models';

export function marketingPrefsToString(
  value: CandidateMarketingPreferences
): 'BcOnly' | 'BcAndThird' | 'None' {
  if (value === CandidateMarketingPreferences.BcAndThird) return 'BcAndThird';
  if (value === CandidateMarketingPreferences.BcOnly) return 'BcOnly';
  return 'None';
}

export const staticProfilePart = {
  isAwaitingParent: false,
  needToUpdateMarketingPreferences: false,
  needToVerifyDraftProcess: false,
  needToSupplyMissingDetails: false,
  hasEnglishOnlineAccount: false,
};

export const getTitleFromGender = (gender: string) =>
  gender === GenderType.Female ? Titles.FEMALE : Titles.MALE;

export function updateGTM(form: PersonalDetailsForm, country: string) {
  GTM.update({
    city: form.town,
    countryOfResidence: country,
    forWhom: form.forWhom === 'myself' ? 'myself' : 'child',
    gender: form.gender,
    title: getTitleFromGender(form.gender) === Titles.MALE ? 'Mr' : 'Ms',
    acceptTncs: form.acceptIeltsTermsAndConditions ? '1' : '0',
    marketingPreferences: marketingPrefsToString(form.marketingPrefs),
  });
}

export const candidateDetailsReq = (
  form: PersonalDetailsForm,
  id: number,
  addressId?: number
): CandidateBookingPersonalDetailsWithName => {
  return {
    id,
    firstName: form.firstName,
    surname: form.lastName,
    dob: form.dateOfBirth,
    addressId: addressId,
    addressLine1: form.addressLine1,
    addressLine2: form.addressLine2,
    addressLine3: form.addressLine3,
    gender: form.gender,
    mobile: form.mobile,
    smsNotificationsAllowed: form.smsNotificationsAllowed,
    countryId: parseInt(form.countryId, 10),
    postCode: form.postcode,
    state: form.state,
    town: form.town,
    titleId: getTitleFromGender(form.gender),
  };
};

/**
 * Get gender proper gender ID required by IOL API endpoints.
 * @param genders unified genders definitions received from API
 * @param gender gender string 'F' or 'M'
 * @returns gender ID required by IOL registration endpoint
 */
export const getGenderId = (genders?: GenderDto[], gender?: string) =>
  genders?.find((g) => g.shortCode === gender)?.id ?? -1;
