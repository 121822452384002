import { createSelector } from '@reduxjs/toolkit';
import { hasOrganisationInUrl } from 'ors-utils';
import { hasIeltsUsaUrl } from 'ors-translations';
import { RootState } from '..';

export type MarketingPref = {
  marketingPreferenceOrganisationOnly?: string;
  marketingPreferenceOrganisationAndThird?: string;
  marketingPreferenceNone?: string;
};

export const getMarketingPreferences = (state: RootState): MarketingPref | undefined => {
  const organisationDetails = state.organisationCountry.organisationDetails.data;

  if (organisationDetails) {
    const {
      marketingPreferenceOrganisationOnly,
      marketingPreferenceOrganisationAndThird,
      marketingPreferenceNone,
    } = organisationDetails;

    return {
      marketingPreferenceOrganisationOnly,
      marketingPreferenceOrganisationAndThird,
      marketingPreferenceNone,
    };
  }
};

export const getOrgId = createSelector(
  (state: RootState) => {
    return state.organisationCountry?.organisationDetails?.data?.organisationId;
  },
  (orgId) => {
    if (hasIeltsUsaUrl()) {
      return hasOrganisationInUrl() ? orgId : undefined;
    }
    return orgId;
  }
);
