import Uppy from '@uppy/core';
import { IdDetailsForm, UppyFileWithMeta } from '..';
import { AppThunk } from '../..';
import { GTM } from 'core';
import { candidate } from '../../candidate';
import { marketing } from '../../candidate/marketing';
import { removeDocsIfExpired } from './thunks.common';
import { appRoutes, navigateTo } from 'routing';

export const loadData = (): AppThunk => async (dispatch, getState) => {
  const forWhom = getState().candidate.forWhom;

  const candidateId =
    forWhom === 'myself'
      ? getState().candidate?.userProfile?.id
      : getState().candidate?.ttProfile?.id;

  await Promise.all([dispatch(marketing.thunks.loadData(candidateId ?? 0))]);

  candidateId && dispatch(removeDocsIfExpired(candidateId));
};

export const proceed =
  (form: IdDetailsForm, uppy: Uppy.Uppy<Uppy.TypeChecking>, nationalityName: string): AppThunk =>
  async (dispatch, getState) => {
    const inChangeMode = getState().registration.changeMode;
    const { idTypesIol } = getState().idDetails;
    const { answers } = getState().candidate;
    const { idFiles, ...idDetails } = form;

    dispatch(
      candidate.actions.setNationality({
        id: idDetails.countryOfNationality,
      })
    );
    dispatch(
      candidate.actions.setIdType({
        idType: idDetails.idType,
      })
    );

    const newFilesCount = uppy
      .getFiles()
      .filter((f: UppyFileWithMeta) => !f.meta.reusedFile).length;

    GTM.update({
      uploadedFiles: newFilesCount === 0 ? 0 : 1,
    });

    dispatch(candidate.actions.setIdDetailsIol(idDetails));

    const idTypeName = idTypesIol.data?.find((t) => t.id === idDetails.idType)?.name;
    GTM.update({ idType: idTypeName || '' });

    if (inChangeMode || answers.data) {
      navigateTo(appRoutes.journey.review);
    } else {
      navigateTo(appRoutes.journey.ttProfile);
    }
  };
