import { extractApiError } from '@britishcouncil/react-common';
import {
  ApiResponseOfOrganisationConfigurationDto,
  ApiResponseOfOrganisationCountryConfigurationDto,
  organisationCountriesClient,
  organisationsClient,
} from 'ors-api/mod';

import { logger } from 'ors-utils';
import { appRoutes } from 'routing';
import { api, BaseTranslatedQueryParams } from 'store/shared';
import { organisationCountry } from '.';

const organisationCountryApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getOrgCountryConfiguration: builder.query<
      ApiResponseOfOrganisationCountryConfigurationDto,
      { organisationCountryId?: number } & BaseTranslatedQueryParams
    >({
      query: ({ organisationCountryId, productFamilyId }) => {
        if (!organisationCountryId || !productFamilyId) return Promise.resolve({} as any);

        return organisationCountriesClient.getOrganisationCountryConfiguration(
          organisationCountryId,
          productFamilyId
        );
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        dispatch(organisationCountry.actions.setOrgCountryDetails({ isLoading: true }));
        try {
          const { data } = await queryFulfilled;
          dispatch(
            organisationCountry.actions.setOrgCountryDetails({
              isLoading: false,
              data: data?.value,
            })
          );
        } catch (error) {
          logger.logError(error as Error);
          dispatch(
            organisationCountry.actions.setOrgCountryDetails({
              isLoading: false,
              error: extractApiError(error),
            })
          );
        }
      },
    }),
    getOrganisationConfiguration: builder.query<
      ApiResponseOfOrganisationConfigurationDto,
      { alias: string | null } & BaseTranslatedQueryParams
    >({
      query: ({ alias, productFamilyId }) => {
        if (!alias || !productFamilyId) return Promise.resolve({} as any);

        return organisationsClient.getOrganisationConfiguration(productFamilyId, alias);
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        dispatch(organisationCountry.actions.setOrganisationDetails({ isLoading: true }));
        try {
          const { data } = await queryFulfilled;
          dispatch(
            organisationCountry.actions.setOrganisationDetails({
              isLoading: false,
              data: data?.value,
            })
          );

          if (data && !data.hasValue) {
            window.location.href = window.location.origin + appRoutes.incorrectOrganisation;
          }
        } catch (error) {
          logger.logError(error as Error);
          dispatch(
            organisationCountry.actions.setOrganisationDetails({
              isLoading: false,
              error: extractApiError(error),
            })
          );
        }
      },
    }),
  }),
});

export const {
  useGetOrgCountryConfigurationQuery,
  useGetOrganisationConfigurationQuery,
  useLazyGetOrganisationConfigurationQuery,
} = organisationCountryApi;
