import { UppyFile } from '@uppy/core';

import slice, { initialState } from './idDetailsSlice';
import * as thunksCommon from './thunks/thunks.common';
import * as thunksIol from './thunks/thunks.iol';

export * from './models';
export * from './routeValidation';
export * from './idDetails.service';

export const idDetails = {
  ...slice,
  thunksCommon,
  thunksIol,
  initialState,
};

export type UppyFileWithMeta = UppyFile<{ candidateDocumentId?: string; reusedFile?: boolean }>;
