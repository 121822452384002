import slice, { initialState } from './searchSelectSlice';
import * as thunksCommon from './thunks/thunks.common';
import * as thunksOrs from './thunks/thunks.ors';
import * as thunksIol from './thunks/thunks.iol';
import * as thunksUkvi from './thunks/thunks.ukvi';
import * as selectors from './selectors';

export * from './models';
export * from './searchSelect.service';
export const searchSelect = {
  ...slice,
  thunksOrs,
  thunksCommon,
  thunksIol,
  thunksUkvi,
  selectors,
  initialState,
};
