import TagManager from 'react-gtm-module';

/**
 * Initializes Google Analytics & Google Tag Manager
 * @param gtmCode GTM id/code, must be something like GTM-000000.
 */
function initialize(gtmCode: string, gtmAuth?: string, previewEnv?: string) {
  if (!gtmCode) {
    throw new Error('There is no valid GTM code. Please provide it by `setupGtmCode` method.');
  }

  TagManager.initialize({
    gtmId: gtmCode,
    auth: gtmAuth,
    preview: previewEnv,
  });
}

function updateDataLayer(dataLayer: object) {
  TagManager.dataLayer({
    dataLayer,
    dataLayerName: 'dataLayer',
  });
}

export const GoogleTagManager = { initialize, updateDataLayer };
