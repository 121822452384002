import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { preRegister as preRegisterUkvi } from '../api/api.ukvi';
import { ReservationState } from '../models';
import { preregister } from './helpers';

export const extraReducersUkvi = (builder: ActionReducerMapBuilder<ReservationState>) => {
  /** Pre-register selected exam for UKVI */
  builder
    .addCase(preRegisterUkvi.pending, preregister.pending)
    .addCase(preRegisterUkvi.fulfilled, preregister.fullfiled)
    .addCase(preRegisterUkvi.rejected, preregister.rejected);
};
