import {
  AwardingBodySystem,
  MarketingQuestion,
  MarketingQuestionAnswer,
  MarketingQuestionDto,
} from 'ors-api/ors2';
import { MarketingQuestionsShortCodes } from 'ors-ui';

import { GTM } from 'core';
import { appRoutes, navigateTo } from 'routing';
import { AppThunk } from '../..';
import { verifyCandidateLastMarketingAnswers } from '../../api';
import { getVerifiedDetailsIol } from '../../api.iol';
import { searchSelect } from '../../searchSelect';
import { TtProfileForm } from '..';
import { candidate } from '..';

export const loadData =
  (id: number): AppThunk =>
  async (dispatch, getState) => {
    const { productId } = getState().reservation;
    const isIol = searchSelect.selectors.isIol();
    const awardingBodySystem = getState().bookTest.selectedExam?.awardingBodySystem;

    const { answers } = getState().candidate;

    if (!answers.data?.isValid) {
      isIol
        ? //TODO: "getVerifiedDetailsIol" includes last answers. Call it on same screens before because it also includes candidates data
          productId && (await dispatch(getVerifiedDetailsIol(productId)))
        : await dispatch(
            verifyCandidateLastMarketingAnswers({
              candidateId: id,
              awardingBodySystem: awardingBodySystem ?? AwardingBodySystem.CMDS,
              answers: answers.data?.answers,
            })
          );
    }
  };

export const proceed =
  (values: TtProfileForm, mqs: MarketingQuestionDto[] = []): AppThunk =>
  async (dispatch) => {
    const valuesWithoutOtherLng = Object.keys(values).filter(
      (shortCode) => shortCode !== 'otherLngInput'
    );

    const answers = mqs
      ? valuesWithoutOtherLng.map((shortCode) => {
          const question = mqs.find((q) => q.shortCode?.toLowerCase() === shortCode.toLowerCase());
          const answer = question?.options?.find(
            (o) => o.id === Number(values[shortCode as keyof TtProfileForm])
          );

          const ttAnswer: MarketingQuestionAnswer = {
            registrationIeltsSpecificId: question?.id!,
            marketingQuestionShortCode: question?.shortCode,
            marketingQuestionId: question?.id!,
            id: question?.id!,
            marketingQuestionOptionId: answer?.id!,
            openAnswerText: answer?.allowOpenAnswer
              ? shortCode === 'mainLanguageSpoken'
                ? values.otherLngInput
                : 'Other'
              : undefined,
          };
          return ttAnswer;
        })
      : [];

    if (mqs) {
      GTM.update({
        nationality: findMQTextValue(mqs, 'CountryOfNationality', values.countryOfNationality),
        yearsOfStudying: findMQTextValue(
          mqs,
          'YearsOfStudyingEnglish',
          values.yearsOfStudyingEnglish
        ),
        firstLang: findMQTextValue(mqs, 'MainLanguageSpoken', values.mainLanguageSpoken),
        educationLevel: findMQTextValue(mqs, 'EducationLevel', values.educationLevel),
        occupationSector: findMQTextValue(mqs, 'OccupationSector', values.occupationSector),
        occupationLevel: findMQTextValue(mqs, 'OccupationLevel', values.occupationLevel),
        reasonForTakingTest: findMQTextValue(
          mqs,
          'ReasonForTakingTest',
          values.reasonForTakingTest
        ),
        countryBeingApplied: findMQTextValue(
          mqs,
          'CountryBeingApplied',
          values.countryBeingApplied
        ),
      });
    }

    dispatch(candidate.actions.setAnswers(answers));
    navigateTo(appRoutes.journey.review);
  };

export function findMQTextValue(
  mqs: MarketingQuestion[],
  questionCode: MarketingQuestionsShortCodes,
  value: string | number
): string {
  const question = mqs?.find((q) => q.shortCode === questionCode);
  if (!question) return '';

  return question.options?.find((o) => o.id.toString() === value.toString())?.esolDescription || '';
}
