import { RootState } from '../';
import { appRoutes } from 'routing';
import { getRegFlow } from 'core';

export function validateState(state: RootState): true | string {
  const ss = state.searchSelect;

  if (!ss.examType) return '/';
  if (getRegFlow() === 'iol') {
    /* IOL */
    if (!ss.ieltsProduct) return appRoutes.root;
    if (!state.registration.iolExam) return appRoutes.search.testDetails;
  } else {
    /* IELST in center */
    if (!ss.activeCountry) return appRoutes.search.findTest;
    if (!state.registration.inCentreExam) return appRoutes.search.bookTest;
    if (!state.bookTest.selectedExam?.awardingBodySystem) return appRoutes.search.bookTest;
  }

  return true;
}

export function validateChildState(state: RootState): true | string {
  const ss = state.searchSelect;
  const needToSupplyMissingDetails = state.candidate.profile?.needToSupplyMissingDetails;

  if (!ss.examType) return '/';
  if (!ss.activeCountry) return appRoutes.search.findTest;
  if (!state.registration.inCentreExam) return appRoutes.search.bookTest;
  if (!state.auth.isLoggedIn || needToSupplyMissingDetails)
    return appRoutes.journey.personalDetails;

  return true;
}
