import { ProductFamilies } from 'ors-api/mod';

import { getRegFlow } from './getRegFlow';

/**
 * Get ProductFamilyId based on getRegFlow
 */
export const getProductFamilyId = (): ProductFamilies => {
  switch (getRegFlow()) {
    case 'ors':
      return ProductFamilies.IELTS;
    case 'ukvi':
      return ProductFamilies.UKVI;
    /** ProductFamilies.UkviOnline and ProductFamilies.IeltsOnline
     * to be taken into consideration if they need to be
     * */
    default:
      return ProductFamilies.IELTS;
  }
};
