import { lazy } from 'react';

import { getRegFlow } from 'core';

const FindTestOrs = lazy(() => import('./ors'));
const FindTestIol = lazy(() => import('./iol'));
const FindTestUkvi = lazy(() => import('./ukvi'));
const NotFound = lazy(() => import('../../pages/NotFound'));

const FindTest = () => {
  const flow = getRegFlow();

  switch (flow) {
    case 'ors':
      return <FindTestOrs />;
    case 'iol':
      return <FindTestIol />;
    case 'ukvi':
      return <FindTestUkvi />;

    default:
      return <NotFound />;
  }
};

export default FindTest;
