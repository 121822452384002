import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { ApiError, parseApiError } from '@britishcouncil/react-common';

import { BookTestState } from '../model';
import { mergeExams } from '../helpers';
import { loadSearchResults, loadSearchResultsWithCoords } from '../api/api.mod';

export const extraReducersOrs = (builder: ActionReducerMapBuilder<BookTestState>) => {
  /** Load search results */
  builder
    .addCase(loadSearchResults.pending, (state, action) => {
      const loadingMore = action.meta.arg.more || false;

      if (loadingMore) {
        state.loadingMoreData = true;
      } else {
        state.loadingData = true;
      }

      state.loadDataError = ApiError.None;
    })
    .addCase(loadSearchResults.fulfilled, (state, action) => {
      const loadingMore = action.meta.arg.more || false;
      const payload = action.payload;

      state.lastPageCount = payload.results?.length || 0;

      if (loadingMore) {
        state.exams = mergeExams(state.exams, payload);
        state.loadingMoreData = false;
      } else {
        state.exams = payload;
        state.loadingData = false;
      }
    })
    .addCase(loadSearchResults.rejected, (state, action) => {
      const loadingMore = action.meta.arg.more || false;

      if (loadingMore) {
        state.loadingMoreData = false;
      } else {
        state.loadingData = false;
        state.exams = undefined;
        state.lastPageCount = 0;
      }

      state.loadDataError = parseApiError(action.error);
    });

  /** Load search results with coordinates */
  builder
    .addCase(loadSearchResultsWithCoords.pending, (state, action) => {
      const loadingMore = action.meta.arg.more || false;

      if (loadingMore) {
        state.loadingMoreData = true;
      } else {
        state.loadingData = true;
      }

      state.loadDataError = ApiError.None;
    })
    .addCase(loadSearchResultsWithCoords.fulfilled, (state, action) => {
      const loadingMore = action.meta.arg.more || false;
      const payload = action.payload;

      state.lastPageCount = payload.results?.length || 0;

      if (loadingMore) {
        state.exams = mergeExams(state.exams, payload);
        state.loadingMoreData = false;
      } else {
        state.exams = payload;
        state.loadingData = false;
      }
    })
    .addCase(loadSearchResultsWithCoords.rejected, (state, action) => {
      const loadingMore = action.meta.arg.more || false;

      if (loadingMore) {
        state.loadingMoreData = false;
      } else {
        state.loadingData = false;
        state.exams = undefined;
        state.lastPageCount = 0;
      }

      state.loadDataError = parseApiError(action.error);
    });
};
