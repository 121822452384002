import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { preRegister } from '../api/api.ors';
import { ReservationState } from '../models';
import { preregister } from './helpers';

export const extraReducersOrs = (builder: ActionReducerMapBuilder<ReservationState>) => {
  /* Pre-register selected exam for ORS */
  builder
    .addCase(preRegister.pending, preregister.pending)
    .addCase(preRegister.fulfilled, preregister.fullfiled)
    .addCase(preRegister.rejected, preregister.rejected);
};
