import { ApiErrorDetailed } from '@britishcouncil/react-common';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { createTestRequest } from './api';
import { OnRequestState } from './models';

const sliceName = 'onRequest';

export const initialState: OnRequestState = {
  processing: false,
};

const slice = createSlice({
  name: sliceName,
  initialState: initialState,
  reducers: {
    setProcessing(state, action: PayloadAction<boolean>) {
      state.processing = action.payload;
    },
    setProcessError(state, action: PayloadAction<ApiErrorDetailed | undefined>) {
      state.processingErr = action.payload;
      state.processing = false;
    },
    setTestRange(state, action: PayloadAction<string | undefined>) {
      state.testRange = action.payload;
    },
  },
  extraReducers: (builder) => {
    /** Save test request form */
    builder
      .addCase(createTestRequest.pending, (state) => {
        state.processing = true;
        state.processingErr = undefined;
      })
      .addCase(createTestRequest.fulfilled, (state, action) => {
        state.processing = false;
        state.addedId = action.payload;
      })
      .addCase(
        createTestRequest.rejected,
        (state, action: PayloadAction<ApiErrorDetailed | undefined>) => {
          state.processing = false;
          state.processingErr = action.payload;
        }
      );
  },
});

export default slice;
