import { useGetAllAccessibilitiesQuery } from './accessibility.service';
import { useLocale, useSelector } from 'core';

export const useGetAccessibilitiesOnLngChange = () => {
  const { locale } = useLocale();
  const { isAccessibilityInfoEnabled } = useSelector((s) => s.featureFlags.features);
  const { data, isLoading, error } = useGetAllAccessibilitiesQuery(locale, {
    skip: !isAccessibilityInfoEnabled,
  });

  return {
    allAccessibilities: data,
    isLoadingAccessibilities: isLoading,
    accessibilitiesError: error,
  };
};
