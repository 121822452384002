import { SimpleLS } from '@britishcouncil/react-common';
import { CandidateMarketingPreferences, candidateClient, McrCandidate } from 'ors-api/ors2';
import { MissingDataForm } from 'ors-ui';
import { TermsShortCodeEnum, logger } from 'ors-utils';

import { AppThunk } from '../';
import { saveCandidateMissingDetails } from '../api';
import { getGenderId } from '../personalDetails/thunks/thunks.helpers';
import slice from './candidateSlice';
import { EamUserProfile, FullCandidatePersonalDetails } from './types';
import { getTermsAndConditionsNewestVersionNumber } from 'store/personalDetails/api/api.ors';

export const loadStateFromLS = (): AppThunk => async (dispatch, getState) => {
  const isLoggedIn = getState().auth.isLoggedIn;
  const userProfileLS = SimpleLS.read<EamUserProfile>('USER_PROFILE', null);
  const profile = getState().candidate.profile;

  if (
    (!userProfileLS && isLoggedIn) ||
    (userProfileLS &&
      userProfileLS.needToSupplyMissingDetails !== !!profile?.needToSupplyMissingDetails)
  ) {
    dispatch(loadProfile());
    return;
  }
  dispatch(slice.actions.userProfileSet(userProfileLS));
};

export const saveProfile =
  (profile: FullCandidatePersonalDetails): AppThunk =>
  async (dispatch) => {
    const userProfile: EamUserProfile = {
      email: profile.email!,
      fullName: profile.firstName + ' ' + profile.surname,
      id: profile.id,
      isMinor: profile.isMinor,
      needToSupplyMissingDetails: profile.needToSupplyMissingDetails,
      needToUpdateMarketingPreferences: profile.needToUpdateMarketingPreferences,
      needToVerifyDraftProcess: profile.needToVerifyDraftProcess,
    };

    dispatch(slice.actions.userProfileSet(userProfile));
    dispatch(slice.actions.profileSet(profile));

    SimpleLS.write('USER_PROFILE', userProfile);
  };

export const loadProfile = (): AppThunk => async (dispatch, getState) => {
  const { genders } = getState().personalDetails;
  const { profile } = getState().candidate;

  // Clean old profile to avoid issues
  dispatch(slice.actions.userProfileSet(undefined));
  SimpleLS.remove('USER_PROFILE');

  const prof = await getCandidateProfile();

  if (prof?.needToSupplyMissingDetails) {
    const mcrProf = await getCandidateFromMcr();
    prof.firstName = mcrProf?.firstName;
    prof.surname = mcrProf?.lastName;
    prof.dob = mcrProf?.dateOfBirth;
  }

  prof &&
    dispatch(
      saveProfile({
        ...prof,
        genderId: getGenderId(genders, prof?.gender),
        idTypeId: prof.idTypeId ?? profile?.idTypeId,
      })
    );
};

const getCandidateProfile = async (): Promise<FullCandidatePersonalDetails | undefined> => {
  try {
    const { data: candidateDetails } = await candidateClient.getCandidateDetailsExtended();

    return {
      ...candidateDetails,
      email: candidateDetails.email || '',
      notExpires: candidateDetails.idExpiry === null,
    };
  } catch (err) {
    logger.logError(err as Error);
    return undefined;
  }
};

const getCandidateFromMcr = async (): Promise<McrCandidate | undefined> => {
  try {
    const { data: candidateDetails } = await candidateClient.getCandidateFromMCR();
    return candidateDetails;
  } catch (err) {
    logger.logError(err as Error);
    return undefined;
  }
};

export const handleMissingData =
  (val: MissingDataForm): AppThunk =>
  async (dispatch, getState) => {
    const orgData = getState().organisationCountry.organisationDetails.data;

    const accountTermsShortCode =
      orgData?.accountTermsAndConditions?.shortCode ??
      TermsShortCodeEnum.Global_IELTS_Account_Registration;

    const { payload: version } = await dispatch(
      getTermsAndConditionsNewestVersionNumber(accountTermsShortCode)
    );

    const termsAndConditionsVersion =
      typeof version === 'number' ? version : orgData?.accountTermsAndConditions?.version ?? 1;

    await dispatch(
      saveCandidateMissingDetails({
        firstName: val.firstName,
        dob: val.dateOfBirth,
        surname: val.lastName,
        marketingPreferences: CandidateMarketingPreferences.None,
        acceptedTermsAndConditionsShortCode: accountTermsShortCode,
        acceptedTermsAndConditionsVersion: termsAndConditionsVersion,
        isIeltsReadyMember: false,
      })
    );

    dispatch(loadProfile());
  };
