import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ExamCountryDto, ExamFormat, ProductsInCentre, SpecialArrangementDto } from 'ors-api/mod';
import { IELTSProducts } from 'ors-api/iol';

import { getRegFlow } from 'core';
import { converters } from 'common/converters';
import { clearInvitation } from '../_common/actions';
import { Location } from '../searchSelect';
import { ExamTypeWithFlow } from '../registration';
import {
  DatesRange,
  ExamFilterBase,
  LocationCoords,
  SearchSelectState,
  UpdateSpecialReq,
} from './models';
import { extraReducersIol, extraReducersOrs } from './extraReducers';

const sliceName = 'searchSelect';

const initApiData = {
  isLoading: false,
};

export const initialState: SearchSelectState = {
  countriesList: initApiData,
  locationsList: initApiData,
  availableDates: initApiData,
  availableIolDates: initApiData,
  iolAvailable: initApiData,

  specialReqs: [],
  fetchingSpecialReqs: false,
  needSpecialReqs: false,
  showAllDates: false,
  isDefaultFilter: true,
  acceptOrganisation: false,
  acceptSoftware: false,
  filter: { examFormat: ExamFormat.CD },
  isUkviOnRequest: false,
};

/**
 * This slice contains a persisted info about all search criteria used to search exam.
 * It's useful only to FIND a test. It should not be used in the further stages of the registration
 */
const slice = createSlice({
  name: sliceName,
  initialState: initialState,
  reducers: {
    setActiveCountry(state, action: PayloadAction<ExamCountryDto | undefined>) {
      const country = action.payload;
      state.activeCountry = country;

      if (country && !country.displayVenues) {
        state.locationCoords = undefined;
      }
    },
    setActiveLocation(state, action: PayloadAction<Location | undefined>) {
      state.activeLocation = action.payload;
    },
    showAllDatesToggled(state, action: PayloadAction<boolean>) {
      state.showAllDates = action.payload;
    },
    datesRangeChanged(state, action: PayloadAction<DatesRange | undefined>) {
      state.datesRange = action.payload;
    },
    setExamType(state, action: PayloadAction<ExamTypeWithFlow>) {
      state.examType = action.payload.examType;
      state.ieltsModule = converters.fromExamTypeCode(action.payload.examType).toIeltsModuleEnum()!;
      state.ieltsProduct = converters
        .fromExamTypeCode(action.payload.examType)
        .toIeltsProductsEnum(action.payload.flow ?? getRegFlow())!;
    },
    updateState(state, action: PayloadAction<Partial<SearchSelectState>>) {
      return {
        ...state,
        ...action.payload,
      };
    },
    setFilter(state, action: PayloadAction<ExamFilterBase>) {
      const newFilters = action.payload;
      const currentFilters = state.filter;
      state.filter = { ...currentFilters, ...newFilters };
      state.isDefaultFilter =
        !newFilters ||
        ((!newFilters.timeTableSessions || newFilters.timeTableSessions.length === 3) &&
          newFilters.examFormat === ExamFormat.CD);
    },
    resetFilter(state) {
      state.filter = { ...initialState.filter };
      state.isDefaultFilter = true;
    },
    setSpecialReqs(state, action: PayloadAction<SpecialArrangementDto[]>) {
      const selectedSpecialReqIds = state.specialReqs?.filter((r) => r.checked).map((r) => r.id);

      state.specialReqs = action.payload.map((r) => ({
        ...r,
        checked: !!selectedSpecialReqIds?.some((s) => s === r.id),
      }));

      state.needSpecialReqs = !!state.specialReqs?.some((sr) => sr.checked);
      state.fetchingSpecialReqs = false;
    },
    setFetchingSpecialReqs(state, action: PayloadAction<boolean>) {
      state.fetchingSpecialReqs = action.payload;
    },
    needSpecialReqsToggled(state, action: PayloadAction<boolean>) {
      state.needSpecialReqs = action.payload;
      // Resets selection dates when you decide to disable Special Reqs
      if (!action.payload) {
        state.datesRange = undefined;
        state.specialReqs.forEach((sr) => {
          sr.checked = false;
        });
        state.otherSpecialReqs = undefined;
      }
    },
    updateSpecialReq(state, action: PayloadAction<UpdateSpecialReq>) {
      const index = state.specialReqs?.findIndex((e) => e.id === action.payload.id) ?? -1;
      if (!state.specialReqs || index < 0) {
        return;
      }
      state.specialReqs[index].checked = action.payload.checked;
    },
    updateOtherSpecialReq(state, action: PayloadAction<string>) {
      state.otherSpecialReqs = action.payload;
    },
    lrwSessionIdChanged(state, action: PayloadAction<number[] | undefined>) {
      state.lrwSessionIds = action.payload;
    },
    setAcceptSoftware(state, action: PayloadAction<boolean>) {
      state.acceptSoftware = action.payload;
    },
    setAcceptOrganisation(state, action: PayloadAction<boolean>) {
      state.acceptOrganisation = action.payload;
    },
    setGeolocationPosition(state, action: PayloadAction<LocationCoords | undefined>) {
      state.locationCoords = action.payload;
    },
    /* Actions for UKVI flow */
    setUkviSpecificData(state, action: PayloadAction<typeof state.ukvi>) {
      state.ukvi = {
        examOptionType: action?.payload?.examOptionType,
      };
    },
    setIsUkviOnRequest(state, action: PayloadAction<boolean>) {
      state.isUkviOnRequest = action?.payload;
    },
    softReset(state) {
      state.iolAvailable = initApiData;
      state.locationsList = initApiData;
      state.availableDates = initApiData;
      state.availableIolDates = initApiData;
      state.activeLocation = undefined;
      state.datesRange = undefined;
    },
    updateCountriesList(
      state,
      action: PayloadAction<{ isLoading: boolean; data?: ExamCountryDto[] }>
    ) {
      state.countriesList = action.payload;
    },
    reset() {
      return initialState;
    },
    updateSelectedProductId(state, action: PayloadAction<IELTSProducts | ProductsInCentre>) {
      state.selectedProductId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(clearInvitation, () => initialState);
    extraReducersOrs(builder);
    extraReducersIol(builder);
  },
});

export default slice;
