import { GetCountryExamDatesRequest, ProductFamilies } from 'ors-api/mod';
import { getOrganisationAlias, hasOrganisationInUrl } from 'ors-utils';

import { appRoutes, navigateTo } from 'routing';
import { organisationCountry } from '../../organisationCountry';
import { AppThunk } from '../..';
import { loadDatesBy, loadLocationsList } from '../../api';
import { getDaysOffset } from '../helpers';
import { LoadLocationsReq } from '..';
import { searchSelectConverters } from '../sliceConverters';

/**
 * Thunks related to UKVI flow.
 */

export const loadLocations =
  (request?: LoadLocationsReq): AppThunk =>
  async (dispatch, getState) => {
    const { activeCountry, ieltsModule, ukvi } = getState().searchSelect;
    const { organisationDetails } = getState().organisationCountry;

    const organisationAlias = getOrganisationAlias();
    const fullOrgDataAvailable =
      !hasOrganisationInUrl() ||
      !organisationAlias ||
      organisationDetails.error ||
      (!!organisationAlias && !!organisationDetails?.data?.organisationId);
    const shouldLoad =
      activeCountry?.id && ieltsModule && ukvi?.examOptionType && fullOrgDataAvailable;

    if (shouldLoad) {
      await dispatch(
        loadLocationsList({
          productFamilyId: ProductFamilies.UKVI,
          countryId: activeCountry.id,
          ieltsModule: ieltsModule,
          examOptionType: ukvi?.examOptionType,
          organisationId: organisationDetails?.data?.organisationId,
        })
      );
    }
  };

export const loadAvailableDates = (): AppThunk => async (dispatch, getState) => {
  const {
    ieltsModule,
    needSpecialReqs,
    specialReqs,
    activeCountry,
    activeLocation,
    locationCoords,
    filter,
    ukvi,
    isUkviOnRequest,
  } = getState().searchSelect;
  if (isUkviOnRequest) return;

  const organisationId = organisationCountry.selectors.getOrgId(getState());
  const locationIds = activeLocation?.ids;
  const countryId = activeCountry?.id;
  const shouldGetByCountryId = !!(countryId && locationCoords);

  const days = getDaysOffset(needSpecialReqs ? specialReqs : undefined);
  const request: GetCountryExamDatesRequest = {
    daysToNearestCdExam: days.daysToNearestCdExam,
    daysToNearestPbExam: days.daysToNearestPbExam,
    ieltsModule: searchSelectConverters.ieltsModule.toExamModule(ieltsModule),
    organisationId,
    examFormat: filter?.examFormat,
    examOptionType: ukvi?.examOptionType,
  };

  if (locationIds ?? (shouldGetByCountryId && countryId)) {
    dispatch(
      loadDatesBy({
        request,
        districtIds: locationIds,
        countryId: shouldGetByCountryId ? countryId : undefined,
        productFamily: ProductFamilies.UKVI,
      })
    );
  }
};

export const proceed = (): AppThunk => async () => navigateTo(appRoutes.search.bookTest);
