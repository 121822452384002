import { AppThunk } from '../';
import { registration } from '../registration';
import { createReservationIol } from '../api.iol';

const getTime = (dateTime: string) => dateTime.split('T')[1].split('Z')[0];

export const preregisterIol = (): AppThunk => async (dispatch, getState) => {
  dispatch(registration.actions.clearRegistrationId());

  const { iolExam } = getState().registration;
  const { ieltsProduct } = getState().searchSelect;

  const req = iolExam &&
    ieltsProduct && {
      lrwSessionId: iolExam.lrwSessionId,
      speakingDayId: iolExam.speakingDayId,
      speakingStartTimeUtc: getTime(iolExam.speakingStartDateTimeUtc),
      speakingEndTimeUtc: getTime(iolExam.speakingEndDateTimeUtc),
      productId: ieltsProduct,
    };

  req && (await dispatch(createReservationIol(req)));
};
