import { specialArrangementClient, SpecialArrangementDto } from 'ors-api/mod';

import { logger } from 'ors-utils';
import { api, BaseTranslatedQueryParams } from 'store/shared';
import { searchSelect } from '.';

const searchSelectApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getSpecialArrangements: builder.query<SpecialArrangementDto[], BaseTranslatedQueryParams>({
      query: () => {
        return specialArrangementClient.getSpecialArrangements();
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        dispatch(searchSelect.actions.setFetchingSpecialReqs(true));
        try {
          const { data } = await queryFulfilled;
          dispatch(searchSelect.actions.setSpecialReqs(data));
        } catch (error) {
          logger.logError(error as Error);
          dispatch(searchSelect.actions.setFetchingSpecialReqs(false));
        }
      },
    }),
  }),
});

export const { useGetSpecialArrangementsQuery } = searchSelectApi;
