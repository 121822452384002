import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  districtExamsClient as districtExamsClientMod,
  GetDistrictExamsRequest,
  GetDistrictExamsByCoordsRequest,
  ProductFamilies,
} from 'ors-api/mod';
import { getRegFlow } from 'core';

const apiClient = 'api-mod';

export const loadSearchResults = createAsyncThunk(
  apiClient + '/getDistrictExams',
  async ({ req }: SearchParams<GetDistrictExamsRequest>) => {
    const isUkvi = getRegFlow() === 'ukvi';
    const productFamily = isUkvi ? ProductFamilies.UKVI : ProductFamilies.IELTS;
    const response = await districtExamsClientMod.getExamDistricts(productFamily, req);
    return response.data;
  }
);

export const loadSearchResultsWithCoords = createAsyncThunk(
  apiClient + '/getDistrictExamsByCoords',
  async ({ req }: SearchParams<GetDistrictExamsByCoordsRequest>) => {
    const isUkvi = getRegFlow() === 'ukvi';
    const productFamily = isUkvi ? ProductFamilies.UKVI : ProductFamilies.IELTS;
    const response = await districtExamsClientMod.getExamDistrictsCoords(productFamily, req);
    return response.data;
  }
);

export interface SearchParams<T> {
  req: T;
  more?: boolean;
  withCoords?: boolean;
}
