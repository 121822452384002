import { CountryDto, MarketingQuestionDto } from 'ors-api/mod';
import { ApiErrorsList } from 'ors-ui';

export interface StorageDataSlice {
  /** List of allowed countries */
  countriesMod: DataStatus & { data?: CountryDto[] };
}

export type DataStatus = {
  storageKey: string;
  status: Status;
  error?: ApiErrorsList;
};

export enum Status {
  Idle,
  Pending,
  Error,
}
