import { appRoutes, navigateTo } from 'routing';
import { AppThunk } from '..';
import { PaymentMode } from './models';

export const changeLastPageInHistory = (url: string) => {
  const fullUrl = window.location.origin + (url[0] !== '/' ? '/' : '') + url;
  window.history.replaceState(document.title, fullUrl);
  window.history.pushState(null, document.title, fullUrl);
};

const OFFLINE_PAYMENT_ID = '0';
export const navigateToConfirmPage =
  (type: PaymentMode, regId: string | number, paymentId = OFFLINE_PAYMENT_ID): AppThunk =>
  async () => {
    navigateTo(
      appRoutes.journey.bookingComplete
        .replace(':registrationId', regId.toString())
        .replace(':type', type)
        .replace(':paymentId', paymentId),
      { method: 'replace' }
    );
  };
