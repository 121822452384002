import slice, { initialState } from './bookTestSlice';
import * as thunks from './thunks/thunks.common';
import * as thunksIol from './thunks/thunks.iol';
import * as thunksOrs from './thunks/thunks.ors';
import * as bookExamThunks from './thunks/thunks.bookExam';
import * as selectors from './selectors';

export * from './model';
export * from './routeValidation';
export const bookTest = {
  ...slice,
  initialState,
  thunks: { ...thunks, ...bookExamThunks },
  thunksIol,
  thunksOrs,
  selectors,
};
