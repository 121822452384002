import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { Modal } from 'ors-ui';

import { GTM, useSelector } from 'core';
import { useDispatch } from 'core/hooks/useDispatch';
import { reservation } from 'store/reservation';
import { appRoutes, useNavigateTo } from 'routing';
import { registration } from 'store/registration';

export const ExamTakenModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigateTo();

  const { showExamTakenModal } = useSelector((state) => state.reservation);

  const onConfirm = useCallback(() => {
    dispatch(reservation.actions.clear());
    dispatch(registration.actions.clear());
    navigate(appRoutes.search.findTest);
  }, [dispatch, navigate]);

  return (
    <Modal
      show={showExamTakenModal ?? false}
      onExit={() => null}
      onConfirm={onConfirm}
      title={t('APPB2C.cj.personalDetails.examTakenModal.title')}
      confirmLabel={t('APPB2C.cj.personalDetails.examTakenModal.cta')}
      intent="warning"
      modalProps={{
        alert: true,
        escapeExits: false,
      }}
      showHideLink={false}
      onHide={() => GTM.trackModal('Hide', 'exam-taken-modal')}
      onShow={() => GTM.trackModal('Show', 'exam-taken-modal')}
    >
      <ModalContent>{t('APPB2C.cj.personalDetails.examTakenModal.message')}</ModalContent>
    </Modal>
  );
};

const ModalContent = styled.p`
  max-width: 700px;
`;
