import { createSelector } from '@reduxjs/toolkit';
import { DateTime } from 'luxon';
import { IELTSProducts } from 'ors-api/iol';
import { ExamOptionType } from 'ors-api/mod';
import { localeFns, examTypeDictionary } from 'ors-utils';

import { DatesRange } from '.';
import { RootState } from '../';
import { getRegFlow } from 'core';

export const getHumanReadableDatesRange = createSelector(
  (state: RootState) => state.searchSelect.datesRange,
  (state: RootState) => state.language.currentLanguage,
  (range, locale) => (!range || range.length < 2 ? null : formatDates(range, locale))
);

export const getFilters = (state: RootState) => state.searchSelect.filter;

export const getIeltsModule = (state: RootState) => state.searchSelect.ieltsModule;

function formatDates(dates: [string, string], locale?: string) {
  return dates.map((e) => localeFns(locale).localeDate(e)).join(' - ');
}

export const isCdOnly = (state: RootState) => {
  /* IDs of countries, that offer CD exams only. */
  const cdOnlyCountries = [42];
  return cdOnlyCountries.includes(state.searchSelect.activeCountry?.id ?? 0);
};

export const isUolContext = (state: RootState) =>
  (state.searchSelect.ieltsProduct === IELTSProducts.UkviOnlineAC && isIol()) ||
  (state.searchSelect.ieltsProduct === IELTSProducts.UkviOnlineGT && isIol());

export const getExamTypeLongName = (state: RootState) => {
  const { examType, ukvi } = state.searchSelect;
  const flow = getRegFlow();
  const isUol = isUolContext(state);

  if (!examType) return '';

  /* Get exam type for UKVI (including Life Skills) */
  const typeUkvi = () => {
    if (flow !== 'ukvi') return undefined;

    /* When Life Skills is NOT selected, then just get "examType" value. */
    if (
      !ukvi ||
      ukvi.examOptionType === ExamOptionType.IELTSUKVILRW ||
      ukvi.examOptionType === ExamOptionType.IELTSCDUKVILRW
    )
      return examType;

    /* Get Life Skills exam code */
    switch (true) {
      case ukvi?.examOptionType === ExamOptionType.LifeSkillsA1:
        return 'lfska1';
      case ukvi?.examOptionType === ExamOptionType.LifeSkillsA2:
        return 'lfska2';
      case ukvi?.examOptionType === ExamOptionType.LifeSkillsB1:
        return 'lfskb1';
      default:
        return undefined;
    }
  };

  const examTypeUkvi = typeUkvi();

  if (flow === 'ors' || flow === 'iol') {
    return examTypeDictionary()[isUol ? 'uol' : flow][examType];
  }

  if (flow === 'ukvi' && examTypeUkvi) {
    return examTypeDictionary().ukvi[examTypeUkvi];
  }

  /* Default and when not in particular flow */
  return examTypeDictionary().ors[examType];
};

export const isIol = () => getRegFlow() === 'iol';
export const isUkvi = () => getRegFlow() === 'ukvi';
export const isOrs = () => getRegFlow() === 'ors';

export const isChosenSpecialReqs = createSelector(
  (state: RootState) => state.searchSelect,
  (search) =>
    search.needSpecialReqs &&
    search.specialReqs &&
    search.specialReqs?.filter((sr) => sr.checked).length > 0
);

export function anyTestsInside(
  examDates?: string[],
  selection?: DatesRange | [Date, Date]
): boolean {
  if (!selection || !examDates || examDates.length === 0 || selection.length !== 2) {
    return false;
  }

  const avDates = examDates.map((e) => DateTime.fromISO(e).toJSDate());
  const from =
    selection[0] instanceof Date
      ? DateTime.fromJSDate(selection[0]).startOf('day').toJSDate()
      : DateTime.fromISO(selection[0]).startOf('day').toJSDate();
  const to =
    selection[1] instanceof Date
      ? DateTime.fromJSDate(selection[1]).endOf('day').toJSDate()
      : DateTime.fromISO(selection[1]).endOf('day').toJSDate();

  return avDates.some((e) => (selection[1] ? from <= e && to >= e : from <= e));
}

export const isSearchButtonEnabled = createSelector(
  (state: RootState) => state.searchSelect,
  (search) => {
    const selAnotherSR = search.specialReqs.find((sr) => sr.shortCode === 'ANTHR' && sr.checked);
    return (
      (!search.needSpecialReqs ||
        !search.specialReqs ||
        search.specialReqs?.filter((sr) => sr.checked).length > 0) &&
      (!selAnotherSR || (search.otherSpecialReqs && search.otherSpecialReqs.length > 0)) &&
      (search.showAllDates || anyTestsInside(search.availableDates.data, search.datesRange))
    );
  }
);

export const getShowSpecialNeeds = createSelector(
  (state: RootState) => state,
  (state) =>
    state.searchSelect.activeCountry?.specialNeeds &&
    (state.searchSelect.activeLocation || state.searchSelect.locationCoords) &&
    state.searchSelect.specialReqs.some((r) => r)
);

export const isUkviLifeSkills = (state: RootState) => {
  const examOptionType = state.searchSelect.ukvi?.examOptionType;

  if (getRegFlow() !== 'ukvi') return false;

  return examOptionType
    ? [
        ExamOptionType.LifeSkillsA1,
        ExamOptionType.LifeSkillsA2,
        ExamOptionType.LifeSkillsB1,
      ].includes(examOptionType)
    : false;
};

export const noExamsAvailableInSelectedDates = (sessions: string[]) => (state: RootState) => {
  const { datesRange } = state.searchSelect;
  return datesRange && !anyTestsInside(sessions, [datesRange?.[0], datesRange?.[1]]);
};
