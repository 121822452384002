import { useEffect } from 'react';
import { useLocation, useMatch } from 'react-router-dom';
import { isNewTransaction, recordRegId } from '../RegistrationLS';
import { appRoutes, routeWithFlow } from 'routing';
import { updateDataLayer, getStoredData } from './GtmManager';
import { AdditionalPaymentData, DataLayerB2C, FullRegistrationDataLayer } from './models';
import { dataLayerJson, updateDataLayerJson } from './dataLayerJson';
import { GoogleTagManager } from './googleTagManager';
import { getGA4PurchaseEvent, ICountryDataLayer, ITrackCommerceEvent } from 'ors-utils';

const delayedPages = ['start-page', 'find-test'];

/**
 * Fun facts: GTM Events are pushed only to dataLayer and are not persisted further.
 * Whereas update method does push data to dataLayer and persist it
 **/
export const GTM = {
  update(value: Partial<FullRegistrationDataLayer>) {
    updateDataLayer(value);
    updateDataLayerJson(value);
  },
  updateAbTests(value: any) {
    const val = value ?? {};
    const abExperiment = Object.keys(val)
      .map((k) => k + '=' + val[k])
      .join(',');

    const abTestProps: Partial<FullRegistrationDataLayer> = {
      AB_test1: '',
      AB_test2: '',
      AB_test3: '',
      AB_test4: '',
    };
    Object.keys(val).forEach((k, i) => {
      const prop = `AB_test${i + 1}`;
      if (prop in abTestProps) {
        updateDataLayer({ [prop]: val[k] });
        updateDataLayerJson({ [prop]: val[k] });
      }
    });
    updateDataLayer({ abExperiment });
    updateDataLayerJson({ abExperiment });
  },
  updateCountry(value: Partial<ICountryDataLayer>) {
    updateDataLayer(value);
    updateDataLayerJson(value);
  },
  /** Track event which also should update persisted Data Layer */
  trackRegistrationEvent(eventName: string, value?: Partial<FullRegistrationDataLayer>) {
    GoogleTagManager.updateDataLayer({
      event: eventName,
    });

    if (value) {
      updateDataLayer(value);
    }

    const dataLayerB2C: DataLayerB2C = {
      event: JSON.stringify({
        event: eventName,
        ...value,
      }),
    };

    dataLayerJson?.push(dataLayerB2C);
  },
  /** Track events not related with registration data layer, e.g. A/B Tests */
  trackAnyEvent(eventName: string, value?: Object) {
    GoogleTagManager.updateDataLayer({
      event: eventName,
      ...value,
    });

    const dataLayerB2C: DataLayerB2C = {
      event: JSON.stringify({
        event: eventName,
        ...value,
      }),
    };

    dataLayerJson?.push(dataLayerB2C);
  },
  trackTransSuccess(event: ITrackCommerceEvent) {
    if (isNewTransaction(event.refNumber)) {
      recordRegId(event.refNumber);

      const purchaseData = getGA4PurchaseEvent(event);

      window.gtag('event', 'purchase', purchaseData);
      updateDataLayerJson(purchaseData);
    }
  },
  trackTransFailure(event: ITrackCommerceEvent) {
    const purchaseData = getGA4PurchaseEvent(event);

    window.gtag('event', 'purchase', purchaseData);
    updateDataLayerJson(purchaseData);
  },
  trackBookingCompletePageView(
    url: string,
    name?: string,
    additionalData?: Partial<AdditionalPaymentData>
  ) {
    const data = { status: '', ...additionalData };
    const autoTranslated = isPageAutoTranslated();

    const dlData = {
      event: 'VirtualPageview',
      virtualPageURL: url,
      virtualPageTitle: name || '',
      autoTranslated: autoTranslated ? 'yes' : '',
      ...getStoredData(),
      ...data,
    };

    GoogleTagManager.updateDataLayer(dlData);
    updateDataLayerJson(dlData);
  },
  trackPageView(url: string, name?: string) {
    const autoTranslated = isPageAutoTranslated();

    const data = {
      event: 'VirtualPageview',
      virtualPageURL: url,
      virtualPageTitle: name || '',
      autoTranslated: autoTranslated ? 'yes' : '',
      ...getStoredData(),
    };

    GoogleTagManager.updateDataLayer(data);
    updateDataLayerJson(data);
  },
  trackModal(action: 'Show' | 'Hide', modalName: string) {
    const data = {
      event: 'modal',
      eventCategory: 'Modal',
      eventAction: action,
      eventLabel: modalName,
    };

    GoogleTagManager.updateDataLayer(data);
    updateDataLayerJson(data);
  },
  trackError(label: string, action?: string) {
    const data = {
      event: 'error',
      eventCategory: 'IELTS',
      eventAction: `${action ?? getPageName()} - error`,
      eventLabel: label,
    };

    GoogleTagManager.updateDataLayer(data);
    updateDataLayerJson(data);
  },
};

/**
 * Set up a global hook that will emit page change event to Google Analytics
 */
export function usePageViewsInGA() {
  let location = useLocation();
  const isBookingRoute = useMatch(routeWithFlow(appRoutes.journey.bookingComplete));

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    /**
     * It's like this because we want to have a full pathname with our VirtualDirectory
     * It should not be called on bookingComplete route as this page handles it on its own
     */
    const url = window.location.pathname;
    let name = url.split('/').pop();
    if (location.pathname === '/') {
      name = 'start-page';
    }

    // WebAnalytics requested such thing - to trigger VirtualPageviews of the initial pages
    // With a small delay, so that other data events will appear before pageview one.
    const delay = delayedPages.indexOf(name || '') > -1 ? 1000 : 100;
    if (!isBookingRoute) {
      timeout = setTimeout(() => GTM.trackPageView(url, name), delay);
    }

    return () => {
      timeout && clearTimeout(timeout);
    };
  }, [location, isBookingRoute]);
}

export enum ExamFormat {
  PB = 1,
  CD = 2,
}

export interface IPageNavigationEvent {
  countryTagCode: string;
  event: string;
  virtualPageTitle?: string;
  virtualPageURL?: string;
}

/**
 * Get Page name from current route, e.g. "/orsnbc/find-test" => "Find test"
 */
const getPageName = () => {
  const route = window.location.pathname;
  if (route === '/') {
    return 'Start page';
  }

  const routesToAvoid = [''];
  const filtered = route
    .split('/')
    .filter((s) => !routesToAvoid.includes(s) && !Number(s))
    .map((s) => s.replace('-', ' '))
    .join(' ');

  return filtered.charAt(0).toUpperCase() + filtered.slice(1);
};

function isPageAutoTranslated(): boolean {
  const googleTranslated = !!document.documentElement.className.match('translated');
  let edgeTranslated = false;
  const titleEl = document.getElementsByTagName('title')[0];
  if (titleEl) {
    edgeTranslated = !!titleEl.attributes.getNamedItem('_msthash');
  }
  return googleTranslated || edgeTranslated;
}
