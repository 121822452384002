import { PayloadAction } from '@reduxjs/toolkit';
import { DateTime } from 'luxon';
import { ApiErrorsList } from 'ors-ui';
import { ReservationState } from '../models';
import { ReservationResult } from 'ors-api/mod';

export const EXAM_NOT_AVAILABLE_CODES = [
  'ClashedWithActiveRegistration',
  'UnableToFindSpeakingSlot',
  'NoAvailableSpeakingSlots',
];
export const EXAM_NOT_AVAILABLE_MESSAGE =
  'Speaking capacity has been exhausted for the selected time';

const NOTIFICATION_NEAREND_MINUTES = 5;
const DEFAULT_EXPIRY_MINUTES = 25;

/* Common async thunk helpers as we handle ORS and UKVI preregister api calls exactly the same. */
export const preregister = {
  pending: (state: ReservationState) => {
    state.processing = true;
    state.processingErr = undefined;
  },
  fullfiled: (state: ReservationState, action: PayloadAction<ReservationResult>) => {
    const curTime = DateTime.local();
    const expiryMinutes = action.payload.expiryMinutes
      ? action.payload.expiryMinutes
      : DEFAULT_EXPIRY_MINUTES;
    const deadline = curTime.plus({ minutes: expiryMinutes });

    state.expiryMinutes = expiryMinutes;
    state.reservationGuid = action.payload.guid;
    state.timerActive = true;
    state.deadline = deadline.toISO();
    state.nearEndTime = deadline.minus({ minutes: NOTIFICATION_NEAREND_MINUTES }).toISO();
    state.processing = false;
    state.examMode = 'IC';
  },
  rejected: (state: ReservationState, action: PayloadAction<ApiErrorsList | undefined>) => {
    state.processing = false;

    /** Show modal when IC exam is no longer available: */
    if (
      EXAM_NOT_AVAILABLE_CODES.some((code) =>
        action.payload?.errors?.some((err) => err.code === code)
      ) ||
      action.payload?.errors?.some((err) => err.message?.includes(EXAM_NOT_AVAILABLE_MESSAGE))
    ) {
      state.showExamTakenModal = true;
      return;
    }

    /** Otherwise treat it as general error: */
    state.processingErr = action.payload;
  },
};
