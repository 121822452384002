import { Button } from '@britishcouncil/react-solas';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

import { useAuthSlice } from 'store/authSlice';

export const ModalFooter = ({ onCancel }: { onCancel: () => void }) => {
  const { t } = useTranslation();
  const { handleLogOut } = useAuthSlice();

  const onConfirm = () => handleLogOut();

  return (
    <FooterWrapper>
      <Button intent="default" onClick={onCancel} data-testid="cancelLogout">
        {t('APPB2C.common.basic.cancel')}
      </Button>
      <Button intent="danger" onClick={onConfirm} data-testid="confirmLogout">
        {t('APPB2C.common.nav.logoutModal.confirm')}
      </Button>
    </FooterWrapper>
  );
};

const FooterWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;

  &&& button {
    margin-left: 5px;
    margin-right: 5px;
  }
`;
