import slice, { initialState } from './registrationSlice';
import * as thunks from './thunks';
import * as thunksIol from './thunks.iol';
import * as selectors from './selectors';

export * from './models';

export const registration = {
  ...slice,
  thunks,
  thunksIol,
  selectors,
  initialState,
};
