import { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';

import { appRoutes } from '../appRoutes';

const TermsPage = lazy(() => import('../../pages/Terms'));

export const TermsRoutes = () => (
  <Routes>
    {/** React Router V6 no longer supports optional parameters, using separate routes with a single component is advised */}
    <Route path={appRoutes.terms.shortCode} element={<TermsPage />} />
    <Route path={appRoutes.terms.organisationCountryId} element={<TermsPage />} />
    <Route path={appRoutes.terms.version} element={<TermsPage />} />
  </Routes>
);
