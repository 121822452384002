import { ApiErrorDetailed, extractApiError } from '@britishcouncil/react-common';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  candidateClient,
  candidateDocumentClient,
  CandidateIDDetails,
  CandidateIDDetailsExtended,
  RegistrationDocumentType,
} from 'ors-api/ors2';
import { RootState } from '../..';
import { logger } from 'ors-utils';
import { extractApiErrors, ApiErrorsList } from 'ors-ui';

export const saveCandidateIdDetails = createAsyncThunk<
  CandidateIDDetails,
  CandidateIDDetailsExtended,
  { state: RootState; rejectValue: ApiErrorsList }
>('api-ors2/saveCandidateIdDetails', async (req, { rejectWithValue }) => {
  try {
    const response = await candidateClient.saveCandidateIDDetails(req);
    return response.data;
  } catch (error: any) {
    logger.logError(error);
    const err = extractApiErrors(error);
    return rejectWithValue(err);
  }
});

/* Thunks to handle file upload/download */

export const verifyDocuments = createAsyncThunk<void, number, { rejectValue: ApiErrorDetailed }>(
  'api-ors2/verifyDocuments',
  async (candidateId, { rejectWithValue }) => {
    try {
      const response = await candidateDocumentClient.verifyDocuments(
        candidateId,
        RegistrationDocumentType.IdDocument
      );
      return response.data;
    } catch (error) {
      const err = extractApiError(error);
      return rejectWithValue(err);
    }
  }
);

export const deleteAllIdFiles = createAsyncThunk<any, number, { rejectValue: ApiErrorsList }>(
  'api-ors2/deleteAllIdFiles',
  async (candidateId, { rejectWithValue }) => {
    try {
      const response = await candidateDocumentClient.deleteAll(
        candidateId,
        RegistrationDocumentType.IdDocument
      );
      return response.data;
    } catch (error) {
      const err = extractApiErrors(error);
      return rejectWithValue(err);
    }
  }
);
