import { converters as sharedConverters } from 'ors-utils';

import { errors } from './apiErrors/apiErrorConverters';

// Add new CJ specific converters here when necessary

export const converters = {
  ...sharedConverters,
  ...errors,
};
