import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  CreateRegistrationFromReservationRequest,
  registrationClient,
  RegistrationCreatedDto,
} from 'ors-api/iol';
import { extractApiErrors } from 'ors-ui';

import { logger } from 'ors-utils';
import { RootState } from '..';
import { ApiErrorsListWithIolRegistrationRequest } from './types';

/** Create registration for IOL exam */
export const createRegistrationIol = createAsyncThunk<
  RegistrationCreatedDto,
  CreateRegistrationFromReservationRequest,
  {
    state: RootState;
    rejectValue: ApiErrorsListWithIolRegistrationRequest;
  }
>('api-iol/createRegistrationIol', async (req, { rejectWithValue }) => {
  try {
    const response = await registrationClient.create(req);
    return response.data;
  } catch (error) {
    logger.logError(error as Error);
    const err = extractApiErrors(error);
    return rejectWithValue({ error: err, req });
  }
});
