import slice, { initialState } from './invitationSlice';
import * as thunks from './thunks';
import * as selectors from './selectors';

export * from './models';

export const invitation = {
  ...slice,
  thunks,
  initialState,
  selectors,
};
