import { FC } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import styled from '@emotion/styled';
import { FormFields } from '@britishcouncil/react-forms';
import {
  LastNameField,
  ReadonlyField,
  TextField,
  LockIcon,
} from '@britishcouncil/react-solas-ors3';
import { AZURE_B2C_ACCOUNT_IDP, formatSurname } from 'ors-utils';
import { DateField, ExternalLink } from 'ors-ui';

import { PersonalDetailsForm } from 'store/personalDetails';
import { FullCandidatePersonalDetails } from 'store/candidate';
import { useLocale, useSelector } from 'core';
import { GenderField } from './GenderField';

interface Props {
  isLoggedIn: boolean;
  fields: FormFields<PersonalDetailsForm>;
  candidateProfile?: FullCandidatePersonalDetails;
  isMinorAndIol: boolean;
  showEditForm: boolean;
}

export const AboutYouFields: FC<Props> = ({
  isLoggedIn,
  fields,
  candidateProfile,
  isMinorAndIol,
  showEditForm,
}) => {
  const { t } = useTranslation();
  const { localeDate } = useLocale();

  const { allowEdit } = useSelector((state) => state.personalDetails);
  const inChangeMode = useSelector((state) => state.registration.changeMode);
  const isFirstNameSurnameOrder = useSelector(
    (state) => state.searchSelect?.activeCountry?.isFirstNameSurnameOrder
  );
  const { idp } = useSelector((state) => state.auth);
  const canEdit = showEditForm || (allowEdit && inChangeMode);
  const showSubnote = fields.forWhom.value === 'myself';
  const subnote = showSubnote ? t('APPB2C.cj.personalDetails.forms.personal.idMatch') : undefined;

  if (!isLoggedIn || canEdit) {
    return (
      <>
        {isFirstNameSurnameOrder && (
          <TextField
            label={
              fields.forWhom.value === 'myself'
                ? t('APPB2C.cj.personalDetails.forms.personal.firstMiddleName')
                : t('APPB2C.cj.personalDetails.forms.personal.firstName')
            }
            subnote={subnote}
            {...fields.firstName}
          />
        )}
        <LastNameField
          label={t('APPB2C.cj.personalDetails.forms.personal.lastName')}
          noLastNameLabel={t('APPB2C.cj.personalDetails.forms.personal.noLastName')}
          subnote={subnote}
          {...fields.lastName}
        />
        {!isFirstNameSurnameOrder && (
          <TextField
            label={
              fields.forWhom.value === 'myself'
                ? t('APPB2C.cj.personalDetails.forms.personal.firstMiddleName')
                : t('APPB2C.cj.personalDetails.forms.personal.firstName')
            }
            subnote={subnote}
            {...fields.firstName}
          />
        )}

        <DateField
          label={t('APPB2C.cj.personalDetails.forms.personal.dob')}
          mode="dob"
          {...fields.dateOfBirth}
        />
        {!isMinorAndIol && <GenderField {...fields.gender} />}
        {canEdit && !candidateProfile?.needToSupplyMissingDetails && (
          <>
            <Label>{t('APPB2C.cj.personalDetails.forms.contact.email')}:</Label>
            <Email>
              <div>
                <LockIcon />
              </div>
              {fields.email.value}
            </Email>
            {idp !== AZURE_B2C_ACCOUNT_IDP ? (
              <p className="small">
                <Trans t={t} i18nKey="APPB2C.cj.personalDetails.login.emailNotEditable">
                  You can’t edit your email address now that you’ve created an account. To change
                  this, please
                  <ExternalLink href="https://takeielts.britishcouncil.org/contact">
                    contact us
                  </ExternalLink>
                </Trans>
              </p>
            ) : (
              <br />
            )}
          </>
        )}
      </>
    );
  }

  return (
    <>
      <ReadonlyField label={t('APPB2C.cj.personalDetails.forms.personal.name')}>
        {fields.firstName.value} {formatSurname(fields.lastName.value)}
      </ReadonlyField>
      <ReadonlyField label={t('APPB2C.cj.personalDetails.forms.personal.dob')}>
        {candidateProfile?.dob ? localeDate(candidateProfile?.dob) : '-'}
      </ReadonlyField>
      <ReadonlyField label={t('APPB2C.cj.personalDetails.forms.contact.email')}>
        {fields.email.value}
      </ReadonlyField>
      {/* Show Gender section only when user logged in and does not have this set up for their
          candidate account */}
      {!candidateProfile?.gender?.trim() && <GenderField {...fields.gender} />}
    </>
  );
};

const Label = styled.div`
  font-size: 1.11em;
  margin-bottom: 7px;
`;

const Email = styled.div`
  margin-bottom: 10px;

  > div {
    display: inline-block;
    width: 47px;
    height: 47px;
    margin-right: 20px;
    padding: 11px;
    text-align: center;
    vertical-align: middle;
    background-color: white;
    border-radius: 50%;
  }
`;
