import { Navigate, useParams } from 'react-router-dom';
import { getOrganisationAlias, hasOrganisationInUrl } from 'ors-utils';

import { routeWithBase } from '../navigation';
import { appRoutes } from '../appRoutes';
import { getRegFlow } from 'core';

export const TestChooserComp = () => {
  const { country } = useParams();
  const ctx = getRegFlow();
  let url = appRoutes.root;

  if (ctx === 'ukvi') {
    url = `${url}ukvi`;
  }

  url = `${url}?`;

  if (country) {
    url = `${url}country=${country}&`;
  }

  return <Navigate to={routeWithBase(url)} />;
};

export const LandingPageComp = () => {
  const { organisation, country } = useParams();
  const ctx = getRegFlow();
  let url = appRoutes.root;

  if (ctx === 'ukvi') {
    url = `${url}ukvi`;
  }

  url = `${url}?`;

  if (country) {
    url = `${url}country=${country}&`;
  }

  if (organisation) {
    url = `${url}organisation=${organisation}&`;
  } else if (hasOrganisationInUrl()) {
    url = `${url}organisation=${getOrganisationAlias()}&`;
  }

  return <Navigate to={routeWithBase(url)} />;
};

export const TestAndResultsComp = () => {
  let url = `/ttp`;

  window.location.href = url;

  return <Navigate to={routeWithBase(url)} />;
};

export const TestDetailsComp = () => {
  let url = `/ttp/ors${window.location.pathname}${window.location.search}`;

  window.location.href = url;

  return <Navigate to={routeWithBase(url)} />;
};

export const MyAccountComp = () => {
  let url = `/ttp${window.location.pathname}${window.location.search}`;

  window.location.href = url;

  return <Navigate to={routeWithBase(url)} />;
};

export const RebookComp = () => {
  const { regId } = useParams();
  let url = `ttp/ors/test-details/${regId}/results${window.location.search}`;

  window.location.href = url;

  return <Navigate to={routeWithBase(url)} />;
};
