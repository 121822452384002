import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApiErrorsList } from 'ors-ui';

import { calculateProductPrice, getTaxedFee } from '../api';
import { globalLogOut, globalCleanRegistration, clearErrors } from '../_common/actions';
import { PaymentState, PaymentStatus, PromoCodeState } from './models';

export const sliceName = 'payment';

export const initialState: PaymentState = {
  processing: false,
  error: undefined,
  promoCodeState: PromoCodeState.UNUSED,
  isOnline: undefined,
  paymentStatus: PaymentStatus.IDLE,
  discount: undefined,
};

const slice = createSlice({
  name: sliceName,
  initialState: initialState,
  reducers: {
    startProcessing(state, action) {
      state.processing = true;
      state.error = initialState.error;
      state.isOnline = action.payload.isOnline;
      state.paymentStatus = PaymentStatus.STARTED;
    },
    setRedirectUrl(state, action: PayloadAction<string | undefined>) {
      state.redirectUrl = action.payload;
    },
    setPaymentId(state, action: PayloadAction<string | undefined>) {
      state.paymentId = action.payload;
    },
    processingDone(state) {
      state.processing = false;
      state.error = initialState.error;
      state.paymentStatus = PaymentStatus.SUCCESS;
    },
    processingFailed(state, action: PayloadAction<ApiErrorsList | undefined>) {
      state.processing = false;
      state.error = action.payload;
      state.paymentStatus = PaymentStatus.FAILED;
    },
    clearDiscount(state) {
      state.discount = initialState.discount;
      state.promoCodeState = initialState.promoCodeState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(globalLogOut, () => initialState);
    builder.addCase(globalCleanRegistration, (state) => {
      return state.paymentStatus === PaymentStatus.SUCCESS
        ? initialState
        : { ...initialState, redirectUrl: state.redirectUrl };
    });
    builder.addCase(clearErrors, (state) => {
      state.error = undefined;
    });

    /* Calculate product price with promo codes */
    builder.addCase(calculateProductPrice.pending, (state, action) => {
      state.processing = true;
      state.promoCodeState = initialState.promoCodeState;
      state.discount = undefined;
    });
    builder.addCase(calculateProductPrice.fulfilled, (state, action) => {
      state.processing = false;
      state.promoCodeState = PromoCodeState.VALID;
      state.discount = action.payload as any;
    });
    builder.addCase(calculateProductPrice.rejected, (state, action) => {
      state.processing = false;
      state.promoCodeState =
        action.payload === 400 ? PromoCodeState.INVALID : PromoCodeState.UNKNOWN;
    });

    /* Calculate taxed fee */
    builder.addCase(getTaxedFee.pending, (state, action) => {
      state.processing = true;
      state.promoCodeState = initialState.promoCodeState;
      state.discount = undefined;
    });
    builder.addCase(getTaxedFee.fulfilled, (state, action) => {
      state.processing = false;
      state.taxedFee = action.payload;
    });
    builder.addCase(
      getTaxedFee.rejected,
      (state, action: PayloadAction<ApiErrorsList | undefined>) => {
        state.processing = false;
        state.error = action.payload;
      }
    );
  },
});

export default slice;
