import { ApiErrorDetailed, extractApiError } from '@britishcouncil/react-common';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { CreateTestRequest, ukviOnRequestsClient } from 'ors-api/mod';
import { logger } from 'ors-utils';

import { RootState } from '..';

export const createTestRequest = createAsyncThunk<
  any,
  CreateTestRequest,
  { state: RootState; rejectValue: ApiErrorDetailed }
>('api-ors2/createTestRequest', async (req, { rejectWithValue }) => {
  try {
    const response = await ukviOnRequestsClient.addTestRequest(req);
    return response.data;
  } catch (error: any) {
    logger.logError(error);
    const err = extractApiError(error);
    return rejectWithValue(err);
  }
});
