import { DateTime } from 'luxon';
import { useCallback, useEffect, useState } from 'react';

import { getRegFlow, useSelector } from 'core';
import {
  candidatesClient as candidatesClientIol,
  ExistingRegistrationDto as ExistingRegistrationDtoIol,
} from 'ors-api/iol';
import {
  candidatesClient as candidatesClientMod,
  ExistingRegistrationDto as ExistingRegistrationDtoMod,
  AvailableDistrictExamDto,
} from 'ors-api/mod';

const formatDate = (date?: string): string =>
  (date && DateTime.fromISO(date).toFormat('yyyy-MM-dd')) || '';

export const useExistingRegistrations = (
  inChangeModeExamToCheck?: AvailableDistrictExamDto,
  handleBook?: (exam: AvailableDistrictExamDto) => void,
  handleBookIol?: () => void,
  iolExamDateToCheck?: string
) => {
  const [existingRegDataIol, setExistingRegDataIol] = useState<ExistingRegistrationDtoIol>();
  const [existingRegDataMod, setExistingRegDataMod] = useState<ExistingRegistrationDtoMod>();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isRegistrationInLockoutPeriod, setIsRegistrationInLockoutPeriod] = useState(false);

  const { inCentreExam, changeMode } = useSelector((state) => state.registration);
  const iolExam = useSelector((state) => state.bookTest.examsIol.data);
  const { profile, childId, forWhom } = useSelector((state) => state.candidate);

  const regFlow = getRegFlow();
  const examDateMod = formatDate(inCentreExam?.examDate);
  const examDateIol = !!iolExam?.length && formatDate(iolExam[0].lrwStartDateTimeUtc);
  const inChangeModeExamDate = inChangeModeExamToCheck?.examDate || iolExamDateToCheck;
  const candidateId = childId || profile?.id;
  const isNewMinor = !childId && forWhom === 'someone-else';

  const isUkviLifeSkills = existingRegDataMod?.details?.productId?.includes('LIFESKILLS') || false;

  const getExamFlow = (prodId: string) => {
    if (prodId.includes('UKVI')) {
      return 'ukvi';
    }
    if (prodId.includes('ONLINE')) {
      return 'iol';
    }
    return 'ors';
  };

  const clearData = () => {
    setExistingRegDataIol(undefined);
    setExistingRegDataMod(undefined);
  };

  const examFlow = getExamFlow(
    existingRegDataIol?.details?.productId || existingRegDataMod?.details?.productId || ''
  );

  const checkExistingRegistrations = useCallback(async () => {
    if (isNewMinor || !candidateId) return;
    if (!inChangeModeExamToCheck && !iolExamDateToCheck && changeMode) return;

    try {
      const responseIol = await candidatesClientIol.getCandidateExistingRegistrations(
        candidateId,
        inChangeModeExamDate || examDateIol || examDateMod
      );

      if (responseIol.status === 200) {
        setExistingRegDataIol(responseIol.data);
      }

      const response = await candidatesClientMod.getCandidateExistingRegistrations(
        candidateId,
        inChangeModeExamDate || examDateIol || examDateMod
      );

      if (response.status === 200) {
        return setExistingRegDataMod(response.data);
      }
    } catch (error) {
      console.error(error);
    }
  }, [
    examDateMod,
    candidateId,
    examDateIol,
    isNewMinor,
    inChangeModeExamToCheck,
    changeMode,
    inChangeModeExamDate,
    iolExamDateToCheck,
  ]);

  useEffect(() => {
    checkExistingRegistrations();
  }, [checkExistingRegistrations, inChangeModeExamToCheck]);

  useEffect(() => {
    const nearestIolRegistrationExamDate = formatDate(
      existingRegDataIol?.details?.lrwExam?.startDateTime
    );

    if (isNewMinor || !existingRegDataIol || !existingRegDataMod) return;

    if (existingRegDataIol?.doesExist && regFlow === 'iol') {
      setIsModalOpen(nearestIolRegistrationExamDate === examDateIol);
      setIsRegistrationInLockoutPeriod(nearestIolRegistrationExamDate !== examDateIol);
    }

    if (existingRegDataMod?.doesExist && regFlow !== 'iol') {
      setIsModalOpen(true);
    }

    if (existingRegDataIol?.doesExist === false && existingRegDataMod?.doesExist === false) {
      inChangeModeExamToCheck && handleBook && handleBook(inChangeModeExamToCheck);
      iolExamDateToCheck && handleBookIol && handleBookIol();
    }

    if (
      existingRegDataMod?.doesExist === false &&
      regFlow !== 'iol' &&
      existingRegDataIol?.doesExist &&
      nearestIolRegistrationExamDate !== inChangeModeExamDate
    ) {
      inChangeModeExamToCheck && handleBook && handleBook(inChangeModeExamToCheck);
    }
  }, [
    existingRegDataIol?.doesExist,
    existingRegDataMod?.doesExist,
    candidateId,
    isNewMinor,
    handleBook,
    inChangeModeExamToCheck,
    iolExamDateToCheck,
    handleBookIol,
  ]);

  return {
    isModalOpen,
    isRegistrationInLockoutPeriod,
    setIsModalOpen,
    existingRegDataIol,
    existingRegDataMod,
    examFlow,
    isUkviLifeSkills,
    clearData,
  };
};
