import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../';

export const bookTestPageSize = 15;

export const getCanLoadMore = createSelector(
  (state: RootState) => state,
  (state) => {
    const filter = state.searchSelect.filter;
    const exams = state.bookTest.exams;
    const lastPageCount = state.bookTest.lastPageCount;
    if (!exams || !filter) {
      return false;
    }

    return lastPageCount > 0;
  }
);
