import { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';

import { GuardedRoute } from 'components/GuardedRoute';

import { validateState as valForFindTest } from 'store/searchSelect/routeValidation';
import { validateState as valForBookTest } from 'store/bookTest';
import { validateState as valForPersonalDetails, validateChildState } from 'store/personalDetails';
import { validateState as valForIdDetails } from 'store/idDetails';
import { validateState as valForTtProfile } from 'store/storageData/marketing/routeValidation';
import { validateReview } from 'store/registration/routeValidation';
import { LandingPageComp, TestChooserComp } from './Compatibility';

/** Initial bundle with all the Search & Select */
import FindTest from '../../pages/FindTest';
import BookTest from '../../pages/BookTest';
import TestDetails from '../../pages/TestDetails';
import { appRoutes } from './../appRoutes';

const NotFound = lazy(() => import('../../pages/NotFound'));
const PersonalDetails = lazy(
  () => import(/* webpackPreload: true */ '../../pages/PersonalDetails')
);
const PersonalDetailsChild = lazy(
  () => import(/* webpackPreload: true */ '../../pages/PersonalDetails/Child')
);
const IdDetails = lazy(() => import('../../pages/IdDetails'));
const TTProfile = lazy(() => import('../../pages/TestTakerProfile'));
const Review = lazy(() => import('../../pages/Review'));
const FinishPayment = lazy(() => import('../../pages/Payment/FinishPayment'));
const BookingComplete = lazy(() => import('../../pages/Payment/BookingComplete'));
const OnRequestCreated = lazy(() => import('../../pages/OnRequest/OnRequestCreated'));

export const JourneyRoutes = () => (
  <Routes>
    <Route
      path={appRoutes.search.findTest}
      element={
        <GuardedRoute validate={valForFindTest}>
          <FindTest />
        </GuardedRoute>
      }
    />
    <Route
      path={appRoutes.search.bookTest}
      element={
        <GuardedRoute validate={valForBookTest}>
          <BookTest />
        </GuardedRoute>
      }
    />
    <Route
      path={appRoutes.search.testDetails}
      element={
        <GuardedRoute validate={valForBookTest}>
          <TestDetails />
        </GuardedRoute>
      }
    />
    <Route
      path={appRoutes.journey.personalDetails}
      element={
        <GuardedRoute validate={valForPersonalDetails}>
          <PersonalDetails />
        </GuardedRoute>
      }
    />
    <Route
      path={appRoutes.journey.childDetails}
      element={
        <GuardedRoute validate={validateChildState}>
          <PersonalDetailsChild />
        </GuardedRoute>
      }
    />
    <Route
      path={appRoutes.journey.idDetails}
      element={
        <GuardedRoute validate={valForIdDetails}>
          <IdDetails />
        </GuardedRoute>
      }
    />
    <Route
      path={appRoutes.journey.ttProfile}
      element={
        <GuardedRoute validate={valForTtProfile}>
          <TTProfile />
        </GuardedRoute>
      }
    />
    <Route
      path={appRoutes.journey.review}
      element={
        <GuardedRoute validate={validateReview}>
          <Review />
        </GuardedRoute>
      }
    />
    <Route path={appRoutes.journey.finishPayment} element={<FinishPayment />} />
    <Route path={appRoutes.journey.bookingComplete} element={<BookingComplete />} />
    <Route path="*" element={<NotFound />} />
    <Route path={appRoutes.search.onRequestCreated} element={<OnRequestCreated />} />

    {/* Legacy routes to keep backward compatibility. Remove when it will be safe to use with flow type only. */}
    <Route path={appRoutes.legacy.search.testChooserCountry} element={<TestChooserComp />} />
    <Route path={appRoutes.legacy.search.testChooser} element={<TestChooserComp />} />
    <Route path={appRoutes.legacy.search.landingPageGlobal} element={<LandingPageComp />} />
    <Route path={appRoutes.legacy.search.landingPageOrganisation} element={<LandingPageComp />} />
    <Route path={appRoutes.legacy.search.landingPage} element={<LandingPageComp />} />
    {/* End of legacy routes */}
  </Routes>
);
