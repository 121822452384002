import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import AriaModal from '@justfixnyc/react-aria-modal';
import { Button } from '@britishcouncil/react-solas';
import { SuiPanel } from '@britishcouncil/react-solas-ors3';

import { invitation } from 'store/invitation';
import { GTM } from 'core';

// TODO: @justfixnyc/react-aria-modal is an unsupported low quality lib with no support for React 18. Use something else here. [ORSNBC-2462]
const AriaModal2 = AriaModal as any;

export const InvitationModal: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onClick = () => {
    dispatch(invitation.thunks.clearInvitationContext());
    GTM.trackModal('Hide', 'invitation-modal');
  };

  /** Because component is rendered conditionally */
  useEffect(() => {
    GTM.trackModal('Show', 'invitation-modal');
  }, []);

  return (
    <AriaModal2 titleText={t('APPB2C.common.invitation.modal.header')} verticallyCenter>
      <StyledPanel intent="danger">
        <SuiPanel.Header>{t('APPB2C.common.invitation.modal.header')}</SuiPanel.Header>
        <SuiPanel.Body>
          <span>{t('APPB2C.common.invitation.modal.invalid')}</span>
        </SuiPanel.Body>
        <Footer>
          <Button intent="primary" data-testid="invitation-ok" type="button" onClick={onClick}>
            {t('APPB2C.common.basic.ok')}
          </Button>
        </Footer>
      </StyledPanel>
    </AriaModal2>
  );
};

const StyledPanel = styled(SuiPanel)`
  width: 70vw;
  max-width: 500px;
`;

const Footer = styled(SuiPanel.Header)`
  text-align: center;
  && button {
    margin: 0;
  }
`;
