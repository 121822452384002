import { GetDistrictExamsBaseRequest, OrganisationCountryAvailableIdDto } from 'ors-api/mod';

import { AppThunk } from '../..';
import { getIdFiles } from '../../api';
import { FullCandidatePersonalDetails } from '../../candidate';
import { idDetails as idDetailsSlice } from '../../idDetails';
import { verifyDocuments } from '../../idDetails/api/api.ors';
import { isIdValid, isIdTypeAvailable } from '../../idDetails/thunks/thunks.helpers';
import { loadSearchResults, loadSearchResultsWithCoords } from '../api/api.mod';
import { appRoutes, navigateWithBase } from 'routing';
import { getRegFlow } from 'core';

export const loadExamsMod =
  (filter: GetDistrictExamsBaseRequest, loadMore: boolean = false): AppThunk =>
  async (dispatch, getState) => {
    const { locationCoords, activeLocation } = getState().searchSelect;
    const countryId = getState().searchSelect.activeCountry?.id;
    const ukviData = getState().searchSelect.ukvi;
    const isUkvi = getRegFlow() === 'ukvi';

    const queryBase = isUkvi
      ? {
          ...filter,
          examOptionType: ukviData?.examOptionType,
        }
      : filter;

    if (!ukviData?.examOptionType && isUkvi) {
      return navigateWithBase(`/${appRoutes.search.ukvi}`);
    }

    if (locationCoords && countryId) {
      const { longitude, latitude } = locationCoords;
      const query = { ...queryBase, countryId, longitude, latitude, ...{} };
      dispatch(loadSearchResultsWithCoords({ req: query, more: loadMore }));
      return;
    }

    const query = { ...queryBase, districtIds: activeLocation!.ids };
    dispatch(loadSearchResults({ req: query, more: loadMore }));
  };

export enum RebookStatus {
  NewTestTaker,
  ExistingTestTaker,
  IdInvalid,
  IdUploadRequired,
}
export const getRebookStatus =
  (profile?: FullCandidatePersonalDetails): AppThunk<Promise<RebookStatus>> =>
  async (dispatch, getState) => {
    const { selectedExam } = getState().bookTest;

    if (profile?.id) await dispatch(verifyDocuments(profile.id));

    /** Get candidate's files only if document has not expired and is valid */
    const shouldGetDocs = profile?.id && isIdValid(getState()) && isIdTypeAvailable(getState());
    shouldGetDocs && (await dispatch(getIdFiles(profile!.id)));

    if (!shouldGetDocs) dispatch(idDetailsSlice.actions.clear());

    const idDetails = getState().idDetails;

    if (!profile?.idNumber) return RebookStatus.NewTestTaker;

    if (!isIdTypeAvailable(getState())) {
      return RebookStatus.IdInvalid;
    }

    if (isUploadRequired(idDetails?.idTypes.data) && idDetails.initIdFiles.length === 0) {
      return RebookStatus.IdUploadRequired;
    }

    if (profile?.idExpiry && selectedExam?.examDate) {
      return profile.idExpiry > selectedExam?.examDate
        ? RebookStatus.ExistingTestTaker
        : RebookStatus.IdInvalid;
    }

    return RebookStatus.ExistingTestTaker;
  };

const isUploadRequired = (options?: OrganisationCountryAvailableIdDto[]) => {
  const isEnabled = options ? options.some((el) => el.enableUploadInCustomerJourney) : false;

  return isEnabled;
};
