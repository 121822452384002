import { createAction } from '@reduxjs/toolkit';

/** Place for global actions that can be listened to by all of the slices */

/** Action raised when user logged out. It can be used to clear other slices */
export const globalLogOut = createAction<void>('global/loggedOut');

/** Action raised on the routing change. Is used by all slices to reset error notifications */
export const clearErrors = createAction<void>('global/clearError');

/** Action raised when invitation context needs to be fully cleared */
export const clearInvitation = createAction<void>('global/clearInvitation');

/** Action raised when exam is registered and TT landed on confirmation page */
export const globalCleanRegistration = createAction<void>('global/cleanRegistration');

/** Action raised when TT changes exam type */
export const examTypeChanged = createAction<void>('global/examTypeChanged');
