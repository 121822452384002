import { CountryDto, ExamCountryDto } from 'ors-api/mod';

export const mapTranslatedCountryNames = (
  initialList: ExamCountryDto[],
  translatedList: CountryDto[]
) => {
  return initialList.reduce<ExamCountryDto[]>((acc, curr) => {
    const translatedCountry = translatedList.find((country) => country.id === curr.id);

    if (translatedCountry) {
      return [
        ...acc,
        {
          ...curr,
          name: translatedCountry.name,
        },
      ];
    } else {
      return [...acc, curr];
    }
  }, []);
};
