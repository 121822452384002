import { FC, PropsWithChildren } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { appRoutes } from 'routing';

import { useSelector, getRegFlow } from 'core';
import { PathValidation } from 'store/';

interface Props extends PropsWithChildren {
  validate: PathValidation;
}

export const GuardedRoute: FC<Props> = ({ validate, children }) => {
  const state = useSelector((s) => s);
  const valid = validate(state);
  const flow = getRegFlow();
  const isUkvi = flow === 'ukvi';

  if (valid === true) {
    return <>{children ? children : <Outlet />}</>;
  }
  if (valid === appRoutes.root) {
    return <Navigate to={isUkvi ? '/ukvi' : appRoutes.root} replace />;
  }

  return <Navigate to={`/${flow}/${valid}`} replace />;
};
