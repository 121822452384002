import { productsClient } from 'ors-api/iol';
import {
  AwardingBodySystem,
  awardingBodySystemsClient,
  familyRelationshipClient,
  FamilyRelationshipDto,
  GenderDto,
} from 'ors-api/mod';

import { includesInUrl, logger } from 'ors-utils';
import { api, BaseTranslatedQueryParams } from 'store/shared';
import { personalDetails } from '.';

const personalDetailsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getGenders: builder.query<
      GenderDto[],
      {
        awardingBodySystem?: AwardingBodySystem;
        productId?: string;
        countryId?: number;
      } & BaseTranslatedQueryParams
    >({
      query: ({ awardingBodySystem, productId, productFamilyId, countryId }) => {
        const isIol = includesInUrl('/iol/');
        if (awardingBodySystem && productFamilyId && countryId) {
          return awardingBodySystemsClient.getCountryAvailableGenders(
            awardingBodySystem,
            productFamilyId,
            countryId
          );
        } else if (productId && isIol) return productsClient.getGenders(productId);

        return Promise.resolve([] as any);
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(personalDetails.actions.setGenders(data));
        } catch (error) {
          logger.logError(error as Error);
        }
      },
    }),
    getFamilyRelationships: builder.query<FamilyRelationshipDto[], BaseTranslatedQueryParams>({
      query: () => {
        return familyRelationshipClient.getFamilyRelationships();
      },
    }),
  }),
});

export const { useGetGendersQuery, useGetFamilyRelationshipsQuery } = personalDetailsApi;
