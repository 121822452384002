import { isEqual } from 'lodash-es';
import { DateTime } from 'luxon';
import { userFriendlyTime } from '@britishcouncil/react-solas-ors3';

import { AppThunk } from '../..';
import slice from '../bookTestSlice';
import { TimeTableSession } from 'ors-api/ors2';
import {
  AvailableDistrictExamDto,
  ExamCountryDto,
  InvitationContextDto,
  GetDistrictExamsBaseRequest,
  BatchExamFormat,
} from 'ors-api/mod';

import { GTM } from 'core';
import { sortingDictionary } from '../helpers';
import { converters } from 'common';
import { SortOrder } from '../model';
import { ExamFilterBase, searchSelect, Location } from '../../searchSelect';
import { getDaysOffset } from '../../searchSelect/helpers';
import { appRoutes, navigateTo } from 'routing';
import { organisationCountry } from '../../organisationCountry';
import { loadExamsMod } from './thunks.ors';
import { searchSelectConverters } from 'store/searchSelect/sliceConverters';
import { bookTestPageSize } from '../selectors';

let lastUsedFilter = {};
let lastUsedLocation: Location | undefined = undefined;
let lastUsedCountry: ExamCountryDto | undefined = undefined;

const handleLoad =
  (filter: GetDistrictExamsBaseRequest, loadMore: boolean = false): AppThunk =>
  async (dispatch) => {
    dispatch(loadExamsMod(filter, loadMore));
  };

export const loadInitialData = (): AppThunk => async (dispatch, getState) => {
  const districtIds = getState().searchSelect.activeLocation?.ids;
  const position = getState().searchSelect.locationCoords;
  const ieltsModule = getState().searchSelect.ieltsModule;

  if (!ieltsModule) {
    navigateTo(appRoutes.root);
    return;
  }

  if (!districtIds && !position) {
    navigateTo(appRoutes.search.findTest);
    return;
  }

  dispatch(slice.actions.setSortOrder(position ? 'distance' : 'date'));

  dispatch(slice.actions.setCurrentPage(1));
  await dispatch(loadExamResults(false));
};

export const loadExamResults =
  (loadMore: boolean): AppThunk =>
  async (dispatch, getState) => {
    const organisationId = organisationCountry.selectors.getOrgId(getState());
    const {
      ieltsModule,
      datesRange,
      showAllDates,
      filter: userFilter,
      activeLocation,
      activeCountry,
      needSpecialReqs,
      specialReqs,
    } = getState().searchSelect;
    const { currentPage, sortOrder, exams } = getState().bookTest;

    const specialArrangementDays = getDaysOffset(needSpecialReqs ? specialReqs : undefined);

    const filter: GetDistrictExamsBaseRequest = {
      ieltsModule: searchSelectConverters.ieltsModule.toExamModule(ieltsModule),
      organisationId,
      from: !showAllDates ? datesRange && datesRange[0] : undefined,
      to: !showAllDates && datesRange && datesRange.length > 1 ? datesRange[1] : undefined,
      pagination: {
        page: currentPage,
        pageSize: bookTestPageSize,
      },
      sorting: sortingDictionary[sortOrder],
      daysToNearestCdExam: specialArrangementDays?.daysToNearestCdExam || 0,
      daysToNearestPbExam: specialArrangementDays?.daysToNearestPbExam || 0,
      ...userFilter,
    };

    /** Avoid reloading exam dates when user goes back to BookTest page */
    if (
      loadMore ||
      exams === undefined ||
      exams.results?.length === 0 ||
      !isEqual(lastUsedFilter, filter) ||
      !isEqual(lastUsedLocation, activeLocation) ||
      !isEqual(lastUsedCountry, activeCountry)
    ) {
      await dispatch(handleLoad(filter, loadMore));
      lastUsedFilter = filter;
      lastUsedLocation = activeLocation;
      lastUsedCountry = activeCountry;
    }
  };

export const loadMoreExamResults = (): AppThunk => async (dispatch, getState) => {
  dispatch(slice.actions.nextPage());
  GTM.trackRegistrationEvent('load-more-exams');
  await dispatch(loadExamResults(true));
};

export const clearExams = (): AppThunk => async (dispatch, getState) => {
  dispatch(slice.actions.clearExams());
};

export const clearFilter = (): AppThunk => async (dispatch, getState) => {
  dispatch(searchSelect.actions.resetFilter());
  dispatch(slice.actions.setCurrentPage(1));
  await dispatch(loadExamResults(false));
};

export const changeFilter =
  (filterOptions: ExamFilterBase): AppThunk =>
  async (dispatch) => {
    dispatch(searchSelect.actions.setFilter(filterOptions));
    dispatch(slice.actions.setCurrentPage(1));
    await dispatch(loadExamResults(false));
  };

export const setInvitationFilters =
  (invitationContext?: InvitationContextDto): AppThunk =>
  async (dispatch) => {
    const getInvitationFilters = (invitationContextData?: InvitationContextDto): ExamFilterBase => {
      const invitationExamFormat =
        invitationContextData?.examFormat === BatchExamFormat.Both
          ? BatchExamFormat.ComputerDelivered
          : invitationContextData?.examFormat;
      const invitationLrwSessions = invitationContextData?.lrwSessions;
      const examFormat = converters.fromBatchExamFormat(invitationExamFormat).toEnum();
      const timeTableSessions = invitationLrwSessions as TimeTableSession[] | undefined;

      return {
        examFormat,
        timeTableSessions,
      };
    };

    dispatch(searchSelect.actions.setFilter(getInvitationFilters(invitationContext)));
  };

export const setSortOrder =
  (sortOrder: SortOrder): AppThunk =>
  async (dispatch, getState) => {
    await dispatch(slice.actions.setSortOrder(sortOrder));
    dispatch(slice.actions.setCurrentPage(1));
    await dispatch(loadExamResults(false));
  };

export const changeSpeaking =
  (exam: AvailableDistrictExamDto): AppThunk =>
  async (dispatch) => {
    await dispatch(slice.actions.setSelectedExam(exam));

    const speakingSlot = exam.defaultSpeakingSlot;
    GTM.trackRegistrationEvent('changeSpeaking', {
      defaultSpeakingDay: !speakingSlot
        ? ''
        : DateTime.fromISO(speakingSlot?.date).toFormat('yyyy-MM-dd'),
      defaultSpeakingTime: userFriendlyTime(speakingSlot?.startTime),
    });

    navigateTo(appRoutes.search.testDetails);
  };
