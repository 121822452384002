import { asyncThunk } from 'components/asyncThunk';
import { reservationClient } from 'ors-api/mod';

const apiClient = 'api-mod';

export const preRegisterMyself = asyncThunk(
  `${apiClient}/preRegisterMyself`,
  reservationClient.reserveMyself
);
export const preRegisterExistingChild = asyncThunk(
  `${apiClient}/preRegisterExistingChild`,
  reservationClient.reserveExistingChild
);
export const preRegisterNewChild = asyncThunk(
  `${apiClient}/preRegisterNewChild`,
  reservationClient.reserveNewChild
);
