import { CreateTestRequest } from 'ors-api/mod';

import { AppThunk } from '..';
import { createTestRequest } from './api';
import { appRoutes, navigateTo } from '../../routing';
import { searchSelect } from '../searchSelect';

export const saveTestRequest =
  (val: CreateTestRequest): AppThunk =>
  async (dispatch, getState) => {
    const resp = await dispatch(createTestRequest(val));

    if (resp.meta.requestStatus === 'fulfilled') {
      dispatch(searchSelect.thunksCommon.setActiveLocation(undefined));
      return navigateTo(appRoutes.search.onRequestCreated);
    }
  };
