import { ActionReducerMapBuilder } from '@reduxjs/toolkit';

import { BookTestState } from '..';
import { getExams } from '../../api.iol';
import { apiExtraReducer } from '../../_common/apiExtraReducer';

export const extraReducersIol = (builder: ActionReducerMapBuilder<BookTestState>) => {
  /** Load IELTS Online Exams */
  apiExtraReducer(builder, getExams, 'examsIol');
};
