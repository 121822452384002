import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { DateTime } from 'luxon';

import { createReservationIol } from '../../api.iol';
import { ReservationState } from '../models';
import { EXAM_NOT_AVAILABLE_CODES } from './helpers';

const EXPIRY_TIME_MINUTES = 25;

export const extraReducersIol = (builder: ActionReducerMapBuilder<ReservationState>) => {
  /* Pre-register selected exam for IOL */
  builder
    .addCase(createReservationIol.pending, (state, action) => {
      state.processing = true;
      state.processingErr = undefined;
    })
    .addCase(createReservationIol.fulfilled, (state, action) => {
      const curTime = DateTime.local();
      const deadline = curTime.plus({ minutes: EXPIRY_TIME_MINUTES });

      state.reservationGuid = action.payload.bookingId;
      state.countryId = action.payload.countryId;
      state.expiryMinutes = EXPIRY_TIME_MINUTES;
      state.deadline = deadline.toISO();
      state.examMode = 'IOL';
      state.grossPrice = action.payload.grossPrice;
      state.productId = action.payload.productId;
    })
    .addCase(createReservationIol.rejected, (state, action) => {
      state.processing = false;

      /** Show modal when IOL exams clash occurs or speaking exam is no longer available: */
      if (action.payload?.errors?.find((el) => EXAM_NOT_AVAILABLE_CODES.includes(el?.code ?? ''))) {
        state.showExamTakenModal = true;
        return;
      }

      /** Otherwise treat it as general error: */
      state.processingErr = action.payload;
    });
};
