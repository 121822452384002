import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { LanguageSwitcher } from 'ors-ui';

import { switchLanguage, useLocale, useSelector } from 'core';

export const LanguageContainer: FC = () => {
  const dispatch = useDispatch();
  const { locale } = useLocale();
  const { activeCountry } = useSelector((state) => state.searchSelect);

  return (
    <LanguageSwitcher
      selectedLang={locale}
      dispatchLangSwitch={(lang) => switchLanguage(lang, dispatch)}
      activeCountryLangs={activeCountry?.additionalLanguages}
    />
  );
};
