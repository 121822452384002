import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { FormFields } from '@britishcouncil/react-forms';
import { LoadingContainer } from '@britishcouncil/react-solas';
import { Quote, LinkButton, Divider, BcAlert } from '@britishcouncil/react-solas-ors3';
import { isAdult } from 'ors-utils';

import { useSelector } from 'core';
import { useDispatch } from 'core/hooks/useDispatch';
import { toggleShowLogoutModal } from 'store/authSlice';
import { PersonalDetailsForm } from 'store/personalDetails';
import { refreshAbTests } from 'store/featureFlagsSlice';
import { AboutYouFields } from './AboutYouFields';

interface Props {
  fields: FormFields<PersonalDetailsForm>;
  isMinorAndIol: boolean;
  setFieldTouched: (field: string) => unknown;
}

export const AboutYou = ({ fields, isMinorAndIol, setFieldTouched }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const candidateProfile = useSelector((state) => state.candidate.profile);
  const { isLoggedIn, tempAccount, logInProcessing } = useSelector((state) => state.auth);
  const { allowEdit, processing } = useSelector((state) => state.personalDetails);
  const inChangeMode = useSelector((state) => state.registration.changeMode);

  const [showEditForm, setShowEditForm] = useState(false);
  const showChangeLink = !showEditForm && isLoggedIn && allowEdit && !inChangeMode;
  const wrongDob = isLoggedIn && (!candidateProfile?.dob || !isAdult(candidateProfile.dob));
  const showIdNote =
    (!isLoggedIn || allowEdit || showEditForm || showChangeLink || wrongDob) &&
    fields.forWhom.value === 'myself';
  const countryCode2 = useSelector((state) => state.searchSelect.activeCountry?.isoCode2);

  useEffect(() => {
    countryCode2 && dispatch(refreshAbTests(countryCode2));
  }, [countryCode2]);

  useEffect(() => {
    if (wrongDob) {
      wrongDob && setFieldTouched('dateOfBirth');
    }
  }, [wrongDob, setFieldTouched, candidateProfile]);

  return (
    <LoadingContainer loading={processing}>
      <ChangeHeader>
        <HeaderWithNote data-testid={'about-you-header'}>
          {t('APPB2C.cj.personalDetails.forms.personal.sectionTitle')}
          {fields.forWhom.value === 'someone-else' && (
            <span>({t('APPB2C.cj.personalDetails.idNote.whenForChild')})</span>
          )}
        </HeaderWithNote>
        {showChangeLink && (
          <StyledLinkButton onClick={() => setShowEditForm(true)}>
            {t('APPB2C.cj.personalDetails.forms.personal.change')}
          </StyledLinkButton>
        )}
      </ChangeHeader>

      {showIdNote && (
        <StyledAlert
          type="warning"
          title={t('APPB2C.common.basic.pleaseNote')}
          size="sm"
          data-testid="id-match"
        >
          {t('APPB2C.cj.personalDetails.idNote.idMatch')}
        </StyledAlert>
      )}

      {!logInProcessing && (
        <AboutYouFields
          fields={fields}
          isLoggedIn={isLoggedIn}
          candidateProfile={candidateProfile}
          isMinorAndIol={isMinorAndIol}
          showEditForm={showEditForm || wrongDob}
        />
      )}

      {isLoggedIn && !tempAccount && !candidateProfile?.needToSupplyMissingDetails && (
        <Quote title="" intent="gray">
          <Trans t={t} i18nKey="APPB2C.cj.personalDetails.login.logout">
            Not your details?
            <LinkButton
              className="link"
              data-testid="modal-login-link"
              onClick={() => dispatch(toggleShowLogoutModal())}
            >
              Login
            </LinkButton>
          </Trans>
        </Quote>
      )}
      <Divider />
    </LoadingContainer>
  );
};

const HeaderWithNote = styled.h2`
  flex: 1 1 0;
  span {
    font-size: 18px;
    margin: 0 5px;
    color: #54565a;
  }
`;

const ChangeHeader = styled.div`
  display: flex;
  align-items: baseline;
`;

const StyledLinkButton = styled(LinkButton)`
  flex: 0 1 0;
  margin-left: 20px;
  padding: 0;
  white-space: nowrap;
  text-decoration: none;
`;

const StyledAlert = styled(BcAlert)`
  margin-bottom: 15px;
`;
