import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AvailableDistrictExamDto, AvailableSpeakingSlotDto } from 'ors-api/mod';
import { RegistrationCreatedDto } from 'ors-api/iol';
import { ApiErrorsList } from 'ors-ui';

import { createRegistration, getNewestTermsVersionNumber } from '../api';
import { createRegistrationUkvi, getNewestTermsVersionNumberUkvi } from '../api.ukvi';
import {
  clearErrors,
  globalCleanRegistration,
  globalLogOut,
  examTypeChanged,
} from '../_common/actions';
import { createRegistrationIol } from './api.iol';
import {
  ApiErrorsListWithInCentreRegistrationRequest,
  ApiErrorsListWithIolRegistrationRequest,
  RegistrationState,
  SelectedIolSession,
} from './types';

export const sliceName = 'registration';

export const initialState: RegistrationState = {
  registrationId: { isLoading: false },
  registrationIdentifiersIol: { isLoading: false },
  newestTermsVersionNumberIelts: { isLoading: false },
  termsShortCodeIelts: '',
  newestTermsVersionNumberUkvi: { isLoading: false },
  termsShortCodeUkvi: '',
  rebooking: false,
  changeMode: false,
};

/**
 * Persisted and very important slice containing information about actual registration
 * and its components
 */
const slice = createSlice({
  name: sliceName,
  initialState: initialState,
  reducers: {
    setRebooking(state, action: PayloadAction<boolean>) {
      state.rebooking = action.payload;
    },
    setChangeModeOn(state) {
      state.changeMode = true;
    },
    setSelectedInCentreExam(state, action: PayloadAction<SetExamPayload>) {
      state.inCentreSpeakingExam = action.payload.speaking;
      state.inCentreExam = action.payload.plannedExam;
    },
    setSelectedIolExam(state, action: PayloadAction<SelectedIolSession>) {
      state.iolExam = action.payload;
    },
    setRegistrationId(state, action: PayloadAction<number>) {
      state.registrationId.data = action.payload;
    },
    setIolRegistrationId(state, action: PayloadAction<RegistrationCreatedDto>) {
      state.registrationIdentifiersIol = {
        data: action.payload,
        isLoading: false,
      };
    },
    setModifyNoteAccepted(state, action: PayloadAction<boolean>) {
      state.organisationSpecific = {
        modifyNoteAccepted: action.payload,
      };
    },
    clearRegistrationId(state) {
      state.registrationId.data = undefined;
    },
    setTermsShortCodeIelts(state, action: PayloadAction<string>) {
      state.termsShortCodeIelts = action.payload;
    },
    setTermsShortCodeUkvi(state, action: PayloadAction<string>) {
      state.termsShortCodeUkvi = action.payload;
    },
    clearErrors(state) {
      state.registrationId.error = undefined;
      state.registrationIdentifiersIol.error = undefined;
    },
    clear: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(globalLogOut, () => initialState);
    builder.addCase(globalCleanRegistration, () => initialState);
    builder.addCase(examTypeChanged, () => initialState);
    builder.addCase(clearErrors, (state) => {
      state.registrationId.error = undefined;
    });

    builder.addCase(
      createRegistrationIol.rejected,
      (state, action: PayloadAction<ApiErrorsListWithIolRegistrationRequest | undefined>) => {
        state.registrationIdentifiersIol.error = action.payload;
      }
    );
    builder.addCase(createRegistrationIol.pending, (state, action) => {
      state.registrationIdentifiersIol.error = undefined;
    });
    builder.addCase(createRegistrationIol.fulfilled, (state, action) => {
      state.registrationIdentifiersIol.error = undefined;
      state.registrationIdentifiersIol.data = action.payload;
    });

    /* Get newest terms version for IELTS */
    builder.addCase(
      getNewestTermsVersionNumber.rejected,
      (state, action: PayloadAction<ApiErrorsList | undefined>) => {
        state.newestTermsVersionNumberIelts.error = action.payload;
      }
    );
    builder.addCase(getNewestTermsVersionNumber.pending, (state) => {
      state.newestTermsVersionNumberIelts.error = undefined;
    });
    builder.addCase(getNewestTermsVersionNumber.fulfilled, (state, action) => {
      state.newestTermsVersionNumberIelts.error = undefined;
      state.newestTermsVersionNumberIelts.data = action.payload;
    });

    /* Get newest terms version for UKVI */
    builder.addCase(
      getNewestTermsVersionNumberUkvi.rejected,
      (state, action: PayloadAction<ApiErrorsList | undefined>) => {
        state.newestTermsVersionNumberUkvi.error = action.payload;
      }
    );
    builder.addCase(getNewestTermsVersionNumberUkvi.pending, (state) => {
      state.newestTermsVersionNumberUkvi.error = undefined;
    });
    builder.addCase(getNewestTermsVersionNumberUkvi.fulfilled, (state, action) => {
      state.newestTermsVersionNumberUkvi.error = undefined;
      state.newestTermsVersionNumberUkvi.data = action.payload;
    });

    /* Register for IC */
    builder.addCase(
      createRegistration.rejected,
      (state, action: PayloadAction<ApiErrorsListWithInCentreRegistrationRequest | undefined>) => {
        state.registrationId.error = action.payload;
      }
    );
    builder.addCase(createRegistration.pending, (state) => {
      state.registrationId.error = undefined;
    });
    builder.addCase(createRegistration.fulfilled, (state, action) => {
      state.registrationId.error = undefined;
      state.registrationId.data = action.payload.id;
    });

    /* Register for UKVI */
    builder.addCase(
      createRegistrationUkvi.rejected,
      (state, action: PayloadAction<ApiErrorsListWithInCentreRegistrationRequest | undefined>) => {
        state.registrationId.error = action.payload;
      }
    );
    builder.addCase(createRegistrationUkvi.pending, (state) => {
      state.registrationId.error = undefined;
    });
    builder.addCase(createRegistrationUkvi.fulfilled, (state, action) => {
      state.registrationId.error = undefined;
      state.registrationId.data = action.payload.id;
    });
  },
});

export default slice;

interface SetExamPayload {
  speaking: AvailableSpeakingSlotDto;
  plannedExam: AvailableDistrictExamDto;
}
