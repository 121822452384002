import { fixGoogleTranslateIssue, SS } from '@britishcouncil/react-common';
import { appSettings, CookieManager } from 'ors-utils';
import { setSupportedLanguages } from 'ors-translations';

import { AppThunk } from '../';
import { B2B_INVITATION_KEY, loadAuthStateFromLS } from '../authSlice';
import { refreshFeatureFlags } from '../api';
import { reservation } from '../reservation';
import { history } from '../historyState';
import { clearErrors } from '../_common/actions';
import appConfig from 'appConfig';
import { initSentry } from 'initSentry';
import { invitation, InvitationState } from '../invitation';
import { GoogleTagManager } from 'core/analytics/googleTagManager';
import { useInterceptors } from './useInterceptors';
import { trackPremiumCodes } from './trackPremiumCodes';
import { setPassedInData } from './setPassedInData';
import { checkIdentityFlow } from './identityFlow';
import personalDetailsSlice from '../personalDetails/personalDetailsSlice';

/** Loads app initial state */
export const initApp =
  (handleLogOut: (redirectUrl?: string) => void): AppThunk =>
  async (dispatch, getState) => {
    appSettings(appConfig, initSentry, import.meta.env.REACT_APP_ENV).then((appSettings) => {
      GoogleTagManager.initialize(appSettings.gtmCode);
      setSupportedLanguages(appSettings.supportedLanguages);
    });
    dispatch(personalDetailsSlice.actions.clearSensitivePersonalData());
    fixGoogleTranslateIssue();
    useInterceptors(handleLogOut);

    // Left for future reference
    // dispatch(refreshAbTests());

    dispatch(loadAuthStateFromLS(handleLogOut));
    dispatch(reservation.thunksCommon.setupRefreshStateWorker());
    await dispatch(setPassedInData());

    /** Clear errors from store on route change */
    history.listen(() => {
      dispatch(clearErrors());
    });

    /** Set Geolocalisation and IOL Early Access Cookies on DEV environments */
    if (
      (import.meta.env.MODE === 'development' || document.location.host.includes('.dev.')) &&
      !CookieManager.getCookie('AkamaiEdgeScape')
    ) {
      document.cookie = 'AkamaiEdgeScape=country_code=GB;path=/';
    }

    /** Refresh Feature Flags after the cookie setup for DEV environments */
    dispatch(refreshFeatureFlags());

    /** Read invitaion context when user is coming back from login page. */
    if (SS.read(B2B_INVITATION_KEY)) {
      const { deeplinkToken, invitationContext } = SS.read<InvitationState>(B2B_INVITATION_KEY);

      if (!getState().invitation.deeplinkToken && deeplinkToken) {
        dispatch(invitation.actions.setDeeplinkToken(deeplinkToken));
        dispatch(
          invitation.actions.setInvitationContext({
            invitationContext: invitationContext.data,
          })
        );
      }
      SS.remove(B2B_INVITATION_KEY);
    }

    /** Track Affiliate link with GA */
    trackPremiumCodes();

    /** Check Identity flow */
    checkIdentityFlow();
  };
