import { appRoutes } from 'routing';
import { history } from 'store/historyState';

type RoutesType = 'root' | 'notFound' | 'auth' | 'search' | 'journey';

export const getJourneyRouteType = () => {
  const currPath = history.location.pathname;

  const journeyRouteType = Object.keys(appRoutes).reduce((acc, curr) => {
    const routes = appRoutes[curr as RoutesType];
    if (typeof routes === 'object') {
      return Object.values(routes).includes(currPath) ? curr : acc;
    } else {
      return routes === currPath ? curr : acc;
    }
  }, appRoutes.notFound);

  return journeyRouteType as RoutesType;
};
