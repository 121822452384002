import { getPayerModel, getPayerOption, getPayerDetailsUkvi } from 'ors-ui';
import { AFFILIATION_PREMIUM_CODE_KEY, CookieManager, countryIsoCode2 } from 'ors-utils';

import { GTM } from 'core';
import { postDataLayer } from 'core/analytics';
import { AppThunk } from '..';
import { createRegistration } from '../api';
import { createRegistrationUkvi } from '../api.ukvi';
import { ReviewForm } from '../payment';
import { searchSelect } from '../searchSelect';
import { getRegistrationRequest, getRegistrationRequestUkvi } from './selectors';
import { AwardingBodySystem } from 'ors-api/mod';
import { B2CCreateRegistrationRequest } from 'ors-api/ukvi';

export const register =
  (values: ReviewForm): AppThunk =>
  async (dispatch, getState) => {
    const regId = getState().registration.registrationId.data;
    if (regId) {
      return regId;
    }

    const candidateProfile = getState().candidate.profile;
    const organisationCountryId = getState().registration.inCentreExam?.organisationCountryId;
    const isUkvi = searchSelect.selectors.isUkvi();
    const countryCode2 = getState().searchSelect.activeCountry?.isoCode2;
    const showInvoiceInfo = countryCode2 === countryIsoCode2.TAIWAN;
    const isLifeSkills = searchSelect.selectors.isUkviLifeSkills(getState());
    const awardingBodySystem = getState().bookTest.selectedExam?.awardingBodySystem;
    const visaTypeId = getState().candidate.visaTypeId;

    const isVisaTypeRequired =
      isUkvi && awardingBodySystem === AwardingBodySystem.CMDS && !isLifeSkills;

    const registrationRequest = {
      payerModel: getPayerModel(values, organisationCountryId!, candidateProfile, showInvoiceInfo),
      ...getRegistrationRequest(getState()),
    };

    const ukviRegistrationRequest: B2CCreateRegistrationRequest = {
      payerModel: getPayerDetailsUkvi(
        regId || 0,
        values,
        organisationCountryId!,
        candidateProfile,
        showInvoiceInfo
      ),
      ...getRegistrationRequestUkvi(getState()),
      visaTypeId: isVisaTypeRequired ? visaTypeId : undefined,
    };

    const removeAffiliationPremiumCodeCookie = () => {
      CookieManager.eraseCookie(AFFILIATION_PREMIUM_CODE_KEY);
    };

    const { payload } = isUkvi
      ? await dispatch(createRegistrationUkvi(ukviRegistrationRequest)).then((registration) => {
          return registration;
        })
      : await dispatch(createRegistration(registrationRequest)).then((registration) => {
          return registration;
        });

    if (payload && 'error' in payload) return;

    if (payload && 'fee' in payload) {
      GTM.update({
        invoiceFor: getPayerOption(values.acknowledgement),
        revenue: `${payload?.fee} ${payload?.currency}`,
        shipping: 0,
        tax: 0,
      });

      payload.reference && GTM.update({ dataLayerCorrelationId: payload.reference });
    }
    removeAffiliationPremiumCodeCookie();
    postDataLayer();
  };
