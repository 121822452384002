import { RootState } from '../';
import { getRegFlow } from 'core';

export function validateState(state: RootState): true | string {
  const ss = state.searchSelect;
  if (!ss.examType) return '/';

  if (getRegFlow() === 'iol' && !ss.ieltsProduct) return '/';

  if (['ors', 'ukvi'].includes(getRegFlow() ?? '')) {
    if (!ss.activeCountry) return '/find-test';
  }

  return true;
}
