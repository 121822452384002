import { FC } from 'react';
import styled from '@emotion/styled';
import { Trans, useTranslation } from 'react-i18next';
import { Button } from '@britishcouncil/react-solas';
import { localeFns } from 'ors-utils';
import { Modal } from 'ors-ui';
import { AvailableDistrictExamDto } from 'ors-api/mod';

import { GTM, useLocale, useSelector } from 'core';
import { SelectedIolSession } from 'store/registration/types';
import { getTimeSpan } from '../../TestDetails/iol/utils/getTitles';

interface Props {
  showModal: boolean;
  onRestart: () => void;
  onExit?: () => void;
  onCancel: () => void;
}

const formatDate = (
  inCentreExam?: AvailableDistrictExamDto,
  iolExam?: SelectedIolSession,
  locale?: string
) => {
  const date = iolExam?.lrwStartDateTimeUtc ?? inCentreExam?.examDate;
  const startTime = iolExam?.lrwStartDateTimeUtc ?? inCentreExam?.startTime;
  const endTime = iolExam?.lrwEndDateTimeUtc ?? inCentreExam?.endTime;

  const timeZoneId = !!iolExam ? Intl.DateTimeFormat().resolvedOptions().timeZone : undefined;
  const localeDate = date && localeFns(locale).localeDate(date);
  const timeSpan = getTimeSpan(startTime, endTime, locale, timeZoneId);

  return localeDate && timeSpan ? `${localeDate}, ${timeSpan}` : '-';
};

export const RegInProgressModal: FC<Props> = (props) => {
  const { t } = useTranslation();
  const { locale } = useLocale();
  const { showModal, onExit, ...cta } = props;

  const { inCentreExam, iolExam } = useSelector((s) => s.registration);

  const date = formatDate(inCentreExam, iolExam, locale);

  return (
    <Modal
      show={showModal}
      onExit={onExit ?? (() => {})}
      title={t('APPB2C.common.regInProgressModal.title')}
      intent="warning"
      footer={<Footer {...cta} />}
      modalProps={{
        alert: true,
      }}
      onHide={() => GTM.trackModal('Hide', 'reg-inprogress-modal')}
      onShow={() => GTM.trackModal('Show', 'reg-inprogress-modal')}
    >
      <ModalContent>
        <p>
          <Trans
            i18nKey="APPB2C.common.regInProgressModal.message1"
            values={{ date }}
            components={{ bold: <strong /> }}
          />
        </p>
        <p>{t('APPB2C.common.regInProgressModal.message2')}</p>
      </ModalContent>
    </Modal>
  );
};

const Footer: FC<Omit<Props, 'showModal' | 'onExit'>> = ({ onRestart, onCancel }) => {
  const { t } = useTranslation();

  return (
    <FooterWrapper>
      <Button intent="warning" onClick={onRestart} data-testid="restart-booking">
        {t('APPB2C.common.regInProgressModal.restart')}
      </Button>
      <Button intent="default" onClick={onCancel} data-testid="continue-booking">
        {t('APPB2C.common.regInProgressModal.continue')}
      </Button>
    </FooterWrapper>
  );
};

const FooterWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;

  &&& button {
    margin-left: 5px;
    margin-right: 5px;
  }
`;

const ModalContent = styled.div`
  max-width: 800px;
`;
