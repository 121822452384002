import { AccessibilityDto, accessibilityClient } from 'ors-api/mod';
import { api } from 'store/shared/api';

const cacheTime = 3600 * 24;

const accessibilityApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllAccessibilities: builder.query<AccessibilityDto[], string>({
      query: (lng) => {
        return accessibilityClient.getAccessibilities();
      },
      keepUnusedDataFor: cacheTime,
    }),
  }),
});

export const { useGetAllAccessibilitiesQuery } = accessibilityApi;
