import { createAsyncThunk } from '@reduxjs/toolkit';
import { candidateClient, CandidateIDDetails, CandidateIDDetailsExtended } from 'ors-api/ukvi';
import { RootState } from '../..';
import { logger } from 'ors-utils';
import { extractApiErrors, ApiErrorsList } from 'ors-ui';

export const saveCandidateIdDetailsUkvi = createAsyncThunk<
  CandidateIDDetails,
  CandidateIDDetailsExtended,
  { state: RootState; rejectValue: ApiErrorsList }
>('api-ors2/saveCandidateIdDetailsUkvi', async (req, { rejectWithValue }) => {
  try {
    const response = await candidateClient.saveCandidateIDDetails(req);
    return response.data;
  } catch (error: any) {
    logger.logError(error);
    const err = extractApiErrors(error);
    return rejectWithValue(err);
  }
});
