import { useTranslation } from 'react-i18next';
import { Modal } from 'ors-ui';

import { GTM, useSelector } from 'core';
import { useDispatch } from 'core/hooks/useDispatch';
import { toggleShowLogoutModal } from 'store/authSlice';
import { ModalFooter } from './ModalFooter';

export const LogoutModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { showLogoutModal } = useSelector((s) => s.auth);

  return (
    <Modal
      show={showLogoutModal}
      onExit={() => dispatch(toggleShowLogoutModal())}
      title={t('APPB2C.common.nav.logoutModal.title')}
      footer={<ModalFooter onCancel={() => dispatch(toggleShowLogoutModal())} />}
      intent="danger"
      onHide={() => GTM.trackModal('Hide', 'logout-modal')}
      onShow={() => GTM.trackModal('Show', 'logout-modal')}
    >
      <p>{t('APPB2C.common.nav.logoutModal.message')}</p>
      <p>{t('APPB2C.common.nav.logoutModal.question')}</p>
    </Modal>
  );
};
