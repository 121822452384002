import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IdTypeDto } from 'ors-api/iol';
import { OrganisationCountryAvailableIdDto } from 'ors-api/mod';
import { ApiData, ApiErrorsList } from 'ors-ui';

import { deleteIdFile, getIdFiles, prepareCandidateDocuments, uploadIdFile } from '../api';
import { examTypeChanged, globalCleanRegistration, globalLogOut } from '../_common/actions';
import { deleteAllIdFiles, verifyDocuments, saveCandidateIdDetails } from './api/api.ors';
import { IdDetailsState } from './models';

const sliceName = 'idDetails';

export const initialState: IdDetailsState = {
  loadingData: true,
  processing: false,
  initIdFiles: [],
  processingFile: false,
  processingErr: undefined,
  idTypes: { isLoading: false },
  idTypesIol: { isLoading: false },
};

/**
 * Ephemeral slice supporting IdDetails page
 */
const slice = createSlice({
  name: sliceName,
  initialState: initialState,
  reducers: {
    setProcessing(state, action: PayloadAction<boolean>) {
      state.processing = action.payload;
    },
    setProcessError(state, action: PayloadAction<ApiErrorsList | undefined>) {
      state.processingErr = action.payload;
      state.processing = false;
    },
    setIdTypes(
      state,
      action: PayloadAction<ApiData<OrganisationCountryAvailableIdDto[], ApiErrorsList>>
    ) {
      state.idTypes = action.payload;
    },
    setIdTypesIol(state, action: PayloadAction<ApiData<IdTypeDto[], ApiErrorsList>>) {
      state.idTypesIol = action.payload;
    },
    clear: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(globalLogOut, () => initialState);
    builder.addCase(globalCleanRegistration, () => initialState);
    builder.addCase(examTypeChanged, () => initialState);

    /* Save Candidate's ID details */
    builder.addCase(saveCandidateIdDetails.pending, (state) => {
      state.processingErr = undefined;
      state.loadingData = true;
    });
    builder.addCase(saveCandidateIdDetails.fulfilled, (state, action) => {
      state.loadingData = false;
    });
    builder.addCase(
      saveCandidateIdDetails.rejected,
      (state, action: PayloadAction<ApiErrorsList | undefined>) => {
        state.loadingData = false;
        state.processingErr = action.payload;
      }
    );
    /* Load ID URLs */
    builder.addCase(getIdFiles.pending, (state) => {
      state.loadingData = true;
    });
    builder.addCase(getIdFiles.fulfilled, (state, action) => {
      state.initIdFiles = action.payload;
      state.loadingData = false;
    });
    builder.addCase(
      getIdFiles.rejected,
      (state, action: PayloadAction<ApiErrorsList | undefined>) => {
        state.loadingData = false;
        state.processingErr = action.payload;
      }
    );
    /* Prepare Candidate's documents */
    builder.addCase(prepareCandidateDocuments.pending, (state) => {
      state.loadingData = true;
    });
    builder.addCase(prepareCandidateDocuments.fulfilled, (state, action) => {
      state.initIdFiles = action.payload;
      state.loadingData = false;
    });
    builder.addCase(
      prepareCandidateDocuments.rejected,
      (state, action: PayloadAction<ApiErrorsList | undefined>) => {
        state.loadingData = false;
        state.processingErr = action.payload;
      }
    );
    /* Verify Candidate's documents */
    builder.addCase(verifyDocuments.pending, (state) => {
      state.loadingData = true;
    });
    builder.addCase(verifyDocuments.fulfilled, (state) => {
      state.loadingData = false;
    });
    builder.addCase(verifyDocuments.rejected, (state) => {
      state.loadingData = false;
    });
    /* Upload ID file */
    builder.addCase(uploadIdFile.pending, (state) => {
      state.processingFile = true;
      state.processingFileError = undefined;
    });
    builder.addCase(uploadIdFile.fulfilled, (state, action) => {
      state.processingFile = false;
      state.initIdFiles = action.payload;
    });
    builder.addCase(
      uploadIdFile.rejected,
      (state, action: PayloadAction<ApiErrorsList | undefined>) => {
        state.processingFile = false;
        state.processingFileError = action.payload;
      }
    );
    /* Delete ID file */
    builder.addCase(deleteIdFile.pending, (state) => {
      state.processingFile = true;
    });
    builder.addCase(deleteIdFile.fulfilled, (state, action) => {
      state.processingFile = false;
      state.initIdFiles = [];
    });
    builder.addCase(
      deleteIdFile.rejected,
      (state, action: PayloadAction<ApiErrorsList | undefined>) => {
        state.processingFile = false;
        state.processingFileError = action.payload;
      }
    );
    /* Delete all ID files */
    builder.addCase(deleteAllIdFiles.pending, (state) => {
      state.processingFile = true;
    });
    builder.addCase(deleteAllIdFiles.fulfilled, (state) => {
      state.processingFile = false;
      state.initIdFiles = [];
    });
    builder.addCase(
      deleteAllIdFiles.rejected,
      (state, action: PayloadAction<ApiErrorsList | undefined>) => {
        state.processingFile = false;
        state.processingFileError = action.payload;
      }
    );
  },
});

export default slice;
