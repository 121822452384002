import { appSettings } from 'ors-utils';
import { SimpleLS, getUrlSearchParam } from '@britishcouncil/react-common';
import appConfig from 'appConfig';
import { initSentry } from 'initSentry';

/**
 * Identity flow values in URL:
 * IDENTITY_FLOW=old - force old identity path;
 * IDENTITY_FLOW=new - force new identity path;
 * IDENTITY_FLOW=clear - remove values from LS and work with A/B test feature flag
 */
export const IDENTITY_FLOW = 'IDENTITY_FLOW';
export const IDENTITY_FLOW_TOKEN_KEY = 'IDENTITY_FLOW_TOKEN';
export const IDENTITY_FLOW_TOKEN = 'LUsP8m5Yw9WrHfMEzk3jAy';

export const checkIdentityFlow = async () => {
  const settings = await appSettings(appConfig, initSentry, import.meta.env.REACT_APP_ENV);

  if (
    settings.environment === 'PROD' &&
    getUrlSearchParam(IDENTITY_FLOW_TOKEN_KEY) !== IDENTITY_FLOW_TOKEN
  ) {
    return handleIdentityFlow('clear');
  }

  const currentIdentityFlow = getUrlSearchParam(IDENTITY_FLOW);
  handleIdentityFlow(currentIdentityFlow);
};

const handleIdentityFlow = (queryString: string | null) => {
  switch (queryString) {
    case 'new':
    case 'old':
      return SimpleLS.write(IDENTITY_FLOW, queryString);
    case 'clear':
      return SimpleLS.remove(IDENTITY_FLOW);
    default:
      return;
  }
};
