import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { SelectedOptionValue, SelectSearchOption } from 'react-select-search';
import { DropdownIcon, SearchAndSelect } from 'ors-ui';

import { SortOrder } from 'store/bookTest';
import { RegFlow } from 'core';

interface Props {
  sortOrder: SortOrder;
  isLocationCoordsSelected: boolean;
  agentPays: boolean;
  regFlow: RegFlow;
  onChange: (selectedValue: SelectedOptionValue) => void;
}

export const SortByFilter: FC<Props> = ({
  sortOrder,
  isLocationCoordsSelected,
  agentPays,
  regFlow,
  onChange,
}) => {
  const { t } = useTranslation();

  const options = [
    { name: t('APPB2C.common.bookTest.sortBy.date'), value: 'date' },
    !agentPays && regFlow === 'ors'
      ? { name: t('APPB2C.common.bookTest.sortBy.price'), value: 'fee' }
      : null,
    isLocationCoordsSelected
      ? { name: t('APPB2C.common.bookTest.sortBy.distance'), value: 'distance' }
      : null,
  ].filter(Boolean) as SelectSearchOption[];

  return options.length > 1 ? (
    <SearchAndSelect
      id="sortby-dropdown"
      value={sortOrder}
      options={options}
      renderValue={(valueProps: any, snapshot: any, className: string) =>
        renderVal(valueProps, className, t)
      }
      onChange={(selectedValue) => onChange(selectedValue as any)}
      style={{ maxWidth: '250px', marginBottom: '20px' }}
    />
  ) : null;
};

function renderVal(valueProps: any, className: string, t: TFunction) {
  return (
    <div {...valueProps} className={className} id={`sort-by-${valueProps.value.toLowerCase()}`}>
      <span style={{ marginRight: '12px' }}>{t('APPB2C.common.bookTest.sortBy.title')}</span>
      <b>{valueProps.value}</b>
      <DropdownIcon aria-hidden="true" />
    </div>
  );
}
