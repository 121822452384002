import { ApiErrorDetailed, extractApiError } from '@britishcouncil/react-common';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { invitationContextClient, InvitationContextDto } from 'ors-api/mod';

const sliceApiName = `api/invitation`;

export const getInvitationContextState = createAsyncThunk<
  InvitationContextDto,
  void,
  { rejectValue: ApiErrorDetailed }
>(sliceApiName + '/getInvitationContextState', async (_, { rejectWithValue }) => {
  try {
    const response = await invitationContextClient.get();
    return response.data;
  } catch (error) {
    const err = extractApiError(error);
    return rejectWithValue(err);
  }
});
