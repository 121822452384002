import { NavigateOptions, To, useNavigate } from 'react-router-dom';
import { appRoutes } from './appRoutes';
import { getRegFlow, RegFlow } from 'core';
import { history } from 'store/historyState';

/** Function remove "ors" from URL when ors choose test page selected */
const checkIsOrsChooseTestPageRoute = (route: string | To, flow: RegFlow | '') => {
  const isChooseTestPage = route === appRoutes.search.chooseTest;
  const isOrs = flow === 'ors';
  if (isChooseTestPage && isOrs) {
    return `/${route}`;
  }
  return `/${flow}/${route}`;
};

/**
 * With React Router 6, we are not able to pass "baseName" to "createBrowserHistory"
 * so to make history.push() work, we need to use "base" part of the URL (i.e. "/").
 */
export const routeWithBase = (route: string) => `${route}`;
export const navigateWithBase = (route: string, config?: { method?: 'push' | 'replace' }) =>
  history[config?.method || 'push'](routeWithBase(route));

/** Get path with flow. You can use it e.g.  with <Link to={routeWithFlow} /> component */
export const routeWithFlow = (route: string, config?: { flow?: RegFlow }) => {
  const flow = config?.flow ?? getRegFlow() ?? 'ors';
  return checkIsOrsChooseTestPageRoute(route, flow);
};

/** Function to navigate user between customer journey routes without losing flow type context */
export const navigateTo = (
  route: string,
  config?: { method?: 'push' | 'replace'; flow?: RegFlow }
) => {
  const flow = getRegFlow() ?? 'ors';
  const flowOrRoot = route === appRoutes.root ? '' : config?.flow || flow;

  history[config?.method || 'push'](
    checkIsOrsChooseTestPageRoute(route, flowOrRoot).replace(/\/\//g, '')
  );
};

/** Hook to navigate user between customer journey routes without losing flow type context */
export const useNavigateTo = () => {
  const flow = getRegFlow() ?? 'ors';
  const navigate = useNavigate();

  return (route: To, options?: NavigateToOptions) => {
    const flowOrRoot = route === appRoutes.root ? '' : options?.flow ?? flow;
    navigate(checkIsOrsChooseTestPageRoute(route, flowOrRoot), options);
  };
};

interface NavigateToOptions extends NavigateOptions {
  /** Force registration flow - use wisely and only when necessary */
  flow?: RegFlow;
}
