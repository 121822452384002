import { useTranslation } from 'react-i18next';
import { useMatch } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { includesInUrl } from 'ors-utils';

import { useSelector } from 'core';
import { toggleShowLogoutModal, useAuthSlice } from 'store/authSlice';
import { appRoutes, routeWithFlow } from 'routing';
import styled from '@emotion/styled';

export const LogoutButton = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { handleLogOut } = useAuthSlice();
  const isConfirmationRoute = useMatch(routeWithFlow(appRoutes.journey.bookingComplete));

  const { timerActive } = useSelector((s) => s.reservation);
  const logout = () => {
    const shouldShowModal = includesInUrl('personal-details') || timerActive;
    const redirectUrl = isConfirmationRoute
      ? window.location.origin + '/ttp' + window.location.search
      : undefined;

    shouldShowModal ? dispatch(toggleShowLogoutModal()) : handleLogOut(redirectUrl);
  };

  return (
    <StyledBtn onClick={logout} data-testid="nav.logout" type="button">
      {t('APPB2C.common.nav.logout')}
    </StyledBtn>
  );
};

const StyledBtn = styled.button`
  transition: box-shadow 0.3s ease-in-out;

  &:focus-visible {
    outline: 0;
    box-shadow: 0 0 0 6px rgba(1, 156, 238, 0.5);
    z-index: 1;
    position: relative;
  }
`;
