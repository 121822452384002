import { TimeTableSession, ExamFormat } from 'ors-api/ors2';

import { GTM, IExtendedDataLayer } from 'core';
import { ExamFilterBase } from 'store/searchSelect';

const TimeTableSessionNames = {
  1: 'Morning',
  2: 'Afternoon',
  3: 'Evening',
  4: 'All Day',
  5: 'Not Defined',
};

export function trackFilters(filter: ExamFilterBase, timeTableSessions: TimeTableSession[]) {
  const ev: Partial<IExtendedDataLayer> = {};

  if (filter.examFormat) {
    ev.filter_format = filter.examFormat === ExamFormat.CD ? 'CD' : 'PB';
  }

  if ((!filter.examFormat || filter.examFormat === ExamFormat.CD) && timeTableSessions) {
    ev.filter_time = timeTableSessions
      .map((c) => TimeTableSessionNames[c] || null)
      .filter((c) => c)
      .join(', ');
  }

  GTM.update(ev);
}
