import { getOrganisationAlias } from 'ors-utils';

import { useIsPremiumCode } from 'core';
import { appRoutes, routeWithBase } from 'routing';
import { AppThunk, examTypeChanged } from '.';
import { isAnyAvailable } from './api.iol';
import { history } from './historyState';
import { organisationCountry } from './organisationCountry';
import { ExamType } from './registration';
import { searchSelect } from './searchSelect';

export const proceed =
  (examType?: ExamType, isUkvi?: boolean): AppThunk =>
  async (dispatch, getState) => {
    const currentExamType = getState().searchSelect.examType;
    const { deeplinkToken } = getState().invitation;
    const orgAlias = getOrganisationAlias();
    const isPremiumCodeLink = useIsPremiumCode();

    /** Reset organization when necessary */
    if (!orgAlias) {
      dispatch(organisationCountry.actions.resetOrganisation());
    }

    !!currentExamType && currentExamType !== examType && dispatch(examTypeChanged());

    let showChoosePage = false;

    if (!deeplinkToken && !orgAlias) {
      await dispatch(isAnyAvailable({ examType }));

      showChoosePage = !!getState().searchSelect.iolAvailable.data && !isPremiumCodeLink;
    }

    examType && dispatch(searchSelect.thunksCommon.chooseExamType(examType));

    const uolRoute = showChoosePage
      ? `/ukvi/${appRoutes.search.chooseTest}`
      : `/ukvi/${appRoutes.search.findTest}`;

    const iolRoute = showChoosePage
      ? appRoutes.utils.chooseTest
      : `/ors/${appRoutes.search.findTest}`;

    history.push(routeWithBase(isUkvi ? uolRoute : iolRoute));
  };
