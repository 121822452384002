import { useUppy } from 'ors-utils';

import { useSelector } from 'core';
import { IdType } from './components/models';

export const enableFileUpload = (options?: IdType[], selectedIdTypeId?: number) => {
  const isEnabled = options
    ? options.some(
        (el) =>
          'enableUploadInCustomerJourney' in el &&
          el.enableUploadInCustomerJourney &&
          el.idTypeId === selectedIdTypeId
      )
    : false;
  return isEnabled;
};

const uploadConfig = { maxNumberOfFiles: 2, minNumberOfFiles: 1 };

export function useIdUpload(isUploadEnabled?: boolean) {
  const initIdFiles = useSelector((state) => state.idDetails.initIdFiles);

  const [uppy, fileRefs, isFetchingFiles, fetchingError] = useUppy(
    uploadConfig,
    isUploadEnabled,
    undefined,
    initIdFiles
  );

  return {
    uppy,
    fileRefs,
    isFetchingFiles,
    fetchingError,
    isUploadEnabled,
    uploadConfig,
  };
}
