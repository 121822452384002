import { useEffect, useState } from 'react';
import { SimpleLS, getUrlSearchParam } from '@britishcouncil/react-common';
import { GTM, useSelector } from 'core';
import { appSettings } from 'ors-utils';
import { hasIeltsUsaUrl } from 'ors-translations';

import { useAuthSlice } from 'store/authSlice';
import {
  IDENTITY_FLOW,
  IDENTITY_FLOW_TOKEN,
  IDENTITY_FLOW_TOKEN_KEY,
} from 'store/initApp/identityFlow';
import { appRoutes, navigateTo } from 'routing';
import { initSentry } from 'initSentry';
import appConfig from 'appConfig';

export type IdentityFlowSource = 'personal-details' | 'ielts-ready-member' | 'book-test';

export const useIdentityFlow = (source: IdentityFlowSource) => {
  const [isCheckingIdentityFlow, setIsCheckingIdentityFlow] = useState(false);

  const { handleStartLogIn } = useAuthSlice();

  const { isLoggedIn } = useSelector((s) => s.auth);
  const { loadingFeatureFlags, features } = useSelector((s) => s.featureFlags);

  const handleIdentityFlow = async () => {
    setIsCheckingIdentityFlow(true);

    const settings = await appSettings(appConfig, initSentry, import.meta.env.REACT_APP_ENV);
    const currentUrlIdentityFlow = getUrlSearchParam(IDENTITY_FLOW);
    const currentIdentityFlow =
      source === 'ielts-ready-member' &&
      !!currentUrlIdentityFlow &&
      (settings.environment !== 'PROD' ||
        getUrlSearchParam(IDENTITY_FLOW_TOKEN_KEY) === IDENTITY_FLOW_TOKEN)
        ? currentUrlIdentityFlow
        : SimpleLS.read<string | null>(IDENTITY_FLOW, null);

    switch (currentIdentityFlow) {
      case 'new':
        return handleNewIdentityFlow(true);
      case 'old':
        return handleOldIdentityFlow(true);
      default:
        return getAbTest(source) ? handleNewIdentityFlow() : handleOldIdentityFlow();
    }
  };

  const handleOldIdentityFlow = async (forced: boolean = false) => {
    await trackIdentityFlow('old', forced);
    return source !== 'book-test'
      ? setIsCheckingIdentityFlow(false)
      : navigateTo(appRoutes.journey.personalDetails);
  };

  const handleNewIdentityFlow = async (forced: boolean = false) => {
    await trackIdentityFlow('new', forced);
    return handleStartLogIn({
      allowAzureB2CAccountCreation: true,
      redirectToPersonalDetails: source === 'book-test',
      isIeltsReadyLogin: source === 'ielts-ready-member',
    });
  };

  const trackIdentityFlow = async (flow: string, forced: boolean = false) => {
    GTM.trackRegistrationEvent('identity-flow', {
      identityFlow: flow,
      identityFlowForced: forced,
    });
  };

  const getAbTest = (source: IdentityFlowSource) => {
    if (hasIeltsUsaUrl()) {
      return source == 'ielts-ready-member'
        ? features.isReadyMemberIdentityUsaEnabled
        : features.isCustomerIdentityUsaEnabled;
    }

    return source == 'ielts-ready-member'
      ? features.isReadyMemberIdentityEnabled
      : features.isCustomerIdentityEnabled;
  };

  useEffect(() => {
    if (!isLoggedIn && !loadingFeatureFlags && source !== 'book-test') {
      handleIdentityFlow();
    }
  }, [isLoggedIn, loadingFeatureFlags, source]);

  return { isCheckingIdentityFlow, handleIdentityFlow };
};
