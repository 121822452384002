import { FC, lazy } from 'react';
import { Routes, Route } from 'react-router-dom';
import { ScrollToTop } from '@britishcouncil/react-common';

import { GuardedRoute } from 'components/GuardedRoute';

import { validateChooseTestPage } from 'store/searchSelect/routeValidation';
import { validateState as valForBookTest } from 'store/bookTest';
import { validateState as valForPersonalDetails, validateChildState } from 'store/personalDetails';
import { validateState as valForIdDetails } from 'store/idDetails';
import { validateState as valForTtProfile } from 'store/storageData/marketing/routeValidation';
import { validateReview } from 'store/registration/routeValidation';

/** Initial bundle with all the Search & Select */
import StartPage from '../../pages/StartPage';
import ChooseUkvi from '../../pages/ChooseUkvi';
import BookTest from '../../pages/BookTest';
import TestDetails from '../../pages/TestDetails';
import { appRoutes } from '../appRoutes';

import { OidcCallback } from '../../auth';
import { JourneyRoutes } from './JourneyRoutes';
import { TermsRoutes } from './TermsRoutes';
import { TermsDownloadRoutes } from './TermsDownloadRoutes';
import {
  LandingPageComp,
  MyAccountComp,
  TestAndResultsComp,
  TestChooserComp,
  TestDetailsComp,
  RebookComp,
} from './Compatibility';

/** Please refer to the Page components as follows. Webpack will bundle app in a more modular way. */
const UnknownCountryPage = lazy(() => import('../../pages/UnknownCountry'));
const UnderConstructionPage = lazy(() => import('../../pages/UnderConstruction'));
const PersonalDetails = lazy(
  () => import(/* webpackPreload: true */ '../../pages/PersonalDetails')
);
const PersonalDetailsChild = lazy(
  () => import(/* webpackPreload: true */ '../../pages/PersonalDetails/Child')
);
const IdDetails = lazy(() => import('../../pages/IdDetails'));
const ForgotPassword = lazy(() => import('../../pages/auth/ForgotPassword'));
const ResetPassword = lazy(() => import('../../pages/auth/ResetPassword'));
const ChangePassword = lazy(() => import('../../pages/auth/ChangePassword'));
const TTProfile = lazy(() => import('../../pages/TestTakerProfile'));
const Review = lazy(() => import('../../pages/Review'));
const FinishPayment = lazy(() => import('../../pages/Payment/FinishPayment'));
const BookingComplete = lazy(() => import('../../pages/Payment/BookingComplete'));
const Deeplink = lazy(() => import('../../pages/Deeplink'));
const ChooseTest = lazy(() => import('../../pages/ChooseTest'));
const NotFound = lazy(() => import('../../pages/NotFound'));
const IeltsReadyRegForm = lazy(() => import('../../pages/IeltsReadyRegForm/IeltsReadyRegForm'));
const IeltsReadyRedirect = lazy(() => import('../../pages/IeltsReadyRegForm/IeltsReadyRedirect'));
const IncorrectOrganisationPage = lazy(() => import('../../pages/IncorrectOrganisationPage'));

export const AppRoutes: FC = () => (
  <ScrollToTop>
    <Routes>
      <Route path={appRoutes.auth.callback} element={<OidcCallback />} />

      <Route path={appRoutes.auth.forgotPsw} element={<ForgotPassword />} />
      <Route path={appRoutes.auth.resetPsw} element={<ResetPassword />} />
      <Route path={appRoutes.auth.changePsw} element={<ChangePassword />} />

      <Route path={appRoutes.utils.deepLink} element={<Deeplink />} />
      <Route path={appRoutes.incorrectOrganisation} element={<IncorrectOrganisationPage />} />
      <Route path={appRoutes.journey.ieltsReadyReg} element={<IeltsReadyRegForm />} />
      <Route path={appRoutes.journey.ieltsReadyRedirect} element={<IeltsReadyRedirect />} />

      <Route
        path={appRoutes.utils.chooseTest}
        element={
          <GuardedRoute validate={validateChooseTestPage}>
            <ChooseTest />
          </GuardedRoute>
        }
      />

      <Route path={appRoutes.search.ukvi}>
        <Route index element={<ChooseUkvi />} />
        <Route
          path={appRoutes.search.chooseTest}
          element={
            <GuardedRoute validate={validateChooseTestPage}>
              <ChooseTest />
            </GuardedRoute>
          }
        />
      </Route>

      {/** All customer journey routes. These routes are prepended with flow type, e.g. "ors", "iol" etc.  */}
      <Route path="/:flowType/*" element={<JourneyRoutes />} />

      <Route path={appRoutes.root} element={<StartPage />} />
      <Route path={appRoutes.unknownCountry} element={<UnknownCountryPage />} />
      <Route path={appRoutes.underConstruction} element={<UnderConstructionPage />} />

      {/** Terms and conditions routes where simple index is used by IELTS (and a safe fallback) and indexUkvi by UKVI. */}
      <Route path={appRoutes.terms.index} element={<TermsRoutes />} />
      <Route path={appRoutes.terms.indexUkvi} element={<TermsRoutes />} />

      {/* Legacy routes to keep backward compatibility. Remove when it will be safe to use with flow type only. */}
      <Route path={appRoutes.terms.download.index} element={<TermsDownloadRoutes />} />
      <Route path={appRoutes.terms.download.indexUkvi} element={<TermsDownloadRoutes />} />

      {/* Legacy routes to keep backward compatibility. Remove when it will be safe to use with flow type only. */}
      <Route path={appRoutes.legacy.search.findTest} element={<LandingPageComp />} />
      <Route
        path={appRoutes.legacy.search.bookTest}
        element={
          <GuardedRoute validate={valForBookTest}>
            <BookTest />
          </GuardedRoute>
        }
      />
      <Route
        path={appRoutes.legacy.search.testDetails}
        element={
          <GuardedRoute validate={valForBookTest}>
            <TestDetails />
          </GuardedRoute>
        }
      />
      <Route
        path={appRoutes.legacy.journey.personalDetails}
        element={
          <GuardedRoute validate={valForPersonalDetails}>
            <PersonalDetails />
          </GuardedRoute>
        }
      />
      <Route
        path={appRoutes.legacy.journey.childDetails}
        element={
          <GuardedRoute validate={validateChildState}>
            <PersonalDetailsChild />
          </GuardedRoute>
        }
      />
      <Route
        path={appRoutes.legacy.journey.idDetails}
        element={
          <GuardedRoute validate={valForIdDetails}>
            <IdDetails />
          </GuardedRoute>
        }
      />
      <Route
        path={appRoutes.legacy.journey.ttProfile}
        element={
          <GuardedRoute validate={valForTtProfile}>
            <TTProfile />
          </GuardedRoute>
        }
      />

      <Route
        path={appRoutes.legacy.journey.review}
        element={
          <GuardedRoute validate={validateReview}>
            <Review />
          </GuardedRoute>
        }
      />

      <Route path={appRoutes.legacy.search.testChooserCountry} element={<TestChooserComp />} />
      <Route path={appRoutes.legacy.search.testChooser} element={<TestChooserComp />} />
      <Route path={appRoutes.legacy.search.landingPageGlobal} element={<LandingPageComp />} />
      <Route path={appRoutes.legacy.search.landingPageOrganisation} element={<LandingPageComp />} />
      <Route path={appRoutes.legacy.search.landingPage} element={<LandingPageComp />} />
      <Route path={appRoutes.legacy.testTakerPortal.testDetail} element={<TestDetailsComp />} />
      <Route path={appRoutes.legacy.testTakerPortal.myAccount} element={<MyAccountComp />} />
      <Route path={appRoutes.legacy.testTakerPortal.root} element={<TestAndResultsComp />} />
      <Route path={appRoutes.legacy.journey.finishPayment} element={<FinishPayment />} />
      <Route path={appRoutes.legacy.journey.bookingComplete} element={<BookingComplete />} />
      <Route path={appRoutes.legacy.journey.rebook} element={<RebookComp />} />
      {/* End of legacy routes */}

      <Route path="*" element={<NotFound />} />
    </Routes>
  </ScrollToTop>
);
