import slice, { initialState } from './onRequestSlice';
import * as thunks from './thunks';

export * from './models';
export * from './routeValidation';

export const onRequest = {
  ...slice,
  thunks,
  initialState,
};
