import { PropsWithChildren } from 'react';
import { IntlProvider } from 'react-intl';
import { defaultLanguage, pseudoLanguage } from 'ors-translations';

import { useLocale } from 'core';

export const BCIntlProvider = ({ children }: PropsWithChildren) => {
  const selectedLang = useLocale().locale;

  const locale = !selectedLang || selectedLang === pseudoLanguage ? defaultLanguage : selectedLang;

  return <IntlProvider locale={locale}>{children}</IntlProvider>;
};
