import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { PayloadAction } from '@reduxjs/toolkit';
import { ApiErrorsList } from 'ors-ui';

import { PersonalDetailsState } from '.';
import { apiExtraReducer } from '../_common/apiExtraReducer';
import { loadParentDetails } from '../api';
import {
  createCandidateAccount,
  getTermsAndConditionsNewestVersionNumber,
  getCandidateChildren,
} from './api/api.ors';
import { getCandidateChildren as getCandidateChildrenUkvi } from './api/api.ukvi';

export const extraReducersIc = (builder: ActionReducerMapBuilder<PersonalDetailsState>) => {
  apiExtraReducer(builder, loadParentDetails, 'parentDetails');

  apiExtraReducer(builder, getCandidateChildren, 'dependants');
  apiExtraReducer(builder, getCandidateChildrenUkvi, 'dependants');

  /* Create account */
  builder
    .addCase(createCandidateAccount.pending, (state) => {
      state.processing = true;
    })
    .addCase(
      createCandidateAccount.rejected,
      (state, action: PayloadAction<ApiErrorsList | undefined>) => {
        state.processing = false;
        state.processingErr = action.payload;
      }
    );

  /* Get terms and conditions newest version number */
  builder.addCase(getTermsAndConditionsNewestVersionNumber.pending, (state) => {
    state.processing = true;
  });
};
