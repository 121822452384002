import { IeltsModule } from 'ors-api/ors2';
import { IeltsModuleEnum } from 'ors-api/ukvi';
import { SearchSelectState } from './models';
import { ExamModule } from 'ors-api/mod';

const ieltsModule = {
  fromUkvi: (m: IeltsModuleEnum): SearchSelectState['ieltsModule'] =>
    m === IeltsModuleEnum.Ac ? IeltsModule.AC : IeltsModule.GT,
  toUkvi: (m: SearchSelectState['ieltsModule']): IeltsModuleEnum =>
    m === IeltsModule.AC ? IeltsModuleEnum.Ac : IeltsModuleEnum.Gt,
  toExamModule: (m: SearchSelectState['ieltsModule']): ExamModule =>
    m === IeltsModule.AC ? ExamModule.AC : ExamModule.GT,
};

export const searchSelectConverters = {
  ieltsModule,
};
