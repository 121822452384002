import { getUrlSearchParam } from '@britishcouncil/react-common';
import { getOrganisationAlias } from 'ors-utils';
import { ExamFormat } from 'ors-api/ors2';
import { countriesClient, ExamOptionType, ProductFamilies } from 'ors-api/mod';

import { AppThunk, ThunkApi } from '..';
import { getRegFlow, GTM } from 'core';
import { ExamType } from '../registration';
import { searchSelect } from '../searchSelect';

/**
 * Get some app initial values from URL and save in redux store.
 * All possible query params (example for Gdansk, PL):
 * ?country=PL&location=5548&examType=ac&examFormat=cd&examDate=2022-08-27
 */
export const setPassedInData = (): AppThunk => async (dispatch, getState) => {
  /** ISO Code (Two characters)  */
  const countryIsoCode = getUrlSearchParam('country');
  const locationId = getUrlSearchParam('location');
  /** Valid exam types: "ac", "gt", "lsa1", "lsa2" or "lsb1"*/
  const examType = getUrlSearchParam('examType') || '';
  /** "examDate" must be in YYYY-MM-DD format (ISO date) */
  const examDate = getUrlSearchParam('examDate');
  /** Valid exam formats: "pb" or "cd" */
  const examFormatParam = getUrlSearchParam('examFormat')?.toLowerCase();
  const examFormat =
    examFormatParam === 'cd' ? ExamFormat.CD : examFormatParam === 'pb' ? ExamFormat.PB : undefined;
  const examFlow = getUrlSearchParam('examFlow');
  const isUkvi = getRegFlow() === 'ukvi' || examFlow === 'ukvi';

  if (countryIsoCode) {
    await setPassedInCountry(countryIsoCode?.trim() || '', isUkvi, { dispatch, getState });
  } else {
    // Additional fill up of country if we have it and it was not passed as a parameter
    const country = getState().searchSelect.activeCountry;
    if (country) {
      GTM.updateCountry({
        country: country?.isoCode2 || '',
        countryTagCode: country?.googleTagManagerCode || '',
      });
    }
  }

  if (isUkvi && isValidExamType(examType)) {
    dispatch(searchSelect.actions.setUkviSpecificData(getUkviSpecificData(examType, examFormat)));
  }

  GTM.update({
    organization: getOrganisationAlias() || 'BC',
  });

  /* loadLoactions expect to have chosen examType */
  if (isValidExamType(examType)) {
    let typeOfExam;

    // for Life skills tests exam type need to be set "ac"
    switch (examType.toLowerCase()) {
      case 'ac':
        typeOfExam = 'ac';
        break;
      case 'gt':
        typeOfExam = 'gt';
        break;
      default:
        typeOfExam = 'ac';
        break;
    }
    dispatch(searchSelect.thunksCommon.chooseExamType(typeOfExam as ExamType));
  }

  if (locationId) {
    await setPassedInLocation(locationId, { dispatch, getState });
  }

  if (examFormat) {
    dispatch(searchSelect.actions.setFilter({ examFormat }));
  }

  if (examDate) {
    const isoDateRegex = /^\d{4}-([0]\d|1[0-2])-([0-2]\d|3[01])$/;
    isoDateRegex.test(examDate) &&
      dispatch(searchSelect.actions.datesRangeChanged([examDate, examDate]));
  }
};

export function isValidExamType(examType?: string): boolean {
  return !!examType && supportedExamTypes.indexOf(examType.toLowerCase()) > -1;
}

const supportedExamTypes = ['ac', 'gt', 'lsa1', 'lsa2', 'lsb1'];

async function setPassedInCountry(countryCode: string, isUkvi: boolean, { dispatch }: ThunkApi) {
  GTM.updateCountry({
    country: '',
  });

  try {
    let countryDetails = undefined;

    const resp = await await countriesClient.getExamCountryByIsoCode(
      isUkvi ? ProductFamilies.UKVI : ProductFamilies.IELTS,
      countryCode
    );
    if (resp.data.hasValue) {
      countryDetails = resp.data.value;
    }

    if (!!countryDetails) {
      dispatch(searchSelect.actions.setActiveCountry(countryDetails));
      await dispatch(searchSelect.thunksCommon.setActiveCountry(countryDetails));
    }
  } catch (ex) {
    dispatch(searchSelect.actions.setActiveCountry(undefined));

    GTM.updateCountry({
      countryTagCode: '',
    });
  }
}

async function setPassedInLocation(locationId: string, { dispatch, getState }: ThunkApi) {
  try {
    await dispatch(searchSelect.thunksOrs.loadLocations());
    const locations = getState().searchSelect.locationsList.data;
    const locId = parseInt(locationId);

    if (!!locId && locations && locations.length > -1) {
      const selLocation = locations.find((e) => e.ids.some((i) => i === locId));
      await dispatch(
        searchSelect.actions.updateState({ activeLocation: selLocation, locationCoords: undefined })
      );
    }
  } catch (ex) {}
}

const getUkviSpecificData = (examType: string, examFormat?: ExamFormat) => {
  switch (examType) {
    case 'ac':
    case 'gt':
      return {
        examOptionType:
          examFormat === ExamFormat.CD
            ? ExamOptionType.IELTSCDUKVILRW
            : ExamOptionType.IELTSUKVILRW,
      };
    case 'lsa1':
      return {
        examOptionType: ExamOptionType.LifeSkillsA1,
      };
    case 'lsa2':
      return {
        examOptionType: ExamOptionType.LifeSkillsA2,
      };
    case 'lsb1':
      return {
        examOptionType: ExamOptionType.LifeSkillsB1,
      };
    default:
      return {
        examOptionType:
          examFormat === ExamFormat.CD
            ? ExamOptionType.IELTSCDUKVILRW
            : ExamOptionType.IELTSUKVILRW,
      };
  }
};
