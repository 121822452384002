import { LoadingCard } from 'ors-ui';
import { useEffect } from 'react';
import { logger } from 'ors-utils';
import { OidcContext } from '@britishcouncil/react-oidc-auth';

import { restoreDataLayer, useLocale } from 'core';

export const OidcCallback = () => {
  const { signinRedirect, error, isAuthenticated } = OidcContext.useAuth();
  const { locale } = useLocale();

  const successCallback = async () => {
    restoreDataLayer();
  };

  useEffect(() => {
    if (isAuthenticated) {
      successCallback();
    }
  }, [isAuthenticated, successCallback]);

  useEffect(() => {
    if (error) {
      if (error.message !== 'No matching state found in storage') {
        logger.logError(error);
      }

      const extraQueryParams = {
        ui_locales: locale,
      };

      signinRedirect({
        extraQueryParams,
      });
    }
  }, [error]);

  return <LoadingCard />;
};
