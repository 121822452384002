import { DateTime } from 'luxon';
import { OrganisationCountryAvailableIdDto } from 'ors-api/mod';

import { getRegFlow } from 'core';
import { getLastExamDate } from '../../registration/selectors';
import { RootState } from '../..';

export const isIdValid = (rootState: RootState) => {
  const lastExamDate = getLastExamDate(rootState);
  const candidate = rootState.candidate;
  const profile = candidate.ttProfile || candidate.profile;

  if (profile && profile.notExpires) return true;

  return profile && profile.idExpiry
    ? DateTime.fromISO(profile.idExpiry) >= lastExamDate.minus({ days: 1 })
    : true;
};

export const isIdTypeAvailable = (rootState: RootState) => {
  const examFlow = getRegFlow();
  const candidate = rootState.candidate;
  const profile = candidate.ttProfile || candidate.profile;
  const idDetails = rootState.idDetails;

  return examFlow === 'iol'
    ? (idDetails.idTypesIol.data ?? []).some((idType) => idType.id === profile?.idTypeId)
    : (idDetails.idTypes.data ?? []).some((idType) => idType.idTypeId === profile?.idTypeId);
};

export const isUploadEnabled = (idTypes?: OrganisationCountryAvailableIdDto[]) =>
  idTypes ? idTypes.find((el) => el.enableUploadInCustomerJourney) : false;
